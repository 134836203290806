import React, { useState } from 'react';
import Quest from '../../../Components/Quest';
import { QuestT, TQuestsResponse } from '../../../types';
import { useAppSelector } from '../../../store';

type QuestDrawerContentProps = {
    quests: TQuestsResponse
    setSelectedQuest: (quest: QuestT) => void
    invalidQuests: number[]
    handleQuestStart: (quest: QuestT) => void
    handleQuestCheck: (quest: QuestT) => void
}
function QuestDrawerContent({
  quests, setSelectedQuest, invalidQuests, handleQuestStart, handleQuestCheck,
}: QuestDrawerContentProps) {
  const { referrals: referralsData } = useAppSelector(({ referrals }) => referrals);
  const [activeTab, setActiveTab] = useState<'social'| 'referral'>('social');

  return (
    <div className="pb-2">
      <div className="flex justify-around bg-black-light-bg rounded-10 p-1 px-2 text-center">
        <div
          className={`w-full cursor-pointer ${activeTab === 'social' && 'rounded-10 bg-black-modal-bg'}`}
          onClick={() => setActiveTab('social')}
        >
          Socials
        </div>
        <div
          className={`w-full cursor-pointer ${activeTab === 'referral' && 'rounded-10 bg-black-modal-bg'}`}
          onClick={() => setActiveTab('referral')}
        >
          Referral
        </div>

      </div>
      <div className="mt-4">
        {quests[activeTab].map((quest) => (
          <Quest
            quest={quest}
            key={JSON.stringify(quest)}
            onStart={() => handleQuestStart(quest)}
            onCheck={() => handleQuestCheck(quest)}
            onInfoClick={() => setSelectedQuest(quest)}
            isValid={!invalidQuests.includes(quest.id)}
            referralsInvited={referralsData?.referrals.length || 0}
          />
        ))}
      </div>
    </div>
  );
}

export default QuestDrawerContent;
