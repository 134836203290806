// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'phaser/plugins/spine4.1/dist/SpinePlugin';
import Phaser from 'phaser';
import eventEmitter, { emitGameEvent, GameEvents } from '../../../pages/Home/eventEmiter';
import { SlotMachineSpinResponse } from '../../../types';
import { SlotTypes } from '../../../types/constants';
import { calculateScale } from '../../../helpers';

const fileListEdit = [
  {
    name: SlotTypes.coin_bag, jsonPath: 'coin_bag/coin_bag.json', atlasPath: 'coin_bag/coin_bag.atlas', animations: ['animation'],
  },
  {
    name: SlotTypes.treasure, jsonPath: 'treasure/treasure.json', atlasPath: 'treasure/treasure.atlas', animations: ['animation'],
  },
  {
    name: SlotTypes.multiplier_x2, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x2'],
  },
  {
    name: SlotTypes.multiplier_x3, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x3'],
  },
  {
    name: SlotTypes.multiplier_x5, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x5'],
  },
  {
    name: SlotTypes.energy_refill, jsonPath: 'energy/energy_small.json', atlasPath: 'energy/energy_small.atlas', animations: ['animation'],
  },
  // {
  //   name: SlotTypes.revive, jsonPath: 'second_chance/second_chance.json', atlasPath: 'second_chance/second_chance.atlas', animations: ['animation'],
  // },
  // {
  //   name: 'coin_booster', jsonPath: 'coin_booster/coins_booster.json', atlasPath: 'coin_booster/coins_booster.atlas', animations: ['animation'],
  // },
  // { // reduce_crash_pr_booster
  //   name: SlotTypes.energy_refill, jsonPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.json', atlasPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.atlas', animations: ['animation'],
  // },
  { // reduce_crash_pr_booster
    name: SlotTypes.aviator, jsonPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.json', atlasPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.atlas', animations: ['animation'],
  },
];

class SlotMachineScene extends Phaser.Scene {
  private columns: Phaser.GameObjects.Container[][] = [];

  private speed = 1;

  private maxSpeed = 40;

  private increment = 2;

  private baseYOffset = 50;

  private baseXOffset = 60;

  constructor() {
    super({ key: 'SlotMachineScene' });
  }

  preload(): void {
    this.load.setPath('spine/slot-machine');
    fileListEdit.forEach((file) => {
      this.load.spine(file.name, file.jsonPath, file.atlasPath);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  calculateAnimationPosition(colIndex: number, rowIndex: number): { x: number; y: number } {
    const sceneWidth = this.scale.width;
    const sceneHeight = this.scale.height;
    const baseXSpacing = sceneWidth / 3.2;
    const baseYSpacing = sceneHeight / 3.3;
    const xPosition = (baseXSpacing * colIndex) + (this.baseXOffset);
    const yPosition = (baseYSpacing * rowIndex) + (this.baseYOffset);
    return { x: xPosition, y: yPosition };
  }

  create(): void {
    this.columns = [[], [], []];
    this.scale.autoRound = true;
    this.baseXOffset = this.scale.width / 6; // 60
    this.baseYOffset = this.scale.height / 5.4;
    // eslint-disable-next-line no-plusplus
    for (let colIndex = 0; colIndex < 3; colIndex++) {
      // eslint-disable-next-line no-plusplus
      for (let rowIndex = 0; rowIndex < fileListEdit.length; rowIndex++) {
        const file = fileListEdit[Phaser.Math.Between(0, fileListEdit.length - 1)];
        const position = this.calculateAnimationPosition(colIndex, rowIndex);
        const spineObject = this.add.spine(position.x, position.y, file.name, file.animations[0], false);
        spineObject.state.tracks[0].timeScale = 0;
        spineObject.setScale(calculateScale(spineObject, 75, 64) * 2);
        spineObject.setName(file.name);

        if (!spineObject) {
          window.console.error(`Failed to create spine object for ${file.name}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        spineObject.setVisible(rowIndex < 3); // Display top 3 elements

        this.columns[colIndex].push(spineObject);
      }
    }
    eventEmitter.emit(GameEvents.SLOT_LOADED);
    eventEmitter.on(GameEvents.SLOT_START_SPIN, (data) => {
      if (data?.targetSlots) {
        this.startSpin(data.targetSlots);
      }
    });
    eventEmitter.on(GameEvents.SLOT_HIDE_MIDDLE, () => {
      this.columns[1][1].visible = false;
    });
    eventEmitter.on(GameEvents.SLOT_SHOW_MIDDLE, () => {
      this.columns[1][1].visible = true;
    });
    this.scale.on('resize', this.handleResize, this);
  }

  handleResize(gameSize) {
    const { width, height } = gameSize;
    window.console.log(`Scene resized to: ${width}x${height}`);
    eventEmitter.off(GameEvents.SLOT_LOADED);
    eventEmitter.off(GameEvents.SLOT_START_SPIN);
    eventEmitter.off(GameEvents.SLOT_HIDE_MIDDLE);
    eventEmitter.off(GameEvents.SLOT_SHOW_MIDDLE);
    this.scene.restart();
  }

  startSpin(targetSlots: SlotMachineSpinResponse['slots'][number]['type']): void {
    this.speed = 10;
    this.columns.forEach((column, colIndex) => {
      this.animateReel(colIndex, this.speed, targetSlots); // Set column speed
    });
  }

  animateReel(columnIndex: number, speed: number, targetValues: string[]): void {
    const column = this.columns[columnIndex];
    const targetSymbols = Phaser.Utils.Array.Shuffle([...fileListEdit]);

    let currentIndex = 0;
    let spinsRemaining = 3 * column.length; // Number of spins
    let isTargetReached = false;
    const targetValue = targetValues[columnIndex];
    const interval = this.time.addEvent({
      delay: this.speed,
      callback: () => {
        if (isTargetReached) {
          if (this.columns.length === columnIndex + 1) {
            emitGameEvent({ event: GameEvents.SLOT_STOP_SPIN });
          }
          interval.remove();
          return;
        }
        const removed = column.pop();
        if (removed) {
          removed.destroy();
        }

        const file = targetSymbols[currentIndex % targetSymbols.length];
        const newSpineObject = this.add.spine(this.columns[columnIndex][0].x, this.columns[columnIndex][0].y, file.name, file.animations[0], true);
        newSpineObject.state.tracks[0].timeScale = 0; // stop on the first frame
        newSpineObject.setScale(calculateScale(newSpineObject, 75, 64) * 2);
        newSpineObject.setName(file.name);

        if (!newSpineObject) {
          window.console.error(`Failed to create spine object for ${file.name}`);
          return;
        }

        column.unshift(newSpineObject);
        this.updateDisplay(columnIndex);

        currentIndex++;
        spinsRemaining--;
        const middleIndex = 1;
        if (column[middleIndex] && column[middleIndex].name === targetValue && spinsRemaining < 5) {
          isTargetReached = true;
        }
        interval.delay = Math.min(interval.delay + (this.increment * columnIndex), this.maxSpeed);
      },
      loop: true,
    });
  }

  updateDisplay(columnIndex: number): void {
    const column = this.columns[columnIndex];
    // eslint-disable-next-line no-plusplus
    for (let rowIndex = 0; rowIndex < column.length; rowIndex++) {
      const spineObject = column[rowIndex];
      spineObject.setVisible(rowIndex < 3); // Display top 3
      this.calculateAnimationPosition(columnIndex, rowIndex);
      spineObject.y = this.calculateAnimationPosition(columnIndex, rowIndex).y; // Update position
    }
  }

  getMiddleElementPosition(): void {
    const getGlobalPosition = (animation) => {
      const worldPoint = new Phaser.Math.Vector2();
      this.cameras.main.getWorldPoint(animation.x, animation.y, worldPoint);
      return {
        ...worldPoint,
        elementWidth: animation.displayWidth,
        elementHeight: animation.displayHeight,
        elementX: animation.x,
        elementY: animation.y,
        baseYOffset: this.baseYOffset,
        baseXOffset: this.baseXOffset,
      };
    };
    return getGlobalPosition(this.columns[1][1]);
  }
}

export default SlotMachineScene;
