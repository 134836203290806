import { useEffect } from 'react';
import { overlayGame } from './index';

function OverlayGame() {
  useEffect(() => {
    const parentId = 'overlay-game'; // ID container
    overlayGame.updateParent(parentId);
  }, []);

  return (
    <div
      id="overlay-game"
      className="absolute top-0 w-screen h-screen z-[10]"
      style={{
        pointerEvents: 'none',
      }}
    />
  );
}

export default OverlayGame;
