import { useEffect } from 'react';
import { useAppSelector } from '../store';
import { useCountDown } from './useCoundown';

export const useEnergyRecoveryTimer = () => {
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const userEnergy = Math.floor(userData?.energy || 0);
  const maxEnergy = appSettings?.max_energy || 0;
  const energyRecoveryPerSecond = appSettings?.energy_recovery_per_second || 1;
  function calculateEnergyToNextSection(currentEnergy: number, sections = 5): number {
    const sectionCapacity = maxEnergy / sections;
    const currentSection = Math.floor(currentEnergy / sectionCapacity);
    const nextSectionThreshold = sectionCapacity * (currentSection + 1);

    if (currentEnergy >= maxEnergy) {
      return 0;
    }
    return nextSectionThreshold - currentEnergy;
  }
  const missingEnergyForSection = calculateEnergyToNextSection(userEnergy);
  const totalSecondsForFullRecovery = energyRecoveryPerSecond > 0 ? missingEnergyForSection / energyRecoveryPerSecond : 0;
  const shouldStartTimer = userEnergy < maxEnergy;

  const countdown = useCountDown(shouldStartTimer ? Math.round(totalSecondsForFullRecovery) : undefined);

  useEffect(() => {
    if (shouldStartTimer) {
      countdown.restart(Math.round(totalSecondsForFullRecovery));
    }
  }, [userEnergy, maxEnergy, energyRecoveryPerSecond]);

  return {
    countdown, shouldStartTimer,
  };
};
