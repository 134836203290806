// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { axiosInstance, RootState } from '../../index';
import { QuestStartedResp, QuestT, TQuestsResponse } from '../../../types';
import { getMe, updateProfileEnergy } from '../profile/asyncActions';
// Types

export const getQuests = createAsyncThunk<TQuestsResponse>(
  'quests/getQuests',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('/quests/user');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const startQuest = createAsyncThunk<
    QuestStartedResp,
    { id: string | number }
>(
  'quests/start',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(`/quests/${id}/start`);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const finishQuest = createAsyncThunk<
    QuestStartedResp,
    { id: string | number }
>(
  'quests/finish',
  async ({ id }, { rejectWithValue, dispatch, getState }) => {
    try {
      const maxEnergy = (getState() as RootState)?.settings.settings?.max_energy;
      const { data }: { data: QuestStartedResp } = await axiosInstance.patch(`/quests/${id}/finish`);
      if (data.quest.type === 'daily_energy_refill' && maxEnergy) {
        dispatch(updateProfileEnergy({ energy: maxEnergy, replace: true }));
        dispatch(getQuests()); // required to update timers
      }
      dispatch(getMe());
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const verifyQuest = createAsyncThunk<
    QuestT,
    QuestT
>(
  'quests/verify',
  async (quest, { rejectWithValue }) => {
    try {
      return quest;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
