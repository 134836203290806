import React from 'react';
import Drawer from '../../../Components/Drawer';
import { ReactComponent as Coin } from '../../../assets/svg/coin.svg';
import Button from '../../../Components/Button';
import { formatDate } from '../../../helpers';
import { AviatorHistoryT } from '../../../types';

type BetsHistoryModalProps = {
  open: boolean
  onClose: () => void
  aviatorHistory: AviatorHistoryT[]
}
function BetsHistoryModal({
  onClose, open, aviatorHistory,
}: BetsHistoryModalProps) {
  return (
    <Drawer
      side="bottom"
      open={open}
      onClose={onClose}
      hideClose
      wrapperClassName="flex flex-col overflow-y-scroll h-screen shadow-xl bg-black-modal-bg z-[9] relative stroke-gray-10 text-start stroke-[none]"
    >
      <div className="mt-4 px-4 overflow-hidden">
        <div className="flex justify-between text-start">
          <div>
            My Bets
          </div>
          <div>
            <Button className="bg-black-light-bg px-3 py-2 rounded-10" pureClass onClick={onClose}>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5625 4.0625L14.4375 14.9375M14.4375 4.0625L3.5625 14.9375" stroke="#5F6168" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-4 stroke-[none] overflow-auto h-full pb-20">
          {/* eslint-disable-next-line react/no-array-index-key */}
          {aviatorHistory.map((bet, index) => <BetCard bet={bet} key={bet.created_at + index} />)}
        </div>
      </div>
    </Drawer>
  );
}
export default BetsHistoryModal;

function BetCard({ bet }: { bet: AviatorHistoryT }) {
  return (
    <div className="flex justify-between items-center bg-black-light-bg p-4 rounded-10">
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-xs text-gray-2 text-start w-[28%]">
              Date
            </th>
            <th className="text-xs text-gray-2 text-start w-[50%]">Multiplier</th>
            <th className="text-xs text-gray-2 text-end w-[12%]">Reward</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-sm">
              {formatDate(bet.created_at)}
            </td>
            <td className="text-sm">
              {bet.result_value}
              x
            </td>
            <td className="text-sm flex items-center gap-1">
              <span>
                {bet.reward_coins}
              </span>
              <span>
                <Coin />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
