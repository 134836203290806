import React from 'react';
import { ReactComponent as EnergySvg } from '../../../assets/images/energy-40x40.svg';
import Button from '../../../Components/Button';
import RefilCountdown from '../../../Components/RefilCountdown';
import { useGetEnergyRefillTimer } from '../../../hooks/useGetEnergyRefillTimer';
import { useAppSelector } from '../../../store';
import { SettingsResponse } from '../../../store/reducers/settings/types';

type EnergyDrawerContentProps = {
    handleEnergyFill: () => void
}
function EnergyDrawerContent({ handleEnergyFill }: EnergyDrawerContentProps) {
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const {
    countdownTime, completedEnergyQuests, totalEnergyQuests,
  } = useGetEnergyRefillTimer();
  return (
    <div className="bg-dailyEnergyBgStar bg-no-repeat bg-energyBgStarPosition h-full">
      <div className="mt-[35px] stroke-none">
        <EnergySvg height="100px" width="100px" className="m-auto" />
      </div>
      <div className="text-lg mt-4 font-bold">Energy fill</div>
      <div className="text-xs text-gray-2 mt-2">Checkin every day to stay engagedwith our project.</div>
      <div className="text-sm mt-2">
        {completedEnergyQuests}
        {' '}
        <span className="text-gray-2">/</span>
        {' '}
        {totalEnergyQuests}
      </div>
      {(countdownTime > 0 && completedEnergyQuests !== totalEnergyQuests)
        ? (
          <div className="flex justify-center items-center mt-2">
            <RefilCountdown dailyEnergyRefillRefreshSeconds={(appSettings as SettingsResponse).daily_energy_refill_refresh_seconds} />
          </div>
        )
        : (
          <div className="px-4">
            <Button className="mt-2 w-full" onClick={handleEnergyFill} disabled={completedEnergyQuests === totalEnergyQuests}>Fill</Button>
          </div>
        )}
    </div>
  );
}

export default EnergyDrawerContent;
