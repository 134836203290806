import { useEffect, useState } from 'react';
import { ReactComponent as CoinSVG } from '../../assets/svg/coin.svg';
import { AutopilotT, ShopItemLevelT, ShopItemT } from '../../types';
import ProgressBar from '../ProgressBar';
import ShopItemButtons from '../ShopComponents/ShopButtons';
import StoreItemBgImage from '../ShopComponents/StoreItemBgImage';
import { useAppSelector } from '../../store';
import { useCountDown } from '../../hooks/useCoundown';
import { getSecondsBetweenDates } from '../../helpers';

type StoreItemProps = {
  item: ShopItemT
  onBuy: (item: ShopItemT) => void
  onStartFarm: () => void
  onCollectFarm: () => void
}
function PermanentStoreItem({
  item, onBuy, onStartFarm, onCollectFarm,
}: StoreItemProps) {
  const { autopilot } = useAppSelector(
    ({ shop }) => shop,
  );
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const nextLevel = [...item.levels || []].sort((level) => level.level).find((level) => !level.purchased_at);
  const progressbarLegend = item.levels?.map((level) => `+${level.effects[0]?.value}`);
  const purchasedLevels = [...item.levels || []].filter((level) => level.purchased_at);
  const currentLevel: ShopItemLevelT | undefined = purchasedLevels[purchasedLevels.length - 1] ? purchasedLevels[purchasedLevels.length - 1] : item.levels?.[0];
  const isAutopilot = item?.levels?.[0]?.effects[0]?.name === 'autopilot';
  const startSumValue = Number(currentLevel?.effects?.[0]?.value || 0) + (userSettings?.aviator_base_coins || 1000);
  return (
    <div className="bg-black-modal-bg p-4 rounded-10 flex flex-col gap-2">
      <div className="flex gap-2">
        <div className={`bg-black-main rounded-10 p-4 ${StoreItemBgImage({ categoryName: item.category.name }).bg} min-h-[64px] min-w-[64px] bg-centerCenter`}>
          <div className="w-full h-full flex flex-col relative">
            <img src={StoreItemBgImage({ categoryName: item.category.name }).icon} alt="icon" className="h-[48px] w-[48px] m-auto" />
          </div>
        </div>
        <div className="flex-col flex justify-between w-full">
          <div>
            <div className="text-lg">{item.name}</div>
            <div className="text-xs text-gray-2 mt-3">
              {item.description}
            </div>
          </div>
        </div>
      </div>
      {item?.levels?.[0]?.effects[0]?.interval
        ? (
          <div className="bg-black-light-bg p-3 rounded-10">
            <div className="text-base flex items-center gap-2">
              {currentLevel?.effects[0]?.value}
              <CoinSVG />
              <span className="text-sm text-gray-2">
                /
                {' '}
                {currentLevel?.effects[0]?.description}
              </span>
            </div>
          </div>
        )
        : (
          <div className="bg-black-light-bg p-3 rounded-10">
            <div className="text-base flex items-center gap-2">
              <span className="text-sm text-gray-2">
                {nextLevel?.effects[0]?.description}
              </span>
              +
              {isAutopilot ? currentLevel?.effects[0]?.value : startSumValue}
              <CoinSVG />
            </div>
          </div>
        )}
      {isAutopilot
          && autopilot?.started_at
          && (autopilot.balance === autopilot.coins_per_interval)
          && (
          <EarnedInfo autopilot={autopilot} />
          )}
      {item?.levels?.length
          && (
          <ProgressBar
            currentValue={purchasedLevels.length}
            goal={item.levels.length}
            size="small"
            hideLine
            legend={progressbarLegend}
          />
          )}
      <ShopItemButtons onBuy={onBuy} item={item} activeTab="permanent" onStartFarm={onStartFarm} onCollectFarm={onCollectFarm} />
    </div>
  );
}

export default PermanentStoreItem;

function EarnedInfo({ autopilot }: {autopilot: AutopilotT}) {
  const secondsFromStart = getSecondsBetweenDates(new Date(autopilot.started_at || ''), new Date());
  const { formatedSecond, formatedMinutes, hours } = useCountDown(secondsFromStart, true);
  const [currentBalance, setCurrentBalance] = useState<number>(autopilot.balance);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentBalance < autopilot.coins_per_interval) {
        setCurrentBalance((prev) => prev + autopilot.coins_per_second);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="bg-black-light-bg p-3 rounded-10 text-gray-2 flex gap-2 items-center">
      Earned
      {' '}
      {Math.floor(currentBalance)}
      <CoinSVG />
      {' '}
      for
      {' '}
      {hours}
      :
      {formatedMinutes}
      :
      {formatedSecond}
    </div>
  );
}
