import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { ReactComponent as Info } from '../../assets/svg/info.svg';
import ProgressBar from '../../Components/ProgressBar';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import DraggableDrawer from '../../Components/DraggableDrawer';
import Button from '../../Components/Button';
import LeaderboardUserCard from '../../Components/LeaderboardUserCard';
import { LeaguesUnion, ProfileResponseT } from '../../types';
import { useAppSelector } from '../../store';
import LeaguesModal from './LeaguesModal';
import { SettingsResponse } from '../../store/reducers/settings/types';
import LeagueIcon from '../../Components/LeagueIcon';
import { formatNumbers, getNextLeagueCoins, numberWithCommas } from '../../helpers';

const tg = window?.Telegram.WebApp;

function Leaderboard() {
  const {
    leaderboard: topPlayers,
  } = useAppSelector(({ leaderboard }) => leaderboard);
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const leaguesList: LeaguesUnion[] = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Challenger', 'Legendary', 'Master'];
  const currentLeague: LeaguesUnion = userData?.league.name as LeaguesUnion;
  const nextLeague: LeaguesUnion = leaguesList[leaguesList.indexOf(currentLeague) + 1];
  const statsRef = useRef<HTMLDivElement>(null);

  const [userCardVisible, setUserCardVisible] = useState<boolean>(true);
  const [activeLeague, setActiveLeague] = useState<LeaguesUnion>(currentLeague);
  const [snapArr, setSnapArr] = useState<string[]>(['150px', '75%', '100%']);
  const [snap, setSnap] = useState<number | string>(snapArr[0]);
  const [openLeaguesModal, setOpenLeaguesModal] = useState<boolean>(false);
  const goldFrom = useMemo(() => userSettings?.leagues[userSettings?.leagues?.findIndex((league) => league.name === activeLeague)].from, [userSettings, userData, activeLeague]);
  const goldToNextLeague: number = useMemo(() => getNextLeagueCoins((userSettings as SettingsResponse).leagues, (userData as ProfileResponseT).league.name), [userSettings, userData]);
  useEffect(() => {
    if (statsRef.current) {
      const calculatedOffset = `${tg.viewportStableHeight - statsRef.current.offsetTop - statsRef.current.offsetHeight - 50}px`;
      const tempArr = [...snapArr];
      tempArr[0] = calculatedOffset;
      setSnapArr(tempArr);
      setSnap(calculatedOffset);
    }
  }, [statsRef.current]);
  const getStarBg = (league: LeaguesUnion) => {
    switch (league) {
      case 'Bronze':
        return 'before:bg-leaderboardBronzeBg';
      case 'Silver':
        return 'before:bg-leaderboardSilverBg';
      case 'Gold':
        return 'before:bg-leaderboardGoldBg';
      case 'Platinum':
        return 'before:bg-leaderboardPlatinumBg';
      case 'Diamond':
        return 'before:bg-leaderboardDiamondBg';
      case 'Challenger':
        return 'before:bg-leaderboardChallengerBg';
      case 'Legendary':
        return 'before:bg-leaderboardLegendaryBg';
      case 'Master':
        return 'before:bg-leaderboardMasterBg';
      default:
        return 'before:bg-leaderboardBronzeBg';
    }
  };

  return (
    <div className="absolute h-screen w-screen  bg-no-repeat  top-0">
      <div className="mt-12">
        <div className={`mt-[12vh] flex flex-col items-center before:content-[''] 
             ${getStarBg((userData as ProfileResponseT)?.league.name)} 
             before:w-screen before:left-0 before:bg-centerCenter before:h-[40vh] before:absolute before:bg-no-repeat before:top-0 before:z-[1]`}
        >
          <div className="z-[1] h-fit">
            <div className="text-gray-2 text-sm text-[700]">Your rating</div>
            <div className="h-[70px] max-w-[90px]">
              <LeagueIcon league={(userData as ProfileResponseT)?.league.name} />
            </div>
            <div className="text-white text-xl mt-2 font-bold">
              {currentLeague}
            </div>
          </div>
          <div className="mt-4 w-full px-4" ref={statsRef}>
            <div className="max-w-[450px] mx-auto my-0">
              <div>
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <div className="h-[20px] max-w-[25px]">
                      <LeagueIcon league={currentLeague} />
                    </div>
                    {currentLeague}
                  </div>
                  <div className="flex gap-2 items-center">
                    {nextLeague}
                    <div className="h-[20px] max-w-[25px]">
                      <LeagueIcon league={nextLeague} />
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <ProgressBar currentValue={userData?.total_earned_coins || 0} goal={goldToNextLeague} />
                </div>
                <div className="flex justify-center items-center mt-2 gap-1">
                  <span>
                    {formatNumbers(userData?.total_earned_coins || 0)}
                  </span>
                  <span>
                    <Coin />
                  </span>
                  <span className="text-gray-2 mx-1">/</span>
                  <span>
                    {numberWithCommas(goldToNextLeague)}
                  </span>
                  <span>
                    <Coin />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <DraggableDrawer
            snap={snap}
            setSnap={setSnap}
            snapPoints={snapArr}
            open
            dismissible={false}
            onOpenChange={() => {
              setSnap(snapArr[0]);
            }}
          >
            <div>
              <div className="mt-4 bg-black-light-bg rounded-10 py-1 flex justify-between items-start w-full mx-auto my-0 px-1 gap-[1px]">
                {userSettings?.leagues.map((league) => (
                  <div
                    onClick={() => setActiveLeague(league.name)}
                    className={`flex flex-col w-fit text-sm min-w-[42px] max-w-[50px] items-start rounded-10 text-gray-300 ${activeLeague === league.name && 'bg-black-modal-bg text-white'}`}
                    key={league.name}
                  >
                    <div className="p-0 my-0 mx-auto h-[20px] w-[25px]">
                      <LeagueIcon league={league.name} />
                    </div>
                    <div className="text-center w-full truncate text-[10px]">
                      {league.name}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2 flex justify-between">
                <div>
                  <div className="text-lg">
                    {activeLeague}
                  </div>
                  <div className="flex items-center gap-2 text-gray-2 text-sm">
                    from
                    {' '}
                    {numberWithCommas(goldFrom || 0)}
                    <Coin />
                  </div>
                </div>
                <Button
                  onClick={() => setOpenLeaguesModal(true)}
                  className="px-[9px] rounded-10 focus:outline-none transform active:scale-75 transition-transform"
                  pureClass
                >
                  <Info height="24px" width="24px" />
                </Button>
              </div>
              <div
                className="mt-4 flex flex-col gap-2 overflow-auto max-h-[75vh] h-fit pb-[155px]"
              >
                {topPlayers?.[activeLeague].map((leader) => (
                  <LeaderboardUserCard
                    key={leader.telegram_id}
                    currentUserId={leader.telegram_id}
                    {...leader}
                    onVisible={() => setUserCardVisible(false)}
                    onHidden={() => setUserCardVisible(true)}
                  />
                ))}
              </div>
            </div>
          </DraggableDrawer>
          <LeaguesModal
            open={openLeaguesModal}
            onClose={() => setOpenLeaguesModal(false)}
            leagues={(userSettings as SettingsResponse)?.leagues}
          />
          {userData
          && (
          <div className="p-2 absolute z-[4] w-full bottom-[90px]">
            {userCardVisible
            && (
            <LeaderboardUserCard
              username={userData?.username || ''}
              league={userData?.league}
              currentUserId={userData?.telegram_id}
              total_earned_coins={userData.total_earned_coins}
              isUser
            />
            )}
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
