// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Phaser from 'phaser';
import eventEmitter, { emitGameEvent, GameEvents } from './eventEmiter';
import { calculateScale } from '../../helpers';

export type AviatorAnimationsUnion = 'all' | 'emotions' | 'explosion' | 'fire' | 'flying' | 'flying_with_blinking' | 'flying_with_blinking_hot'
| 'flying_with_emotions' | 'hot' | 'planet' | 'racoon' | 'sun' | 'takeoff' | 'ufo'
export const fileListEdit = [
  {
    name: 'rocket',
    jsonPath: 'aviator/character/characters.json',
    atlasPath: 'aviator/character/characters.atlas',
    skins: ['default'],
    attachments: [null],
    animations: ['all'],
  },
  // {
  //   name: 'rocket',
  //   jsonPath: 'aviator/rocket_small_ship/characters.json',
  //   atlasPath: 'aviator/rocket_small_ship/characters.atlas',
  //   skins: ['default'],
  //   attachments: [null],
  //   animations: ['all'],
  // },
  {
    name: 'background',
    jsonPath: 'aviator/background/background.json',
    atlasPath: 'aviator/background/background.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'racoon',
    jsonPath: 'aviator/racoon/racoon.json',
    atlasPath: 'aviator/racoon/racoon.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'sun',
    jsonPath: 'aviator/sun/sun.json',
    atlasPath: 'aviator/sun/sun.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'ufo',
    jsonPath: 'aviator/ufo/ufo.json',
    atlasPath: 'aviator/ufo/ufo.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'planet',
    jsonPath: 'aviator/planet/planet.json',
    atlasPath: 'aviator/planet/planet.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coins_shower',
    jsonPath: 'overlay/coins/coins.json',
    atlasPath: 'overlay/coins/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
];

const racoonAnimations = ['racoon_fly1', 'racoon_fly2', 'racoon_fly3'];
const ufoAnimations = ['ufo_fly_1', 'ufo_fly_2', 'ufo_fly_3'];
const planetAnimations = ['planet_fly', 'planet_fly2', 'planet_fly3'];

export class AviatorScene extends Phaser.Scene {
  private rocketData = null;

  private backgroundData = null;

  private racoonData = null;

  private ufoData = null;

  private planetData = null;

  private coinsShowerData = null;

  private sunData = null;

  private scrollSpeed = 0.5; // Adjust this for slower/faster scrolling

  private initialScrollSpeed = 2.7;

  private isGameStarted = false;

  private initialUpdates = 0;

  constructor() {
    super('AviatorScene');
  }

  preload() {
    this.load.setPath('spine');
    // eslint-disable-next-line prefer-destructuring
    this.rocketData = fileListEdit[0];
    // eslint-disable-next-line prefer-destructuring
    this.backgroundData = fileListEdit[1];
    // eslint-disable-next-line prefer-destructuring
    this.racoonData = fileListEdit[2];
    // eslint-disable-next-line prefer-destructuring
    this.sunData = fileListEdit[3];
    // eslint-disable-next-line prefer-destructuring
    this.ufoData = fileListEdit[4];
    // eslint-disable-next-line prefer-destructuring
    this.planetData = fileListEdit[5];
    // eslint-disable-next-line prefer-destructuring
    this.coinsShowerData = fileListEdit[6];
    fileListEdit.forEach((file) => {
      this.load.spine(file.name, file.jsonPath, file.atlasPath);
    });
    this.load.svg('pointer', 'aviator/pointer.svg');
  }

  create() {
    this.eventEmitter = eventEmitter;
    let rocket;
    let racoon;
    let ufo;
    let planet;
    let coinsShower;
    let background;
    let sun;
    let currentTrackTime;
    this.game.renderer.pipelines.imageSmoothingQuality = 'high';
    this.cameras.main.roundPixels = false;
    this.scale.resolution = window.devicePixelRatio;
    this.cameras.main.setRoundPixels(false);
    this.textures.get('characters.png').setFilter(Phaser.Textures.FilterMode.LINEAR);
    this.textures.get('characters2.png').setFilter(Phaser.Textures.FilterMode.LINEAR);
    const setBackground = () => { // TODO: calculate proper gb by animation seconds
      // eslint-disable-next-line prefer-const
      background = this.add.spine(
        this.game.scale.width / 2,
        this.game.scale.height,
        this.backgroundData.name,
        this.backgroundData.animations[0],
        true,
      );
      // background.setScale(0.8);
      const scaleX = this.cameras.main.width / background.width;
      const scaleY = this.cameras.main.height / background.height;
      const scale = Math.max(scaleX, scaleY);
      background.setScale(scale).setScrollFactor(0);
    };
    setBackground();
    const setAnimation = (char, animation: AviatorAnimationsUnion, loop = false, track = 0, delayInSeconds = 0) => {
      char.state.setAnimation(track, animation, loop, delayInSeconds);
    };

    // eslint-disable-next-line prefer-const
    rocket = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.rocketData.name,
      null,
      false,
    );
    rocket.setScale(calculateScale(rocket, 93, 190) * 3);
    // rocket.setScale(0.28, 0.25);
    rocket.setDepth(10);

    function pauseSpineAnimation(gameObject, trackIndex = 0) {
      const currentTrack = gameObject.state.getCurrent(trackIndex);
      if (currentTrack) {
        // Save animation time
        currentTrackTime = currentTrack.trackTime;
        gameObject.state.setEmptyAnimation(trackIndex, 0); // Stop animation
        window.console.log('Animation paused:', currentTrackTime);
      } else {
        window.console.error('Animation track not found');
      }
    }

    function resumeSpineAnimation(gameObject, animationName, trackIndex = 0) {
      gameObject.setAnimation(trackIndex, animationName, false); // Start animation again
      const currentTrack = gameObject.state.getCurrent(trackIndex);
      if (currentTrack) {
        currentTrack.trackTime = currentTrackTime; // Reset track time
        window.console.log('Resume animation:', currentTrackTime);
      } else {
        window.console.error('Failed to resume animation.');
      }
    }
    const showExplosion = () => {
      // eslint-disable-next-line
      let currentTrack = rocket.state.getCurrent(0);
      if (currentTrack) {
        currentTrackTime = currentTrack.trackTime;
        rocket.state.setAnimation(0, 'explosion', false);
        rocket.once('complete', () => {
          emitGameEvent({ event: GameEvents.EXPLOSION_END });
        });
      }
      this.time.removeAllEvents();
    };

    rocket.stateData.setMix('takeoff', 'takeoff', 0.2);
    rocket.stateData.setMix('takeoff', 'all', 0.5);
    rocket.stateData.setMix('all', 'explosion', 0.2);
    rocket.stateData.setMix('explosion', 'all', 0.2);

    const takeoff = () => {
      this.time.addEvent({
        delay: 700,
        // repeatCount: 6,
        callback: () => {
          // Start from
          setAnimation(rocket, 'takeoff', true);
          setAnimation(background, 'takeoff', true);
        },
        loop: true,
      });
    };

    function getRandomAnimation(animations: string[]): string {
      return animations[Math.floor(Math.random() * animations.length)];
    }

    function playRandomAnimation(spineObject: Phaser.GameObjects.Spine, animations: string[]) {
      const randomAnimation = getRandomAnimation(animations);
      spineObject.setAnimation(0, randomAnimation, false);
    }

    function startRandomAnimationLoop(spineObject: Phaser.GameObjects.Spine, minDelay: number, maxDelay: number, animations: string[], maxDepth = 11) {
      function scheduleNextAnimation() {
        const delay = Phaser.Math.Between(minDelay, maxDelay);
        spineObject.scene.time.delayedCall(delay, () => {
          playRandomAnimation(spineObject, animations);
          spineObject.visible = true;
          const depth = Math.floor(Math.random() * maxDepth) + 1;
          spineObject.setDepth(depth);
          spineObject.setScale(depth * 0.05);
          scheduleNextAnimation();
        });
      }

      scheduleNextAnimation();
    }

    // eslint-disable-next-line prefer-const
    racoon = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.racoonData.name,
      'racoon_fly1',
      // 'racoon_fly2',
      false,
    );
    racoon.visible = false;
    racoon.setScale(0.4);
    // eslint-disable-next-line prefer-const
    ufo = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.ufoData.name,
      ufoAnimations[0],
      false,
    );
    ufo.visible = false;
    ufo.setScale(0.4);
    // eslint-disable-next-line prefer-const
    planet = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.planetData.name,
      ufoAnimations[0],
      false,
    );
    planet.visible = false;
    planet.setScale(0.4);
    planet.timeScale = 0.5;
    // eslint-disable-next-line prefer-const
    sun = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.sunData.name,
      'sun_fly',
      false,
    );
    sun.visible = false;
    sun.setScale(0.4);

    // eslint-disable-next-line prefer-const
    coinsShower = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.coinsShowerData.name,
      null,
      false,
    );
    coinsShower.setScale(calculateScale(coinsShower, this.game.scale.width + 50, this.game.scale.height + 100));
    // coinsShower.setScale(0.35, 0.295);
    const playNextAnimationWithDelay = (spineObject: Phaser.GameObjects.Spine) => {
      const frameRate = this.game.loop.actualFps;
      const delay = (440 / frameRate) * 1000;
      spineObject.scene.time.delayedCall(delay, () => {
        spineObject.setAnimation(0, 'sun_fly', false);
        sun.timeScale = 0.5;
        sun.visible = true;
      });
    };

    function startGame() {
      rocket.state.addAnimation(0, 'all', false).trackTime = 1;
      playNextAnimationWithDelay(sun);
      startRandomAnimationLoop(racoon, 2000, 20000, racoonAnimations);
      startRandomAnimationLoop(ufo, 2000, 20000, ufoAnimations);
      startRandomAnimationLoop(planet, 2000, 10000, planetAnimations, 6);
      rocket.stateData.setMix('all', 'flying_with_blinking_hot', 0.2);
      rocket.state.addAnimation(0, 'flying_with_blinking_hot', true);
      setAnimation(background, 'takeoff', false);
      background.timeScale = 0.5;
    }

    const calculateSpeed = (interval) => {
      const baseInterval = 100;
      return (baseInterval / interval) * this.initialScrollSpeed;
    };

    const runCoinShower = () => {
      coinsShower.state.setAnimation(0, 'win', false).trackTime = 1;
      coinsShower.once('complete', () => {
        emitGameEvent({ event: GameEvents.OVERLAY_COINS_RAIN_START });
      });
    };
    eventEmitter.on(GameEvents.SHOW_WIN_COINS_AVIATOR_LAYER, () => {
      runCoinShower();
    });
    eventEmitter.on(GameEvents.START_GAME, () => {
      this.time.removeAllEvents();
      startGame();
      this.isGameStarted = true;
    });
    eventEmitter.on(GameEvents.STOP_GAME, () => {
      this.isGameStarted = false;
      showExplosion();
      background.timeScale = 0;
    });
    eventEmitter.on(GameEvents.TAKEOFF, () => {
      takeoff();
    });
    eventEmitter.on(GameEvents.RESUME_GAME, () => {
      resumeSpineAnimation(rocket, 'all');
    });
    eventEmitter.on(GameEvents.PAUSE_GAME, () => {
      this.isGameStarted = false;
      pauseSpineAnimation(rocket, 0);
    });
    eventEmitter.on(GameEvents.SET_AVIATOR_SPEED, ({ speed }) => {
      this.scrollSpeed = calculateSpeed(speed);
    });
    eventEmitter.on(GameEvents.RESTART, () => {
      eventEmitter.off(GameEvents.START_GAME);
      eventEmitter.off(GameEvents.STOP_GAME);
      eventEmitter.off(GameEvents.TAKEOFF);
      eventEmitter.off(GameEvents.RESUME_GAME);
      eventEmitter.off(GameEvents.PAUSE_GAME);
      eventEmitter.off(GameEvents.RESTART);
      eventEmitter.off(GameEvents.WIN_GAME);
      eventEmitter.off(GameEvents.SET_AVIATOR_SPEED);
      eventEmitter.off(GameEvents.SHOW_WIN_COINS_AVIATOR_LAYER);
      this.scene.restart();
    });
    eventEmitter.on(GameEvents.WIN_GAME, () => {
      this.isGameStarted = false;
      pauseSpineAnimation(rocket, 0);
      pauseSpineAnimation(background, 0);
      background.clearTrack(0); // pause background
      this.tweens.add({
        targets: rocket,
        y: -rocket.height,
        duration: 2000,
        ease: 'Power1',
        callbacks: () => {
          runCoinShower();
        },
      });
    });

    // size of scale
    const scaleWidth = 20; // width
    const scaleHeight = this.scale.height * 0.4; // height of scale (40%)

    const x = 20; // x position by left side
    const y = this.scale.height / 3 - scaleHeight / 2; // y by centers

    const maskShape = this.add.graphics();
    // maskShape.fillStyle(0xff0000, 0.5); // debug red mask
    maskShape.fillRect(0, y + 100, scaleWidth * 2, scaleHeight * 0.6);
    this.mask = maskShape.createGeometryMask();

    // scale graphic
    this.scaleGraphics = this.add.graphics();
    this.scaleGraphics.setPosition(x, y);
    this.scaleGraphics.setMask(this.mask);

    const pointerX = 45;
    const pointerY = this.scale.height / 3 + 55;
    this.add.image(pointerX, pointerY, 'pointer');

    this.displayText = -2;

    // initial offset
    this.offset = 100;
    this.divisionTexts = [];
    this.drawScale();
  }

  drawScale() {
    const scaleHeight = this.scale.height * 0.4; // scale height
    const totalDivisions = 50;
    const divisionHeight = 20;
    const y = this.scale.height / 3 - scaleHeight / 2; // y by centers
    this.scaleGraphics.clear(); // clear scale
    this.divisionTexts.forEach((text) => {
      text.y += this.scrollSpeed;

      // delete text if it below mask
      if (text.y > this.disappearLine) {
        text.destroy();
        this.divisionTexts.shift();
      }
    });

    if (this.divisionTexts.length === 0 || this.divisionTexts[0].y > divisionHeight) {
      this.displayText += 1;
      // add new number above
      const posY = this.divisionTexts.length > 0
        ? this.divisionTexts[0].y - (divisionHeight * 5)
        : y + 300; // start position of first number
      const newText = this.add.text(5, posY, `${this.displayText.toString()}x`, {
        font: '14px Arial',
        color: '#ffffff',
      });
      newText.setMask(this.mask);
      this.divisionTexts.unshift(newText);
    }

    // draw with offset
    for (let i = totalDivisions; i >= 0; i--) {
      const posY = i * divisionHeight - this.offset; // move from top to bottom

      // stop drawing
      // eslint-disable-next-line no-continue
      if (posY < 0 || posY > scaleHeight) continue;

      // bigger marks each 5th
      if (i % 5 === 0) {
        this.scaleGraphics.lineStyle(2, 0xffffff); // big line
        this.scaleGraphics.lineBetween(6, posY + 5, 12, posY + 5); // big line
      } else {
        this.scaleGraphics.lineStyle(1, 0xffffff); // small line
        this.scaleGraphics.lineBetween(8, posY + 5, 12, posY + 5); // small line
      }
    }
  }

  update() {
    this.offset -= this.scrollSpeed; // increase offset
    if (this.offset <= -100) {
      this.offset = 100; // reset position
    }
    if (this.initialUpdates === 70) {
      this.scrollSpeed = this.initialScrollSpeed; // initial speed for hack = 0.5
      this.initialUpdates += 1;
    }
    if (this.initialUpdates < 70) { // hack to set right position
      this.initialUpdates += 1;
      this.drawScale();
    }
    if (this.isGameStarted) {
      this.drawScale();
    }
  }

  setScaleValue(value) {
    this.currentValue = value;
    this.scaleGraphics.y = -value * 20;
  }
}
