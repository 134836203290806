import { useEffect, useState } from 'react';
import Button, { ButtonProps } from '../../Button';
import { ReactComponent as CircleCheck } from '../../../assets/svg/circleCheck.svg';
import { ReactComponent as CoinSVG } from '../../../assets/svg/coin.svg';
import LeagueIcon from '../../LeagueIcon';
import { AutopilotT, LeaguesUnion, ShopItemT } from '../../../types';
import { ShopTabs } from '../tabs';
import { useCountDown } from '../../../hooks/useCoundown';
import { useAppSelector } from '../../../store';
import { getSecondsBetweenDates } from '../../../helpers';
import { LeaguesSetting, SettingsResponse } from '../../../store/reducers/settings/types';

type ShopItemButtonsProps = {
  item: ShopItemT
  onBuy: (item: ShopItemT) => void
  activeTab?: typeof ShopTabs[number]
  onStartFarm?: () => void
  onCollectFarm?: () => void
  onSelectSkin?: () => void
  activeItemIds?: number[]
  disabled?: boolean
}
function ShopItemButtons({
  item, onBuy, activeTab, onStartFarm, onCollectFarm, onSelectSkin, activeItemIds, disabled,
}: ShopItemButtonsProps) {
  const { autopilot } = useAppSelector(
    ({ shop }) => shop,
  );
  const purchasedLevels = [...item.levels || []].filter((level) => !!level.purchased_at);

  const autopilotButtons = () => (
    <div className="flex gap-2 flex-col-reverse items-end">
      {/* eslint-disable-next-line no-nested-ternary */}
      {purchasedLevels?.length
        ? autopilot?.started_at ? <EarnedButton autopilot={autopilot} onCollectFarm={onCollectFarm} disabled={disabled} /> : <StartFarmButton onClick={onStartFarm} disabled={disabled} /> : null}
      <BuyButtons item={item} onBuy={onBuy} disabled={disabled} />
    </div>
  );
  const renderDynamicItemButtons = () => {
    if (item.category.name === 'Skins' && purchasedLevels?.length && item.levels) {
      return !activeItemIds?.includes(item.levels[0].id) ? <SelectButton onClick={onSelectSkin} disabled={disabled} /> : <SelectedButton disabled={disabled} />;
    }
    return <BuyButtons item={item} onBuy={onBuy} disabled={disabled} />;
  };
  const renderPermanentTabButtons = () => {
    if (item?.levels?.[0]?.effects[0].name === 'autopilot') {
      return autopilotButtons();
    }
    return <BuyButtons item={item} onBuy={onBuy} disabled={disabled} />;
  };
  const renderButton = () => {
    if (activeTab === 'permanent') {
      return renderPermanentTabButtons();
    }
    return renderDynamicItemButtons();
  };
  return renderButton();
}

export default ShopItemButtons;

type BuyBtnsProps = ButtonProps & {
  item: ShopItemT
  onBuy: (item: ShopItemT) => void
}
function BuyButtons({ item, onBuy, ...props }: BuyBtnsProps) {
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const purchasedLevels = [...item.levels || []].filter((level) => !!level.purchased_at);
  const nextLevel = [...item.levels || []].sort((level) => level.level).find((level) => !level.purchased_at);
  const requiredLeagueIndex = (userSettings as SettingsResponse).leagues.findIndex((league: LeaguesSetting) => league.name === nextLevel?.required_league);
  const userLeagueIndex = (userSettings as SettingsResponse).leagues.findIndex((league: LeaguesSetting) => league.name === userData?.league.name);

  if (requiredLeagueIndex > userLeagueIndex) {
    return <RequiredLeagueButton league={nextLevel?.required_league || 'Bronze'} {...props} />;
  }
  if (purchasedLevels?.length === item.levels?.length) {
    return <PurchasedButton {...props} />;
  }
  if ((userData?.coins || 0) < (nextLevel?.price || 0)) {
    return <DisabledButton />;
  }
  if (purchasedLevels.length && purchasedLevels?.length !== item.levels?.length) {
    return (
      <ImproveButton onClick={() => onBuy(item)} {...props} />
    );
  }
  return <BuyButton onClick={() => onBuy(item)} {...props} />;
}

export function BuyButton({ onClick, ...props }: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      className="mt-2 w-full h-[36px] py-3 px-4 bg-green-main rounded-10 text-black-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal disabled:bg-gray-10 disabled:text-gray-600"
      pureClass
      {...props}
    >
      Buy
      {' '}
    </Button>
  );
}

export function ImproveButton({ onClick, ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full h-[36px] py-3 px-4 bg-green-main rounded-10 text-black-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
      onClick={onClick}
      {...props}
    >
      Improve
    </Button>
  );
}
export function StartFarmButton({ onClick, ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full h-[36px] py-3 px-4 bg-white rounded-10 text-black-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
      onClick={onClick}
      {...props}
    >
      Start Farm
    </Button>
  );
}
export function RequiredLeagueButton({ league }: ButtonProps & {league?: LeaguesUnion}) {
  return (
    <Button
      className="mt-2 w-full h-[36px] px-4 bg-orange-bg rounded-10 text-orange-text font-bold text-sm line-normal"
      pureClass
      disabled
    >
      <div className="flex items-center gap-2">
        <div className="h-[21.6px]">
          <LeagueIcon league={league || 'Bronze'} />
        </div>
        <div>
          Reach
          {' '}
          {league}
          {' '}
          League
        </div>
      </div>
    </Button>
  );
}

export function PurchasedButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full py-3 px-4 bg-green-text bg-opacity-15 rounded-10 text-green-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
      {...props}
    >
      <CircleCheck stroke="#1FD969" />
      {' '}
      Purchased
    </Button>
  );
}
export function SelectedButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full h-[36px] py-3 px-4 bg-green-text bg-opacity-15 rounded-10 text-green-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
      {...props}
    >
      <CircleCheck stroke="#1FD969" />
      {' '}
      Selected
    </Button>
  );
}

export function SelectButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full h-[36px] py-3 px-4 bg-white rounded-10 text-black-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
      {...props}
    >
      Select
    </Button>
  );
}

type EarnedButtonProps = ButtonProps & {
  autopilot: AutopilotT
  onCollectFarm?: () => void
}
export function EarnedButton({ autopilot, onCollectFarm, ...props }:EarnedButtonProps) {
  const [currentBalance, setCurrentBalance] = useState<number>(autopilot.balance);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBalance((prev) => prev + autopilot.coins_per_second);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (autopilot.balance) {
      setCurrentBalance(autopilot.balance);
    }
  }, [autopilot.balance]);
  const secondsToFinish = getSecondsBetweenDates(new Date(), new Date(autopilot.end_at || ''));
  const {
    formatedSecond, formatedMinutes, seconds, minutes,
  } = useCountDown(secondsToFinish);
  if (currentBalance >= autopilot.coins_per_interval || (!seconds && !minutes)) {
    return <CollectFarm onClick={onCollectFarm} />;
  }
  return (
    <Button
      className="mt-2 w-full h-[36px] py-[9px] px-4 bg-gradient-to-r from-[#6433A7] to-[#4C2088] rounded-10 text-black-text flex items-center justify-between gap-1 font-bold text-sm line-normal"
      pureClass
      {...props}
    >
      <div className="flex gap-2 text-white text-base items-center">
        <span className="text-gray-2 text-sm">
          Earned
        </span>
        {Math.floor(currentBalance)}
        <CoinSVG />
      </div>
      <div className="text-white bg-white bg-opacity-15 p-1 rounded-10">
        {formatedMinutes}
        :
        {formatedSecond}
      </div>
    </Button>
  );
}

export function CollectFarm({ ...props }: ButtonProps) {
  return (
    <Button
      className="w-full h-[36px] rounded-10 bg-gradient-to-r from-[#EFAF00] to-[#E47513] text-[#701E00]"
      pureClass
      {...props}
    >
      Collect reward
    </Button>
  );
}

export function DisabledButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full h-[36px] py-3 px-4 bg-green-main rounded-10 text-black-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal disabled:bg-gray-10 disabled:text-gray-600"
      pureClass
      disabled
      {...props}
    >
      Insufficient Coin Balance
    </Button>
  );
}
