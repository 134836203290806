// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Phaser from 'phaser';
import eventEmitter, { GameEvents } from '../../pages/Home/eventEmiter';
import { slotMachineGame } from '../../Layouts/AppWrapper';
import { calculateScale } from '../../helpers';

const fileList = [
  {
    name: 'coins',
    jsonPath: 'coins/coins.json',
    atlasPath: 'coins/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'meteors',
    jsonPath: 'meteors/meteors.json',
    atlasPath: 'meteors/meteors.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'multiplier',
    jsonPath: 'multiplier/multiplier.json',
    atlasPath: 'multiplier/multiplier.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'galaxy',
    jsonPath: 'galaxySmall/galaxy.json',
    atlasPath: 'galaxySmall/galaxy.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coinsRain',
    jsonPath: 'coinsRain/coins.json',
    atlasPath: 'coinsRain/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'energy',
    jsonPath: 'energy/energy.json',
    atlasPath: 'energy/energy.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
];
export class OverlayScene extends Phaser.Scene {
  private coinsRain;

  constructor() {
    super('OverlayScene');
  }

  preload() {
    this.load.setPath('spine/overlay');
    fileList.forEach((file) => {
      this.load.spine(file.name, file.jsonPath, file.atlasPath);
    });
    // eslint-disable-next-line prefer-destructuring
    this.coinsData = fileList[0];
    // eslint-disable-next-line prefer-destructuring
    this.meteorsData = fileList[1];
    // eslint-disable-next-line prefer-destructuring
    this.multiplierData = fileList[2];
    // eslint-disable-next-line prefer-destructuring
    this.galaxyData = fileList[3];
    // eslint-disable-next-line prefer-destructuring
    this.coinsRainData = fileList[4];
    // eslint-disable-next-line prefer-destructuring
    this.energyData = fileList[5];
  }

  create() {
    let coins;
    let meteors;
    let multiplier;
    let galaxy;
    let energy;
    // eslint-disable-next-line prefer-const
    coins = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.coinsData.name,
      null,
      false,
    );
    // coins.setScale(0.35, 0.295);
    coins.setScale(calculateScale(coins, this.game.scale.width + 50, this.game.scale.height + 100));

    // eslint-disable-next-line prefer-const
    meteors = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.meteorsData.name,
      null,
      false,
    );
    meteors.visible = false;
    meteors.setDisplaySize(this.game.scale.width + 200, this.game.scale.height);

    // eslint-disable-next-line prefer-const
    multiplier = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height / 2,
      this.multiplierData.name,
      null,
      false,
    );
    multiplier.visible = false;
    multiplier.setScale(0.4);

    // eslint-disable-next-line prefer-const
    galaxy = this.add.spine(
      0,
      this.game.scale.height * 0.3,
      this.galaxyData.name,
      null,
      false,
    );
    galaxy.visible = false;
    galaxy.setScale(calculateScale(galaxy, 93, 190));

    // eslint-disable-next-line prefer-const
    this.coinsRain = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.coinsRainData.name,
      null,
      false,
    );
    this.coinsRain.visible = false;
    this.coinsRain.setScale(calculateScale(this.coinsRain, this.game.scale.width, this.game.scale.height));
    const runCoinRain = () => {
      this.coinsRain.visible = true;
      this.coinsRain.setAnimation(0, 'win', true);
      this.time.addEvent({
        delay: 2500,
        loop: true,
        callback: () => {
          const track0 = this.coinsRain.state.tracks[0];

          if (track0 && track0.trackTime >= (16 / this.coinsRain.skeletonData.animations[0].duration)) {
            this.coinsRain.setAnimation(1, 'win', true);
            this.coinsRain.state.tracks[0].loop = true;
            this.coinsRain.state.tracks[1].loop = true;
          }
        },
      });
    };
    // eslint-disable-next-line prefer-const
    energy = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.energyData.name,
      null,
      false,
    );
    energy.visible = false;
    energy.setScale(calculateScale(energy, 80, 80));
    energy.height = multiplier.height;
    energy.width = multiplier.width;

    const setPositionOfCentreElement = (spineChar) => {
      const position = slotMachineGame.scene.getScene('SlotMachineScene').getMiddleElementPosition();
      const canvasPosition = slotMachineGame.canvas.getBoundingClientRect();
      spineChar.setPosition(
        canvasPosition.left + position.elementX + (position.elementWidth / 2) - 30,
        canvasPosition.bottom - position.y + (position.elementHeight + (position.elementHeight / 2) + position.baseYOffset),
      );
    };
    const showMultiplierAnimation = (multiplerValue) => {
      setPositionOfCentreElement(multiplier);
      multiplier.visible = true;
      multiplier.state.setAnimation(0, `multiplier_x${multiplerValue}`, true);
      multiplier.once('complete', () => {
        eventEmitter.emit(GameEvents.SLOT_SHOW_MIDDLE);
        multiplier.visible = false;
      });
    };
    const showEnergyAnimation = () => {
      const position = slotMachineGame.scene.getScene('SlotMachineScene').getMiddleElementPosition();
      const canvasPosition = slotMachineGame.canvas.getBoundingClientRect();
      setPositionOfCentreElement(multiplier);
      const bounds1 = multiplier.getBounds();
      const yPos = canvasPosition.bottom - position.y - (position.baseYOffset - (position.baseYOffset / 2));
      const spine1CenterX = multiplier.x + bounds1.offset.x + bounds1.size.x / 2;
      const bounds2 = energy.getBounds();
      const spine2HalfWidth = bounds2.size.x / 2;
      energy.setPosition(spine1CenterX - spine2HalfWidth - bounds2.offset.x + 10, yPos + 10);
      energy.visible = true;
      energy.state.setAnimation(0, 'animation', false);
      energy.once('complete', () => {
        eventEmitter.emit(GameEvents.SLOT_SHOW_MIDDLE);
        energy.visible = false;
      });
      const targetFrame = 28;
      let frame = 0;
      let isReached = false;
      this.events.on('update', () => {
        if (frame === targetFrame && !isReached) {
          eventEmitter.emit(GameEvents.ENERGY_HIT_SCREEN);
          isReached = true;
          frame = 0;
        }
        frame += 1;
      });
    };
    const startGalaxyTransition = () => {
      galaxy.setPosition(
        -100,
        this.game.scale.height * 0.2,
      );
      galaxy.visible = true;
      galaxy.state.setAnimation(0, 'animation', false);
      galaxy.once('complete', () => {
        galaxy.visible = false;
      });
    };
    eventEmitter.on(GameEvents.SHOW_WIN_COINS, () => {
      this.coinsRain.visible = false;
      coins.state.setAnimation(0, 'win', false).trackTime = 1;
    });
    eventEmitter.on(GameEvents.OVERLAY_COINS_RAIN_START, () => {
      runCoinRain();
    });
    eventEmitter.on(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, (val) => {
      showMultiplierAnimation(val);
    });
    eventEmitter.on(GameEvents.OVERLAY_SHOW_ENERGY_ANIMATION, () => {
      showEnergyAnimation();
    });
    eventEmitter.on(GameEvents.OVERLAY_GALAXY_TRANSITION, () => {
      startGalaxyTransition();
    });
    eventEmitter.on(GameEvents.SHOW_METEORS, () => {
      meteors.state.setAnimation(0, 'win', false);
      meteors.visible = true;
      meteors.once('complete', () => {
        meteors.visible = false;
      });
    });
    eventEmitter.emit(GameEvents.OVERLAY_LOADED);
  }
}
