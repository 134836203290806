import Phaser from 'phaser';
import { AviatorAnimationsUnion } from './AviatorScene';
import { SlotMachineSpinResponse } from '../../types';

const eventEmitter = new Phaser.Events.EventEmitter();
export default eventEmitter;

export enum GameEvents {
    START_GAME = 'START_GAME',
    STOP_GAME = 'STOP_GAME',
    RESUME_GAME = 'RESUME_GAME',
    PAUSE_GAME = 'PAUSE_GAME',
    RESTART = 'RESTART',
    WIN_GAME = 'WIN_GAME',
    SHOW_WIN_COINS = 'SHOW_WIN_COINS',
    OVERLAY_SHOW_MULTIPLIER_ANIMATION = 'OVERLAY_SHOW_MULTIPLIER_ANIMATION',
    OVERLAY_SHOW_ENERGY_ANIMATION = 'OVERLAY_SHOW_ENERGY_ANIMATION',
    OVERLAY_GALAXY_TRANSITION = 'OVERLAY_GALAXY_TRANSITION',
    OVERLAY_COINS_RAIN_START = 'OVERLAY_COINS_RAIN_START',
    OVERLAY_COINS_RAIN_STOP = 'OVERLAY_COINS_RAIN_STOP',
    SLOT_HIDE_MIDDLE = 'SLOT_HIDE_MIDDLE',
    SLOT_SHOW_MIDDLE = 'SLOT_SHOW_MIDDLE',
    ENERGY_HIT_SCREEN = 'ENERGY_HIT_SCREEN',
    SHOW_WIN_COINS_AVIATOR_LAYER = 'SHOW_WIN_COINS_AVIATOR_LAYER',
    SHOW_METEORS = 'SHOW_METEORS',
    EXPLOSION_END = 'EXPLOSION_END',
    TAKEOFF = 'TAKEOFF',
    SLOT_START_SPIN = 'SLOT_START_SPIN',
    SLOT_STOP_SPIN = 'SLOT_STOP_SPIN',
    SLOT_LOADED = 'SLOT_START_SPIN',
    OVERLAY_LOADED = 'OVERLAY_LOADED',
    SET_AVIATOR_SPEED = 'SET_AVIATOR_SPEED',
}

export type GameEventsProps =
    {
    event: keyof typeof GameEvents
} |
 {
    event: GameEvents.START_GAME
    animations: AviatorAnimationsUnion
    loop?: boolean
} | {
    event: GameEvents.SLOT_START_SPIN
    targetSlots: SlotMachineSpinResponse['slots'][number]['type'][]
} | {
    event: GameEvents.SET_AVIATOR_SPEED
    speed: number
}

export const emitGameEvent = ({ ...props }: GameEventsProps) => {
  eventEmitter.emit(props.event, { ...props });
};

export const listenGameEvent = (event: keyof typeof GameEvents, fn: () => void) => {
  eventEmitter.on(event, fn);
};
