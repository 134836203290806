import {
  Drawer, DrawerContent, DrawerContentProps, DrawerOverlay, DrawerOverlayProps, DrawerTitle, Snap,
} from '@vladyoslav/drawer';
import React, {
  FC, forwardRef, ReactNode,
} from 'react';
import { DrawerRoot, type DrawerRootProps } from '@vladyoslav/drawer';

type DraggableDrawerProps = DrawerRootProps & {
    children: ReactNode
    open: boolean
    setSnap: (snap: Snap) => void
}
function DraggableDrawer({
  children, open, onOpenChange, snapPoints, snap, setSnap, ...props
}: DraggableDrawerProps) {
  return (
    <Root
      open={open}
      onOpenChange={onOpenChange}
      snapPoints={snapPoints}
      snap={snap}
      setSnap={setSnap}
      modal={false}
      {...props}
    >
      <Drawer.Portal>
        <Content
          aria-describedby={undefined}
          className="min-h-[95vh] bg-black-modal-bg rounded-t-[20px] fixed bottom-0 left-0 right-0 mx-auto text-white outline-none z-[2]"
        >
          <DrawerTitle />
          <div>
            <div className="bg-black-light-bg w-1/4 h-[3px] mt-2 m-auto" />
            <div className="p-3">
              {children}
            </div>
          </div>
        </Content>
      </Drawer.Portal>
    </Root>
  );
}

export default DraggableDrawer;

export function Root({ ...props }: DrawerRootProps) {
  return <DrawerRoot shouldScaleBackground {...props} />;
}

export const Overlay: FC<DrawerOverlayProps> = forwardRef<
    HTMLDivElement,
    DrawerOverlayProps
>((props, ref) => (
  <DrawerOverlay
    ref={ref}
    {...props}
  />
));

Overlay.displayName = 'Overlay';

export const Content: FC<DrawerContentProps> = forwardRef<
    HTMLDivElement,
    DrawerContentProps
>(({ className, ...props }, ref) => (
  <DrawerContent
    ref={ref}
    data-testid="content"
    className={className}
    {...props}
  />
));

Content.displayName = 'Content';
