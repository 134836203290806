import * as React from 'react';
import Drawer from '../Drawer';
import CoinsSvg from './CoinsSvg';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import Button from '../Button';

type AviatorWinModalProps = {
  multiplier: number | null
  coinsWon: string
  open: boolean
  onOkay: () => void
}
function AviatorWinModal({
  multiplier, coinsWon, onOkay, open,
}: AviatorWinModalProps) {
  return (
    <Drawer
      side="bottom"
      open={open}
      onClose={onOkay}
      closeStroke="gray-10"
      hideClose
      wrapperClassName="flex flex-col overflow-y-scroll h-80 shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#B73B20] to-[#821220] z-[9] relative stroke-gray-10"
    >
      <div className="bg-aviatorBgStar bg-centerXPosition bg-no-repeat h-full stroke-[none]">
        <div className="flex justify-center mt-4">
          <CoinsSvg multiplier={multiplier} />
        </div>
        <div className="flex justify-center mt-4">
          <div className="text-[26px] flex justify-center items-center gap-2">
            <span>
              {coinsWon}
            </span>
            <Coin width={22} height={22} />
          </div>
        </div>
        <div className="text-sm flex justify-center items-center gap-2 opacity-[80%]">
          Congratulations on your victory!
        </div>
        <Button className="w-[80%] mt-6 py-2" onClick={onOkay}>
          Okay
        </Button>
      </div>
    </Drawer>
  );
}

export default AviatorWinModal;
