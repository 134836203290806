import React, {
  useEffect, useRef, useState,
} from 'react';
import { initUtils } from '@telegram-apps/sdk';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import Button from '../../Components/Button';
import { useAppDispatch, useAppSelector } from '../../store';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { ReactComponent as LongArrow } from '../../assets/svg/longArrow.svg';
import { ReactComponent as Copy } from '../../assets/svg/copy.svg';
import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg';
import FriendCard from './FriendCard';
import { getReferrals } from '../../store/reducers/referrals/asyncActions';
import PilotsImg from '../../assets/images/pilots.svg';
import CoinImg from '../../assets/images/coin-size-42x42.svg';
import QuestTasksImg from '../../assets/images/quest-tasks.svg';
import Pages from '../../Router/pages';
import DraggableDrawer from '../../Components/DraggableDrawer';

const tg = window?.Telegram.WebApp;

function FriendsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const inviteFriendsRef = useRef<HTMLDivElement>(null);
  const bonusRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const personRef = useRef<HTMLDivElement>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [copiedTooltip, setCopiedTooltip] = useState<boolean>(false);
  const [snapArr, setSnapArr] = useState<string[]>(['150px', '40%', '50%', '60%', '75%', '100%']);
  const [snap, setSnap] = useState<number | string>(snapArr[0]);
  const { referrals: referralsData } = useAppSelector(({ referrals }) => referrals);
  const referralLink = `${process.env.REACT_APP_BOT_URL}?startapp=${userData?.telegram_id}`;
  useEffect(() => {
    setOpenDrawer(true);
  }, []);
  const handeSendInvite = () => {
    const utils = initUtils();
    const shareText = 'Join me on RocketCrash and let\'s earn together! Use my invite link to join the fun.';
    utils.openTelegramLink(`https://t.me/share/url?url=${referralLink}&text=${shareText}`);
  };
  const handeCopyInvite = () => {
    navigator.clipboard.writeText(referralLink);
    setCopiedTooltip(true);
    setTimeout(() => {
      setCopiedTooltip(false);
    }, 2000);
  };
  useEffect(() => {
    if (inviteFriendsRef?.current) {
      const tempArr = [...snapArr];
      const calculatedOffset = `${tg.viewportStableHeight - inviteFriendsRef.current.offsetTop - 50}px`;
      tempArr[3] = calculatedOffset;
      setSnap(calculatedOffset);
      setSnapArr(tempArr);
    }
  }, [inviteFriendsRef?.current, tg.viewportStableHeight]);
  const handleBonusClick = () => {
    navigate(Pages.quests);
  };
  useEffect(() => {
    dispatch(getReferrals());
  }, []);
  const handlers = useSwipeable({
    onSwipeStart: () => {
      setOpenDrawer(true);
      setSnap(snapArr[0]);
    },
  });

  useEffect(() => {
    if (infoRef?.current && personRef.current) {
      const contentRect = infoRef.current.getClientRects()[0];
      const parent = personRef.current;

      const offset = 190;
      const topPosition = contentRect.top - offset;

      parent.style.backgroundPosition = `right 0 top ${topPosition}px`;
    }
  }, [personRef, infoRef]);
  return (
    <div className="absolute w-screen h-screen bg-friendsRacoonBg bg-no-repeat bg-racoonPosition bg-racoonSize top-0" {...handlers}>
      <div ref={personRef} className="h-full flex flex-col z-[2] px-3  bg-no-repeat ">
        <div className="flex flex-col items-start mt-14">
          <h3 className="text-lg">
            Invite friend and earn
          </h3>
          <h2 className="text-2xl flex items-center gap-1">
            <div className="mr-2">
              5,000
            </div>
            <Coin width={25} height={25} />
            for
          </h2>
          <h3 className="text-2xl">
            everyone
          </h3>
          <p className="text-gray-2 text-sm max-w-[46%] text-start mt-2 leading-[1.2]">
            Receive up to 20% from each invited user from each of your referrals
          </p>
        </div>
        <div ref={infoRef} className="bg-black-2 mt-12 w-full rounded-10 py-4 border-black-light-bg border-2 max-w-[400px] mx-auto">
          <div className="flex flex-col items-start justify-around gap-2 pl-4">
            <div className="flex text-start gap-4">
              <img
                className="w-[40px] h-auto object-contain"
                src={PilotsImg}
                alt="pilots"
              />
              <div className="ml-1">
                <div className="text-sm">
                  Refer your friends
                </div>
                <p className="text-gray-2 text-xs mt-1">
                  Earn 5000 coins each
                </p>
              </div>
            </div>
            <div className="flex text-start gap-2">
              <img
                className="w-[42px] h-auto object-contain"
                src={CoinImg}
                alt="coin"
              />

              <div className="ml-2">
                <div className="text-sm">
                  Bonus points
                </div>
                <p className="text-gray-2 text-xs mt-1">
                  {'Earn 20% of your friend\'s in-game earnings, up to a maximum of 10,000 coins per friend.'}
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-2 px-4 mt-4">
            <Button
              type="button"
              onClick={handeSendInvite}
              className="mt-2 px-6 bg-white text-black-text font-bold py-1 px-4 rounded-10 py-2 w-full flex items-center justify-center gap-2 focus:ring-4 transform active:scale-75 transition-transform"
              pureClass
            >
              Invite friends
              <LongArrow />
            </Button>
            <Button
              type="button"
              onClick={handeCopyInvite}
              className="mt-2  py-2 w-fit flex items-center justify-center gap-2 focus:ring-4 transform active:scale-75 transition-transform relative"
            >
              <Copy />
              <div
                role="tooltip"
                className={`absolute ${!copiedTooltip && 'invisible'} bottom-10 right-0 z-[6] w-[100px] px-3 py-2 text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700`}
              >
                <span id="default-tooltip-message">Copied</span>
              </div>
            </Button>
          </div>
        </div>
        <div ref={bonusRef} className="flex gap-2 px-4 mt-4 bg-blue-1 rounded-10 h-[60px] items-center cursor-pointer w-full max-w-[400px] mx-auto" onClick={handleBonusClick}>
          <img src={QuestTasksImg} alt="quests" className="max-h-[60px]" />
          <div className="flex items-center justify-between w-full">
            <div className="text-start">
              <div className="text-sm">
                Bonus points
              </div>
              <div className="text-xs">
                More income in our quests
              </div>
            </div>
            <Arrow className="scale-150" />
          </div>
        </div>
        <DraggableDrawer
          snap={snap}
          setSnap={setSnap}
          snapPoints={snapArr}
          open={openDrawer}
          dismissible={false}
          onOpenChange={() => {
            setOpenDrawer(false);
            setSnap(snapArr[1]);
          }}
        >
          <div>
            <div className="flex justify-between">
              <div className="text-lg">
                {referralsData?.referrals.length || 0}
                {' '}
                friends
              </div>
              <div className="text-lg flex items-center gap-2">
                {referralsData?.all_time_rewards || 0}
                <Coin />
              </div>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-2">
              {referralsData?.referrals.map((referral) => <FriendCard key={referral.avatar_url} referral={referral} />)}
            </div>
          </div>
        </DraggableDrawer>
      </div>
    </div>
  );
}

export default FriendsPage;
