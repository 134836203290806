import React from 'react';
import { ReactComponent as CircleCheck } from '../../assets/svg/circleCheck.svg';
import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg';
import { ReactComponent as Validating } from '../../assets/svg/validating.svg';
import Button, { ButtonProps } from '../Button';

export function ClaimedButton() {
  return (
    <Button
      className="w-full h-[36px] py-3 px-4 bg-green-text bg-opacity-15 rounded-10 text-green-text flex items-center justify-center gap-1 focus:outline-none transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
    >
      <CircleCheck stroke="#1FD969" />
      {' '}
      Claimed
    </Button>
  );
}

export function CompleteTheTaskButton({ onClick }: ButtonProps) {
  return (
    <Button
      className="w-full h-[36px] py-3 bg-green-main rounded-10 text-black-text flex items-center justify-center gap-1 disabled:bg-gray-10 disabled:text-gray-600 focus:outline-none transform active:scale-75 transition-transform"
      pureClass
      onClick={onClick}
    >
      Complete the task
      <Arrow stroke="#121214" />
    </Button>
  );
}

export function CheckButton({ checkDisable, onClick }: {checkDisable: boolean, onClick: () => void}) {
  return (
    <Button
      className={`w-full h-[36px] py-[9px] px-4 bg-white rounded-10 text-black-text flex items-center justify-center gap-1 focus:outline-none transform active:scale-75 transition-transform font-bold text-sm line-normal ${
        checkDisable ? 'bg-gray-10 text-gray-600 cursor-not-allowed' : 'bg-white text-black-text '
      }`}
      pureClass
      onClick={onClick}
    >
      Check
    </Button>
  );
}

export function ValidatingButton() {
  return (
    <Button
      className="w-full h-[36px] py-[9px] px-4 bg-[#FFA90826] bg-opacity-10 rounded-10 text-[#FFA909] flex items-center justify-center gap-1 font-bold text-sm line-normal focus:outline-none"
      pureClass
      disabled
    >
      <span className="animate-spin">
        <Validating />
      </span>
      {' '}
      Validating
    </Button>
  );
}
