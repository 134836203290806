import React from 'react';
import { ReactComponent as BronzeLeague } from '../../assets/leaderboard/Bronze.svg';
import { ReactComponent as SilverLeague } from '../../assets/leaderboard/Silver.svg';
import { ReactComponent as GoldLeague } from '../../assets/leaderboard/Gold.svg';
import { ReactComponent as PlatinumLeague } from '../../assets/leaderboard/Platinum.svg';
import { ReactComponent as DiamondLeague } from '../../assets/leaderboard/Diamond.svg';
import { ReactComponent as ChallengerLeague } from '../../assets/leaderboard/Challenger.svg';
import { ReactComponent as LegendaryLeague } from '../../assets/leaderboard/Legendary.svg';
import { ReactComponent as MasterLeague } from '../../assets/leaderboard/Master.svg';
import { LeaguesUnion } from '../../types';

function LeagueIcon({ league }: { league: LeaguesUnion }) {
  const getLeagueIcon = () => {
    switch (league) {
      case 'Bronze':
        return <BronzeLeague />;
      case 'Silver':
        return <SilverLeague />;
      case 'Gold':
        return <GoldLeague />;
      case 'Platinum':
        return <PlatinumLeague />;
      case 'Diamond':
        return <DiamondLeague />;
      case 'Challenger':
        return <ChallengerLeague />;
      case 'Legendary':
        return <LegendaryLeague />;
      case 'Master':
        return <MasterLeague />;
      default:
        return <BronzeLeague />;
    }
  };
  return (
    <div className="h-[inherit] w-[inherit]">
      {getLeagueIcon()}
    </div>
  );
}

export default LeagueIcon;
