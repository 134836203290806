import { ButtonHTMLAttributes } from 'react';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  pureClass?: boolean
}
function Button({
  pureClass, children, className, ...props
}: ButtonProps) {
  return (
    <button
      className={`${!pureClass && 'bg-white text-black-text font-bold py-1 px-4 rounded-10 disabled:bg-gray-10 disabled:text-gray-600 '
      + 'focus:ring-4 transform active:scale-75 transition-transform'
      + ''} ${className}`}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
