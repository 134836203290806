import Rocket from '../../../assets/store/rocket.svg';
import Coin from '../../../assets/svg/coin.svg';
import Character from '../../../assets/store/character.svg';
import { ShopItemCategoryT } from '../../../types';

type StoreItemBgImageProps = {
    categoryName: ShopItemCategoryT['name']
}
const StoreItemBgImage = ({ categoryName }: StoreItemBgImageProps): {bg: string, icon: string} => {
  switch (categoryName) {
    case 'Passive':
      return {
        bg: 'bg-rocketStarBg',
        icon: Rocket,
      };
    case 'Boosters':
      return {
        bg: 'bg-boosterStarBg',
        icon: Coin,
      };
    case 'Skins':
      return {
        bg: 'bg-rocketStarBg',
        icon: Character,
      };
    default:
      return {
        bg: 'bg-storeCoinStarBg',
        icon: Coin,
      };
  }
};

export default StoreItemBgImage;
