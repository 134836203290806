import React from 'react';
import CoinImg from '../../../assets/images/coin-size-42x42.svg';

type DailyCardProps = {
    handleDailyClick: () => void
    completedDailyQuests: number
    totalDailyQuests: number
    isTodayQuestCompleted: boolean
}
function DailyCard({
  handleDailyClick, totalDailyQuests, completedDailyQuests, isTodayQuestCompleted,
}: DailyCardProps) {
  return (
    <div className="bg-gradient-to-r from-red-2 to-red-3 rounded-10 w-full" onClick={handleDailyClick}>
      <div className="bg-yellowStar bg-yellowStarSize bg-no-repeat bg-yellowStarPosition  justify-between border-none flex gap-1 text-left  p-3 px-5">
        <div>
          <div className="text-sm">
            Daily quests
          </div>
          <div className="text-xs">
            {completedDailyQuests}
            {' '}
            /
            {' '}
            {totalDailyQuests}
            {isTodayQuestCompleted
            && (
            <span className="ml-2 text-[10px] text-green-text bg-green-text bg-opacity-15 p-[2px] px-1 rounded">
              Claimed
            </span>
            )}
          </div>
        </div>
        <div>
          <img
            className="m-auto max-h-[40px] drop-shadow-[0_0_12px_rgba(255,233,53,0.6)]"
            src={CoinImg}
            alt="coin"
          />
        </div>
      </div>
    </div>
  );
}

export default DailyCard;
