import { useEffect } from 'react';
import ProgressBar from '../../ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ReactComponent as Energy } from '../../../assets/images/energy-22x22.svg';
import { updateProfileEnergy } from '../../../store/reducers/profile/asyncActions';
import { useEnergyRecoveryTimer } from '../../../hooks/useEnergyRecoveryTimer';

function EnergyInfo() {
  const dispatch = useAppDispatch();
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const {
    shouldStartTimer,
  } = useEnergyRecoveryTimer();

  useEffect(() => {
    const energyFillInterval = setInterval(() => {
      if (userSettings?.energy_recovery_per_second) {
        dispatch(updateProfileEnergy({ energy: userSettings.energy_recovery_per_second, replace: false }));
      }
    }, 1000);
    return () => clearInterval(energyFillInterval);
  }, [userSettings?.energy_recovery_per_second]);
  return (
    <div className="flex items-center gap-1">
      <Energy />
      <div>
        <div className="flex text-[10px] gap-1">
          <div>
            {Math.floor(userData?.energy || 0)}
            <span className="text-gray-2 mx-1">/</span>
            {userSettings?.max_energy}
          </div>
          <div>
            {shouldStartTimer && <EnergyTimer />}
          </div>
        </div>
        <div className="w-fit m-auto">
          <ProgressBar
            size="small"
            goal={userSettings?.max_energy || 100}
            currentValue={userData?.energy || 0}
            elementWidth="13px"
            type="energy"
            hideLine
          />
        </div>
      </div>
    </div>
  );
}

export default EnergyInfo;

function EnergyTimer() {
  const { countdown } = useEnergyRecoveryTimer();
  return (
    <div className="bg-white bg-opacity-15 rounded-10 px-1 text-[10px] min-w-[32px]">
      {countdown.formatedMinutes}
      :
      {countdown.formatedSecond}
    </div>
  );
}
