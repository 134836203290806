import React, { useEffect } from 'react';
import { slotMachineGame } from '../../../Layouts/AppWrapper';

function SlotMachineGame() {
  useEffect(() => {
    setTimeout(() => {
      const canvas = slotMachineGame.canvas.getContext('2d');
      if (canvas) {
        canvas.imageSmoothingEnabled = true;
        canvas.imageSmoothingQuality = 'high';
      }
    }, 2000);
  }, []);
  return (
    <div
      id="slot-game"
      className="w-full h-full"
      style={{
        position: 'inherit',
        top: 'inherit',
        left: 'inherit',
      }}
    />
  );
}

export default SlotMachineGame;
