import {
  ReactNode, useEffect, useState,
} from 'react';
import Phaser from 'phaser';
import { useAppDispatch, useAppSelector } from '../../store';
import { getMe, updateTGProfile } from '../../store/reducers/profile/asyncActions';
import Loader from '../../Components/Loader';
import { getQuests } from '../../store/reducers/quests/asyncActions';
import { getReferrals } from '../../store/reducers/referrals/asyncActions';
import { getSettings, getVersion } from '../../store/reducers/settings/asyncActions';
import { authenticateUser, refreshAuthToken } from '../../store/reducers/auth/asyncActions';
import Modal from '../../Components/Modal';
import { getTopPlayers } from '../../store/reducers/leaderboard/asyncActions';
import { getAutopilot, getPurchases, getShopItems } from '../../store/reducers/store/asyncActions';
import { getActiveSlotEffects, getAviatorHistory } from '../../store/reducers/aviator/asyncActions';
import SlotMachineScene from '../../Components/SlotMachine/SlotMachineGame/SlotScene';
import eventEmitter, { GameEvents } from '../../pages/Home/eventEmiter';
import OverlayGame from './OverlayGame';
import { OverlayScene } from '../../Components/OverlayScene/OverlayScene';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version: appVersion } = require('../../../package.json');

interface AppWrapperProps {
    children: ReactNode
}

const tg = window?.Telegram.WebApp;

class MyGame extends Phaser.Game {
  // eslint-disable-next-line no-useless-constructor
  constructor(config: Phaser.Types.Core.GameConfig) {
    super(config);
  }

  updateParent(newParentId: string) {
    const { canvas } = this;

    if (canvas) {
      const newParent = document.getElementById(newParentId);
      if (newParent) {
        newParent.appendChild(canvas);
      }
    }
  }

  resizeToParent(width: number, height: number) {
    const { canvas } = this;
    if (canvas) {
      this.scale.resize(width, height);
    }
  }
}

const config = {
  plugins: {
    scene: [
      {
        key: 'SpinePlugin',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        plugin: window.SpinePlugin,
        sceneKey: 'spine',
      },
    ],
  },
};

// Initialize game before parent render
export const slotMachineGame = new MyGame({
  ...config,
  width: 335,
  height: 258,
  render: {
    pixelArt: false,
    antialias: true,
    roundPixels: true,
    powerPreference: 'high-performance',
  },
  type: Phaser.CANVAS,
  parent: null,
  pixelArt: false,
  roundPixels: true,
  transparent: true,
  antialias: true,
  antialiasGL: true,
  powerPreference: 'high-performance',
  scene: SlotMachineScene,
});
const overlayConfig = {
  type: Phaser.AUTO,
  width: '100%',
  height: '100%',
  render: {
    pixelArt: false,
    antialias: true,
    antialiasGL: true,
    mipmapFilter: 'LINEAR_MIPMAP_LINEAR',
  },
  plugins: {
    scene: [
      {
        key: 'SpinePlugin',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        plugin: window.SpinePlugin,
        sceneKey: 'spine',
      },
    ],
  },
  parent: 'overlay-game',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  transparent: true,
  fps: {
    target: 30,
    forceSetTimeOut: true,
  },
  scene: OverlayScene,
};

export const overlayGame = new MyGame(overlayConfig);
function AppWrapper({ children }: AppWrapperProps) {
  const dispatch = useAppDispatch();
  const {
    loading: profileLoading,
  } = useAppSelector(({ profile }) => profile);
  const {
    loading: questsLoading,
  } = useAppSelector(({ quests }) => quests);
  const {
    version: beVersion, loading: settingsLoading,
  } = useAppSelector(({ settings }) => settings);
  const { error: authError, loading: authLoading } = useAppSelector(({ auth }) => auth);

  const [fakeLoading, setFakeLoading] = useState<boolean>(true);
  const [gameLoading, setGameLoading] = useState<boolean>(true);
  const [overlayLoading, setOverlayLoading] = useState<boolean>(true);

  const handleAuth = async () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      await dispatch(refreshAuthToken());
    } else {
      await dispatch(authenticateUser(tg.initData));
    }
  };
  useEffect(() => {
    const handleLoadingComplete = () => {
      setGameLoading(false);
    };
    eventEmitter.on(GameEvents.SLOT_LOADED, handleLoadingComplete);
    return () => {
      eventEmitter.off(GameEvents.SLOT_LOADED, handleLoadingComplete);
    };
  }, []);
  useEffect(() => {
    const handleLoadingComplete = () => {
      setOverlayLoading(false);
    };
    eventEmitter.on(GameEvents.OVERLAY_LOADED, handleLoadingComplete);
    return () => {
      eventEmitter.off(GameEvents.OVERLAY_LOADED, handleLoadingComplete);
    };
  }, []);
  const initialLoadingData = async () => { // initial data loading to get all pages data on loading screen
    await Promise.all([
      dispatch(getMe()),
      dispatch(getVersion()),
      dispatch(getSettings()),
      dispatch(getQuests()),
      dispatch(getReferrals()),
      dispatch(getTopPlayers()),
      dispatch(getShopItems()),
      dispatch(getPurchases()),
      dispatch(getAutopilot()),
      dispatch(getAviatorHistory()),
      dispatch(getActiveSlotEffects()),
    ]);
  };

  useEffect(() => {
    (async () => {
      await handleAuth();
      await initialLoadingData();
    })();
  }, []);

  useEffect(() => {
    dispatch(updateTGProfile(tg.initDataUnsafe));
    window.console.log(tg, 'tg');
  }, [tg.initDataUnsafe]);

  useEffect(() => {
    if (beVersion) {
      window.console.log({
        BE_Version: beVersion,
        FE_Version: appVersion,
      }, 'app version');
    }
  }, [beVersion]);

  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false);
    }, 3000);
  }, []);

  const handleReload = () => {
    tg?.close();
  };

  if (authError) {
    return <Modal message={authError} onReload={handleReload} />;
  }

  if (profileLoading || settingsLoading || questsLoading || fakeLoading || authLoading || gameLoading || overlayLoading) {
    return <Loader />;
  }
  return (
    <div>
      <OverlayGame />
      {children}
    </div>
  );
}

export default AppWrapper;
