import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store';
import { ReactComponent as Coin } from '../../../assets/images/coin-size.svg';
import { ReactComponent as Energy } from '../../../assets/images/energy-22x22.svg';
import { ReactComponent as Revive } from '../../../assets/images/revive.svg';
import { ReactComponent as Booster } from '../../../assets/images/booster.svg';
import eventEmitter, { GameEvents } from '../../../pages/Home/eventEmiter';

function SpinResult() {
  const { slotMachineSpin } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const [gameEnded, setGameEnded] = useState<boolean>(false);

  useEffect(() => {
    const handleStopEvent = () => {
      setGameEnded(() => true);
    };
    eventEmitter.on(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    return () => {
      eventEmitter.off(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    };
  }, []);
  useEffect(() => {
    const handleStartEvent = () => {
      setGameEnded(() => false);
    };
    eventEmitter.on(GameEvents.SLOT_START_SPIN, handleStartEvent);
    return () => {
      eventEmitter.off(GameEvents.SLOT_START_SPIN, handleStartEvent);
    };
  }, []);
  const renderInfo = () => {
    const effect = slotMachineSpin?.effects[0];
    if (!effect || !gameEnded) {
      return {
        icon: null,
        label: null,
      };
    }
    switch (effect.name) {
      case 'bonus':
        return {
          icon: <Coin width="16px" height="16px" />,
          label: effect.amount,
        };
      case 'regenerate_energy':
        return {
          icon: <Energy width="16px" height="16px" />,
          label: effect.amount,
        };
      case 'revive':
        return {
          icon: <Revive width="16px" height="16px" />,
          label: 'revive',
        };
      case 'reward_multiplier':
        return {
          icon: <Booster width="16px" height="16px" />,
          label: 'booster',
        };
      case 'aviator':
        return {
          icon: <Booster width="16px" height="16px" />,
          label: 'aviator',
        };
      default:
        return {
          icon: null,
          label: null,
        };
    }
  };
  if (!gameEnded) {
    return null;
  }
  return (
    <div className="flex items-center justify-center text-xs gap-1">
      <span>
        {renderInfo().icon}
      </span>
      <span>
        {renderInfo().label}
      </span>
    </div>
  );
}

export default SpinResult;
