import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import PermanentStoreItem from '../../Components/PermanentStoreItem';
import {
  buyStoreItem, finishAutopilot, getAutopilot, getPurchases, getShopItems, selectSkin, startAutopilot,
} from '../../store/reducers/store/asyncActions';
import { ShopItemT } from '../../types';
import { updateProfileCoins } from '../../store/reducers/profile/asyncActions';
import StoreItem from '../../Components/ShopComponents/StoreItem';
import { ShopTabs } from '../../Components/ShopComponents/tabs';

function Store() {
  const dispatch = useAppDispatch();
  const { shopItems, purchases } = useAppSelector(
    ({ shop }) => shop,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const [activeTab, setActiveTab] = useState<typeof ShopTabs[number]>('permanent');
  const handleBuy = (item: ShopItemT) => {
    const nextLevel = [...item.levels || []].sort((level) => level.level).find((level) => !level.purchased_at);
    if (nextLevel) {
      if (userData?.coins) {
        dispatch(updateProfileCoins({ coins: -nextLevel.price, replace: false }));
      }
      dispatch(buyStoreItem({ id: item.id, level: nextLevel.level }));
    }
  };
  useEffect(() => {
    dispatch(getShopItems());
    dispatch(getPurchases());
  }, []);
  useEffect(() => {
    if (activeTab === 'permanent') {
      dispatch(getAutopilot());
    }
  }, [activeTab]);
  const handleStartFarm = () => {
    dispatch(startAutopilot());
  };
  const handleCollectFarm = () => {
    dispatch(finishAutopilot());
  };
  const handleSelectSkin = (item: ShopItemT) => {
    const boughtLevel = [...item.levels || []].sort((a, b) => b.level - a.level).find((level) => level.purchased_at);
    if (boughtLevel?.id) {
      dispatch(selectSkin({ skinLevelId: boughtLevel.id }));
    }
  };
  const activeItemIds = purchases?.filter((item) => item.is_active).map((item) => item.store_item_level?.id) || [];
  const activeTemporaryItems = purchases?.filter((el) => el.store_item_level.duration_seconds && el.expires_at && el.is_active) || [];
  return (
    <div className="px-2 mt-4">
      <div className="flex justify-between bg-black-light-bg rounded-10 p-1 gap-1 text-gray-300">
        {ShopTabs.map((tab) => (
          <div
            key={tab}
            className={`w-full ${activeTab === tab && 'bg-black-modal-bg text-white'} py-2 rounded-10 cursor-pointer`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </div>
        ))}
      </div>
      <div className="mt-4 text-start pb-[100px] gap-2 flex flex-col">
        {shopItems?.[activeTab].map((storeItem) => (
          <div key={storeItem.id}>
            {activeTab === 'permanent'
              ? <PermanentStoreItem item={storeItem} onBuy={handleBuy} onStartFarm={handleStartFarm} onCollectFarm={handleCollectFarm} />
              : <StoreItem item={storeItem} onBuy={handleBuy} onSelectSkin={handleSelectSkin} activeItemIds={activeItemIds} activeTemporaryItems={activeTemporaryItems} />}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Store;
