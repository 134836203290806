import { useEffect } from 'react';
import SlotMachineGame from '../SlotMachineGame';
import MachineScreenBgSVG from './MachineScreenBgSVG';
import SpinResult from './SpinResult';
import { slotMachineGame } from '../../../Layouts/AppWrapper';

type MachineProps = {
  onSpin: () => void
  isSpinning: boolean
}
function Machine({ onSpin, isSpinning }: MachineProps) {
  const resize = () => {
    const foreignMachine = document.getElementById('machine-foreign');
    const parentSvg = document.getElementById('machine-frame-svg');
    const screenSvg = document.getElementById('svg_63');
    const divInside = document.getElementById('machine-content');
    if (foreignMachine && parentSvg && screenSvg && divInside) {
      const actualWidth = parentSvg.clientWidth;
      const actualHeight = parentSvg.clientHeight;
      const viewBox = parentSvg.getAttribute('viewBox');
      const screenRect = screenSvg.getBoundingClientRect();
      if (viewBox) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = viewBox.split(' ').map(Number);

        const scaleX = actualWidth / viewBoxWidth;
        const scaleY = actualHeight / viewBoxHeight;
        slotMachineGame.resizeToParent((screenRect.width / scaleX) * 2, (screenRect.height / scaleY) * 2);

        foreignMachine.setAttribute('width', (screenRect.width / scaleX).toString());
        foreignMachine.setAttribute('height', (screenRect.height / scaleY).toString());
        // set position to div inside foreignObject
        divInside.style.width = `${screenRect.width / scaleX}px`;
        divInside.style.height = `${screenRect.height / scaleY}px`;
        divInside.style.position = 'fixed';
        divInside.style.top = '0';
        divInside.style.left = '0';
      }
    }
  };
  useEffect(() => {
    const parentId = 'slot-game'; // ID container
    slotMachineGame.updateParent(parentId);
    resize();
  }, []);
  const handleSpinClick = () => {
    onSpin();
  };
  return (
    <svg
      id="machine-frame-svg"
      className="w-full h-auto max-w-full"
      viewBox="0 0 374 486"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
    >
      <foreignObject x="0" y="-10" width="100%" height="100%">
        <MachineScreenBgSVG />
      </foreignObject>
      <foreignObject x="30" y="45" id="machine-foreign">
        <div
          id="machine-content"
          className="z-[2]"
        >
          <SlotMachineGame />
        </div>
      </foreignObject>
      <defs>
        <clipPath id="clip0_1415_146431">
          <rect width="374.267" height="380.134" fill="white" y="0.3208" x="0.46674" id="svg_13" />
        </clipPath>
        <radialGradient id="paint0_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.5403 319.421) rotate(-144.678) scale(111.474 38.0091)">
          <stop offset="0" stopColor="#EEEEEE" />
          <stop offset="0.3578" stopColor="#6AD1F7" />
          <stop offset="0.7914" stopColor="#5D2FB3" />
          <stop offset="1" stopColor="#06295E" />
        </radialGradient>
        <radialGradient id="paint1_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.3365 325.11) rotate(35.8847) scale(90.2716 27.4614)">
          <stop stopColor="#4937C2" />
          <stop offset="0.2189" stopColor="#402FA6" />
          <stop offset="0.5575" stopColor="#332380" />
          <stop offset="0.8299" stopColor="#2C1D69" />
          <stop offset="1" stopColor="#291A60" />
        </radialGradient>
        <linearGradient id="paint2_linear_1415_146431" x1="0.4373" y1="0.60374" x2="0.5404" y2="0.40363">
          <stop offset="0.275" stopColor="#8688F8" />
          <stop offset="0.3926" stopColor="#6864D6" />
          <stop offset="0.5522" stopColor="#4439AF" />
          <stop offset="0.6961" stopColor="#2A1A92" />
          <stop offset="0.8177" stopColor="#1B0780" />
          <stop offset="0.9025" stopColor="#15007A" />
        </linearGradient>
        <radialGradient id="paint3_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.2472 284.603) rotate(-144.51) scale(71.0439 20.1798)">
          <stop stopColor="#A8EEFF" />
          <stop offset="0.5093" stopColor="#B19BF1" />
          <stop offset="1" stopColor="#00277C" />
        </radialGradient>
        <linearGradient id="paint4_linear_1415_146431" x1="0.08951" y1="0.90725" x2="1.05538" y2="0.17618">
          <stop stopColor="#33057C" />
          <stop offset="0.147" stopColor="#291482" />
          <stop offset="0.4292" stopColor="#0D3B92" />
          <stop offset="0.4595" stopColor="#0A4094" />
          <stop offset="0.6135" stopColor="#0E67B7" />
          <stop offset="0.8199" stopColor="#15A2EA" />
          <stop offset="0.9255" stopColor="#B9D9EA" />
        </linearGradient>
        <radialGradient id="paint5_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(81.7197 39.3334) rotate(-27.8106) scale(92.8778 26.5746)">
          <stop stopColor="#C4EBFF" />
          <stop offset="0.1464" stopColor="#C1E9FE" stopOpacity="0.8923" />
          <stop offset="0.272" stopColor="#B6E4FC" stopOpacity="0.7999" />
          <stop offset="0.3896" stopColor="#A4DBF8" stopOpacity="0.7133" />
          <stop offset="0.4078" stopColor="#A1D9F7" stopOpacity="0.7" />
          <stop offset="0.7916" stopColor="#43B4EE" stopOpacity="0.2463" />
          <stop offset="1" stopColor="#15A2EA" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint6_linear_1415_146431" x1="0.4995" y1="1.00107" x2="0.4995" y2="-0.00062">
          <stop offset="0.1855" stopColor="#1B69F8" />
          <stop offset="0.8622" stopColor="#2E1877" />
          <stop offset="1" stopColor="#221157" />
        </linearGradient>
        <radialGradient id="paint7_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.4535 172.439) scale(45.7027 105.089)">
          <stop stopColor="#1E429C" />
          <stop offset="0.2515" stopColor="#223487" />
          <stop offset="0.7669" stopColor="#2B1152" />
          <stop offset="1" stopColor="#300038" />
        </radialGradient>
        <linearGradient id="paint8_linear_1415_146431" x1="0.49997" y1="1.00057" x2="0.49997" y2="0">
          <stop offset="0" stopColor="#FF0089" />
          <stop offset="0.1677" stopColor="#FF2093" />
          <stop offset="0.5339" stopColor="#FF71AB" />
          <stop offset="0.6946" stopColor="#FF97B6" />
          <stop offset="1" stopColor="#FFCBF3" />
        </linearGradient>
        <linearGradient id="paint9_linear_1415_146431" x1="0.50039" y1="0.99859" x2="0.50039" y2="-0.00706">
          <stop stopColor="#FDFB6A" />
          <stop offset="0.2044" stopColor="#FDFB6D" />
          <stop offset="0.3673" stopColor="#FDFB77" />
          <stop offset="0.5161" stopColor="#FDFC88" />
          <stop offset="0.6563" stopColor="#FEFCA0" />
          <stop offset="0.7905" stopColor="#FEFDBE" />
          <stop offset="0.9185" stopColor="#FFFEE3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint10_linear_1415_146431" x1="0.50039" y1="1.00035" x2="0.50039" y2="-0.00529">
          <stop stopColor="#009DC8" />
          <stop offset="0.1198" stopColor="#05A0C9" />
          <stop offset="0.2664" stopColor="#14A7CD" />
          <stop offset="0.427" stopColor="#2DB4D4" />
          <stop offset="0.5977" stopColor="#50C6DC" />
          <stop offset="0.7762" stopColor="#7CDDE8" />
          <stop offset="0.9588" stopColor="#B2F8F6" />
          <stop offset="1" stopColor="#BFFFF9" />
        </linearGradient>
        <radialGradient id="paint11_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.8654 302.223) rotate(35.6095) scale(89.9664 42.6243)">
          <stop stopColor="#42329C" />
          <stop offset="0.393" stopColor="#392A87" />
          <stop offset="1" stopColor="#291A60" />
        </radialGradient>
        <linearGradient id="paint12_linear_1415_146431" x1="-0.67933" y1="0.4987" x2="1.56656" y2="0.50285">
          <stop offset="0.01441" stopColor="#4F72BD" />
          <stop offset="0.4118" stopColor="#323B99" />
          <stop offset="0.7619" stopColor="#1C107E" />
          <stop offset="0.9404" stopColor="#130073" />
        </linearGradient>
        <linearGradient id="paint13_linear_1415_146431" x1="0.0558" y1="0.49896" x2="0.80995" y2="0.49982">
          <stop offset="0" stopColor="#130073" />
          <stop offset="0.04703" stopColor="#3E219D" />
          <stop offset="0.09836" stopColor="#6540C4" />
          <stop offset="0.1455" stopColor="#8257E1" />
          <stop offset="0.1864" stopColor="#9364F2" />
          <stop offset="0.2166" stopColor="#9969F8" />
          <stop offset="0.2309" stopColor="#9B70F8" />
          <stop offset="0.3211" stopColor="#A996F6" />
          <stop offset="0.3935" stopColor="#B2ADF5" />
          <stop offset="0.4385" stopColor="#B5B6F5" />
          <stop offset="0.5351" stopColor="#A4ABF5" />
          <stop offset="0.8463" stopColor="#728AF3" />
          <stop offset="1" stopColor="#5F7DF3" />
        </linearGradient>
        <linearGradient id="paint14_linear_1415_146431" x1="-0.23405" y1="0.8626" x2="0.79747" y2="0.05228">
          <stop offset="0" stopColor="#2E1877" />
          <stop offset="0.453" stopColor="#2B3282" />
          <stop offset="0.6313" stopColor="#2A3E87" />
          <stop offset="0.6849" stopColor="#38519B" />
          <stop offset="0.7944" stopColor="#5C82D0" />
          <stop offset="0.8712" stopColor="#77A8F8" />
          <stop offset="0.8975" stopColor="#7EADF8" />
          <stop offset="0.9369" stopColor="#93BAF8" />
          <stop offset="0.9843" stopColor="#B4CFF8" />
          <stop offset="1" stopColor="#C1D7F8" />
        </linearGradient>
        <radialGradient id="paint15_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.6219 96.9482) rotate(12.6302) scale(27.2171 51.8078)">
          <stop stopColor="#021277" />
          <stop offset="1" stopColor="#021277" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint16_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(94.9309 50.6024) rotate(-110.711) scale(25.3534 48.1205)">
          <stop stopColor="#9E00F8" stopOpacity="0.2" />
          <stop offset="1" stopColor="#9E00F8" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint17_linear_1415_146431" x1="0.00022" y1="0.49995" x2="1.00003" y2="0.49995">
          <stop stopColor="#66CBFF" />
          <stop offset="0.1235" stopColor="#6FCEFF" />
          <stop offset="0.3275" stopColor="#88D7FF" />
          <stop offset="0.5866" stopColor="#B1E5FF" />
          <stop offset="0.8878" stopColor="#E9F8FF" />
          <stop offset="0.9963" stopColor="white" />
        </linearGradient>
        <radialGradient id="paint18_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.0217 294.441) rotate(34.3945) scale(16.7387 7.48771)">
          <stop stopColor="#7BC1FC" />
          <stop offset="0.2405" stopColor="#78BDFA" />
          <stop offset="0.4763" stopColor="#6FB1F3" />
          <stop offset="0.7102" stopColor="#619EE8" />
          <stop offset="0.9417" stopColor="#4C82D9" />
          <stop offset="1" stopColor="#467AD4" />
        </radialGradient>
        <radialGradient id="paint19_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.6072 274.005) rotate(26.7897) scale(15.5068 6.56361)">
          <stop stopColor="#6B79DC" />
          <stop offset="0.1516" stopColor="#6A72D8" />
          <stop offset="0.3671" stopColor="#6660CE" />
          <stop offset="0.6198" stopColor="#6041BD" />
          <stop offset="0.7474" stopColor="#5D2FB3" />
          <stop offset="1" stopColor="#23087A" />
        </radialGradient>
        <linearGradient id="paint20_linear_1415_146431" x1="0.08337" y1="0.39653" x2="1.0269" y2="0.63957">
          <stop offset="0" stopColor="#ED00AE" />
          <stop offset="0.08112" stopColor="#F01BB5" />
          <stop offset="0.2477" stopColor="#F44CC2" />
          <stop offset="0.4128" stopColor="#F876CD" />
          <stop offset="0.5731" stopColor="#FB96D5" />
          <stop offset="0.7272" stopColor="#FDADDB" />
          <stop offset="0.8726" stopColor="#FFBADF" />
          <stop offset="1" stopColor="#FFBFBE" />
        </linearGradient>
        <linearGradient id="paint21_linear_1415_146431" x1="0.08939" y1="0.39226" x2="1.0254" y2="0.63764">
          <stop stopColor="#FFA100" />
          <stop offset="0.1452" stopColor="#FFAB15" />
          <stop offset="0.4463" stopColor="#FFC54C" />
          <stop offset="0.8741" stopColor="#FFEFA4" />
          <stop offset="1" stopColor="#FFFCBF" />
        </linearGradient>
        <linearGradient id="paint22_linear_1415_146431" x1="0.36811" y1="0.13273" x2="0.68374" y2="0.97905">
          <stop stopColor="#BFFFF9" />
          <stop offset="0.213" stopColor="#91F6ED" />
          <stop offset="0.5939" stopColor="#43E6D8" />
          <stop offset="0.8657" stopColor="#13DDCB" />
          <stop offset="1" stopColor="#00D9C6" />
        </linearGradient>
        <linearGradient id="paint23_linear_1415_146431" x1="0.30757" y1="0.1331" x2="0.75623" y2="0.9415">
          <stop stopColor="#3BDBFF" />
          <stop offset="0.1931" stopColor="#35D2FF" />
          <stop offset="0.5076" stopColor="#23BAFF" />
          <stop offset="0.9025" stopColor="#0792FF" />
          <stop offset="0.9963" stopColor="#0088FF" />
        </linearGradient>
        <radialGradient id="paint24_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.774 323.637) rotate(23.1654) scale(50.8355 26.787)">
          <stop stopColor="#0095D1" />
          <stop offset="0.4693" stopColor="#1E429C" />
          <stop offset="0.56" stopColor="#203C93" />
          <stop offset="0.7112" stopColor="#242B79" />
          <stop offset="0.903" stopColor="#2C1050" />
          <stop offset="1" stopColor="#300038" />
        </radialGradient>
        <radialGradient id="paint25_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(78.5504 318.104) rotate(5.51332) scale(65.1819 30.7772)">
          <stop stopColor="#D3F6FF" />
          <stop offset="0.06486" stopColor="#C5ECFE" />
          <stop offset="0.2281" stopColor="#A6D7FD" />
          <stop offset="0.3194" stopColor="#9ACFFC" />
          <stop offset="0.3997" stopColor="#96C2F9" />
          <stop offset="0.5486" stopColor="#8C9FF1" />
          <stop offset="0.6017" stopColor="#8891EE" />
          <stop offset="1" stopColor="#003375" />
        </radialGradient>
        <linearGradient id="paint26_linear_1415_146431" x1="0.49377" y1="1.42797" x2="0.50435" y2="-0.19012">
          <stop offset="0.1819" stopColor="#7594E6" />
          <stop offset="0.3015" stopColor="#6C85DB" />
          <stop offset="0.5298" stopColor="#535FC0" />
          <stop offset="0.8406" stopColor="#2B2293" />
          <stop offset="1" stopColor="#15007A" />
        </linearGradient>
        <radialGradient id="paint27_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(84.3791 283.588) rotate(-0.665014) scale(69.6244 13.6157)">
          <stop offset="0.2384" stopColor="#CFDCFF" />
          <stop offset="0.3192" stopColor="#C5D5FC" />
          <stop offset="0.4582" stopColor="#A9C1F4" />
          <stop offset="0.6372" stopColor="#7DA2E7" />
          <stop offset="0.784" stopColor="#5385DB" />
          <stop offset="1" stopColor="#4148AD" />
        </radialGradient>
        <radialGradient id="paint28_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.634 304.633) rotate(-0.38539) scale(20.9301 4.48724)">
          <stop offset="0.05063" stopColor="#0A4094" />
          <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
          <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint29_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(106.945 323.454) rotate(95.6552) scale(20.9663 4.47838)">
          <stop offset="0.05063" stopColor="#0A4094" />
          <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
          <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint30_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(95.5498 334.772) rotate(97.9313) scale(20.9651 4.47907)">
          <stop offset="0.1814" stopColor="#002F78" />
          <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint31_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.376 313.391) rotate(-0.327308) scale(29.5729 5.3843)">
          <stop offset="0.05063" stopColor="#0A4094" />
          <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
          <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint32_linear_1415_146431" x1="0.5021" y1="1.00052" x2="0.5021" y2="0.94614">
          <stop offset="0.1198" stopColor="#000067" stopOpacity="0.6" />
          <stop offset="0.3961" stopColor="#06126A" stopOpacity="0.4117" />
          <stop offset="0.7219" stopColor="#0B206D" stopOpacity="0.1896" />
          <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint33_linear_1415_146431" x1="0.5021" y1="1.00052" x2="0.5021" y2="0.4366">
          <stop stopColor="#000067" stopOpacity="0.6" />
          <stop offset="0.06499" stopColor="#010368" stopOpacity="0.561" />
          <stop offset="0.6457" stopColor="#0A1C6C" stopOpacity="0.2126" />
          <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint34_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(351.242 324.711) rotate(-35.1037) scale(86.5267 33.1891)">
          <stop stopColor="#1E429C" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <radialGradient id="paint35_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(320.215 307.991) rotate(144.67) scale(93.5635 43.8437)">
          <stop stopColor="#E6E4EE" />
          <stop offset="0.1457" stopColor="#28F7F7" />
          <stop offset="0.5139" stopColor="#15A2EA" />
          <stop offset="0.6289" stopColor="#117AC7" />
          <stop offset="0.7335" stopColor="#0D59AA" />
          <stop offset="0.7371" stopColor="#0D57A9" />
          <stop offset="0.8565" stopColor="#062891" />
          <stop offset="0.9479" stopColor="#020B82" />
          <stop offset="1" stopColor="#00007C" />
        </radialGradient>
        <linearGradient id="paint36_linear_1415_146431" x1="0.54866" y1="0.60231" x2="0.43974" y2="0.40614">
          <stop offset="0.275" stopColor="#6CB1FF" />
          <stop offset="0.3676" stopColor="#5890DC" />
          <stop offset="0.5277" stopColor="#3A5DA5" />
          <stop offset="0.6767" stopColor="#223579" />
          <stop offset="0.8097" stopColor="#10185A" />
          <stop offset="0.922" stopColor="#060647" />
          <stop offset="1" stopColor="#020040" />
        </linearGradient>
        <linearGradient id="paint37_linear_1415_146431" x1="-0.00021" y1="0.49967" x2="0.99956" y2="0.49967">
          <stop stopColor="#3C67ED" />
          <stop offset="0.2955" stopColor="#7E99F5" />
          <stop offset="0.6397" stopColor="#CFD6FF" />
          <stop offset="0.6759" stopColor="#CBD2FF" />
          <stop offset="0.7117" stopColor="#BFC7FE" />
          <stop offset="0.7473" stopColor="#ABB5FD" />
          <stop offset="0.7828" stopColor="#909BFB" />
          <stop offset="0.8179" stopColor="#6C7AF8" />
          <stop offset="0.8207" stopColor="#6977F8" />
          <stop offset="0.9851" stopColor="#130073" />
        </linearGradient>
        <linearGradient id="paint38_linear_1415_146431" x1="0.91091" y1="0.90725" x2="-0.05495" y2="0.17618">
          <stop stopColor="#33057C" />
          <stop offset="0.147" stopColor="#291482" />
          <stop offset="0.4292" stopColor="#0D3B92" />
          <stop offset="0.4595" stopColor="#0A4094" />
          <stop offset="0.6135" stopColor="#0E67B7" />
          <stop offset="0.8199" stopColor="#15A2EA" />
          <stop offset="0.9255" stopColor="#B9D9EA" />
        </linearGradient>
        <radialGradient id="paint39_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(297.163 35.3496) rotate(-145.218) scale(68.0714 23.6497)">
          <stop offset="0" stopColor="#F7F7F7" stopOpacity="0.9" />
          <stop offset="1" stopColor="#EEC8F7" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint40_linear_1415_146431" x1="0.50891" y1="0.99961" x2="0.48932" y2="-0.00035">
          <stop offset="0.275" stopColor="#0379FF" />
          <stop offset="0.3676" stopColor="#0363DC" />
          <stop offset="0.5277" stopColor="#0340A5" />
          <stop offset="0.6767" stopColor="#022479" />
          <stop offset="0.8097" stopColor="#02105A" />
          <stop offset="0.922" stopColor="#020447" />
          <stop offset="1" stopColor="#020040" />
        </linearGradient>
        <radialGradient id="paint41_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(354.641 170.366) scale(51.9403 105.42)">
          <stop stopColor="#1E429C" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <linearGradient id="paint42_linear_1415_146431" x1="0.50021" y1="1.00062" x2="0.50021" y2="-0.00503">
          <stop stopColor="#FD3390" />
          <stop offset="0.1181" stopColor="#FD3C96" />
          <stop offset="0.315" stopColor="#FD56A8" />
          <stop offset="0.5664" stopColor="#FE80C4" />
          <stop offset="0.8595" stopColor="#FFB9EB" />
          <stop offset="1" stopColor="#FFD7FF" />
        </linearGradient>
        <linearGradient id="paint43_linear_1415_146431" x1="-0.00062" y1="0.49819" x2="1.00114" y2="0.49819">
          <stop stopColor="#FDFB6A" />
          <stop offset="0.2044" stopColor="#FDFB6D" />
          <stop offset="0.3673" stopColor="#FDFB77" />
          <stop offset="0.5161" stopColor="#FDFC88" />
          <stop offset="0.6563" stopColor="#FEFCA0" />
          <stop offset="0.7905" stopColor="#FEFDBE" />
          <stop offset="0.9185" stopColor="#FFFEE3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint44_linear_1415_146431" x1="0.50021" y1="1.00071" x2="0.50021" y2="-0.00494">
          <stop stopColor="#00D9C6" />
          <stop offset="0.1298" stopColor="#24E0D0" />
          <stop offset="0.3499" stopColor="#5BEBDE" />
          <stop offset="0.5546" stopColor="#86F4EA" />
          <stop offset="0.7376" stopColor="#A5FAF2" />
          <stop offset="0.8923" stopColor="#B8FEF7" />
          <stop offset="1" stopColor="#BFFFF9" />
        </linearGradient>
        <radialGradient id="paint45_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(313.284 281.034) rotate(143.697) scale(68.9908 28.6169)">
          <stop stopColor="#A1EFFF" />
          <stop offset="0.2272" stopColor="#4EA8EE" />
          <stop offset="0.6417" stopColor="#0A4094" />
          <stop offset="0.9244" stopColor="#00007C" />
        </radialGradient>
        <radialGradient id="paint46_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(321.735 308.412) rotate(-35.0497) scale(114.493 57.4569)">
          <stop stopColor="#1E429C" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <linearGradient id="paint47_linear_1415_146431" x1="-0.32173" y1="0.50157" x2="1.43177" y2="0.49939">
          <stop offset="0.05959" stopColor="#130073" />
          <stop offset="0.273" stopColor="#171D81" />
          <stop offset="0.7325" stopColor="#1F66A3" />
          <stop offset="1" stopColor="#2593B8" />
        </linearGradient>
        <linearGradient id="paint48_linear_1415_146431" x1="1.29299" y1="0.81435" x2="0.07279" y2="0.14232">
          <stop offset="0" stopColor="#210C63" />
          <stop offset="0.1568" stopColor="#201066" />
          <stop offset="0.3124" stopColor="#1B1C70" />
          <stop offset="0.4677" stopColor="#153081" />
          <stop offset="0.6217" stopColor="#0B4C99" />
          <stop offset="0.7591" stopColor="#006BB3" />
          <stop offset="0.7995" stopColor="#0D73B7" />
          <stop offset="0.8746" stopColor="#308AC2" />
          <stop offset="0.9755" stopColor="#68AED4" />
          <stop offset="1" stopColor="#77B7D9" />
        </linearGradient>
        <radialGradient id="paint49_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(324.561 82.5819) rotate(141.113) scale(25.7534 70.3222)">
          <stop stopColor="#021277" />
          <stop offset="0.7854" stopColor="#021277" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint50_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(337.314 234.209) rotate(178.82) scale(45.2457 114.567)">
          <stop stopColor="#FF9EEC" stopOpacity="0.3" />
          <stop offset="0.7542" stopColor="#FF9EEC" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint51_linear_1415_146431" x1="0.99907" y1="0.49935" x2="-0.00049" y2="0.49935">
          <stop stopColor="#66CBFF" stopOpacity="0" />
          <stop offset="0.2322" stopColor="#85D6FF" stopOpacity="0.2331" />
          <stop offset="0.7374" stopColor="#D4F0FF" stopOpacity="0.7402" />
          <stop offset="0.9963" stopColor="white" />
        </linearGradient>
        <radialGradient id="paint52_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(317.51 294.395) rotate(-6.64173) scale(8.90798 5.64536)">
          <stop stopColor="#128DDC" />
          <stop offset="0.335" stopColor="#128BDB" />
          <stop offset="0.4958" stopColor="#1183D5" />
          <stop offset="0.6194" stopColor="#0F76CD" />
          <stop offset="0.7241" stopColor="#0D64C0" />
          <stop offset="0.8167" stopColor="#0A4CB0" />
          <stop offset="0.9009" stopColor="#062E9C" />
          <stop offset="0.9771" stopColor="#020C84" />
          <stop offset="1" stopColor="#00007C" />
        </radialGradient>
        <radialGradient id="paint53_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(334.661 281.97) rotate(-9.99082) scale(8.80099 5.61103)">
          <stop stopColor="#128DDC" />
          <stop offset="0.335" stopColor="#128BDB" />
          <stop offset="0.4958" stopColor="#1183D5" />
          <stop offset="0.6194" stopColor="#0F76CD" />
          <stop offset="0.7241" stopColor="#0D64C0" />
          <stop offset="0.8167" stopColor="#0A4CB0" />
          <stop offset="0.9009" stopColor="#062E9C" />
          <stop offset="0.9771" stopColor="#020C84" />
          <stop offset="1" stopColor="#00007C" />
        </radialGradient>
        <radialGradient id="paint54_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(351.869 268.88) rotate(-9.30962) scale(8.69999 5.57524)">
          <stop stopColor="#128DDC" />
          <stop offset="0.335" stopColor="#128BDB" />
          <stop offset="0.4958" stopColor="#1183D5" />
          <stop offset="0.6194" stopColor="#0F76CD" />
          <stop offset="0.7241" stopColor="#0D64C0" />
          <stop offset="0.8167" stopColor="#0A4CB0" />
          <stop offset="0.9009" stopColor="#062E9C" />
          <stop offset="0.9771" stopColor="#020C84" />
          <stop offset="1" stopColor="#00007C" />
        </radialGradient>
        <radialGradient id="paint55_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(315.447 293.041) scale(8.49774 5.11725)">
          <stop stopColor="#BFFFF9" />
          <stop offset="0.09361" stopColor="#B7FBF8" />
          <stop offset="0.2353" stopColor="#A3F0F4" />
          <stop offset="0.4074" stopColor="#80DDEF" />
          <stop offset="0.6034" stopColor="#51C4E7" />
          <stop offset="0.8167" stopColor="#15A3DD" />
          <stop offset="0.886" stopColor="#0098D9" />
          <stop offset="1" stopColor="#005CA8" />
        </radialGradient>
        <radialGradient id="paint56_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(333.151 280.84) rotate(-6.64092) scale(8.15585 5.169)">
          <stop offset="0" stopColor="#FFBFDD" />
          <stop offset="0.1284" stopColor="#FFBBDB" />
          <stop offset="0.255" stopColor="#FFAFD6" />
          <stop offset="0.3811" stopColor="#FF9BCE" />
          <stop offset="0.5068" stopColor="#FF80C2" />
          <stop offset="0.6323" stopColor="#FF5CB3" />
          <stop offset="0.7559" stopColor="#FF30A1" />
          <stop offset="0.8734" stopColor="#FF008C" />
          <stop offset="1" stopColor="#A400AD" />
        </radialGradient>
        <radialGradient id="paint57_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(350.342 267.847) rotate(-6.64092) scale(8.15585 5.169)">
          <stop stopColor="#FFFCBF" />
          <stop offset="0.1278" stopColor="#FFFABB" />
          <stop offset="0.254" stopColor="#FFF4AF" />
          <stop offset="0.3795" stopColor="#FFEB9B" />
          <stop offset="0.5047" stopColor="#FFDE80" />
          <stop offset="0.6296" stopColor="#FFCD5C" />
          <stop offset="0.7527" stopColor="#FFB830" />
          <stop offset="0.8696" stopColor="#FFA100" />
          <stop offset="1" stopColor="#AD6800" />
        </radialGradient>
        <linearGradient id="paint58_linear_1415_146431" x1="0.50019" y1="0.99979" x2="0.50019" y2="-0.00006">
          <stop offset="0" stopColor="#006E8C" />
          <stop offset="1" stopColor="#006E8C" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint59_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.037 301.752) rotate(69.5752) scale(3.83202 5.14587)">
          <stop stopColor="#FCFEFF" />
          <stop offset="0.5536" stopColor="#5D96D4" />
          <stop offset="0.6858" stopColor="#437CC0" />
          <stop offset="1" stopColor="#0A4094" />
        </radialGradient>
        <radialGradient id="paint60_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(112.475 343.959) scale(3.64522 3.65157)">
          <stop stopColor="#5D96D4" />
          <stop offset="0.174" stopColor="#538BCA" />
          <stop offset="0.4813" stopColor="#386DAF" />
          <stop offset="0.8831" stopColor="#0E3D83" />
          <stop offset="1" stopColor="#002E75" />
        </radialGradient>
        <radialGradient id="paint61_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(126.772 310.432) scale(4.03387 4.05461)">
          <stop stopColor="#FCFEFF" />
          <stop offset="0.5536" stopColor="#5D96D4" />
          <stop offset="0.6858" stopColor="#437CC0" />
          <stop offset="1" stopColor="#0A4094" />
        </radialGradient>
        <radialGradient id="paint62_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(158.473 362.898) scale(4.23469 2.73062)">
          <stop stopColor="#5D96D4" />
          <stop offset="0.174" stopColor="#538BCA" />
          <stop offset="0.4813" stopColor="#386DAF" />
          <stop offset="0.8831" stopColor="#0E3D83" />
          <stop offset="1" stopColor="#002E75" />
        </radialGradient>
        <radialGradient id="paint63_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(293.947 360.481) scale(241.069 32.9149)">
          <stop stopColor="#66ABFF" />
          <stop offset="1" stopColor="#162E6F" />
        </radialGradient>
        <linearGradient id="paint64_linear_1415_146431" x1="-0.00075" y1="0.49977" x2="0.99976" y2="0.49977">
          <stop offset="0" stopColor="#4F6AD6" />
          <stop offset="0.3007" stopColor="#5684E1" />
          <stop offset="0.9419" stopColor="#69C8FC" />
          <stop offset="1" stopColor="#6BCEFF" />
        </linearGradient>
        <linearGradient id="paint65_linear_1415_146431" x1="0.77869" y1="0.13158" x2="0.38794" y2="0.86784">
          <stop stopColor="#CFEFFF" />
          <stop offset="1" stopColor="#3C88FC" />
        </linearGradient>
        <linearGradient id="paint66_linear_1415_146431" x1="0.00186" y1="0.49665" x2="1.00161" y2="0.49665">
          <stop offset="0.1819" stopColor="#7594E6" />
          <stop offset="0.3015" stopColor="#6C85DB" />
          <stop offset="0.5298" stopColor="#535FC0" />
          <stop offset="0.8406" stopColor="#2B2293" />
          <stop offset="1" stopColor="#15007A" />
        </linearGradient>
        <linearGradient id="paint67_linear_1415_146431" x1="0.09694" y1="0.22629" x2="0.91382" y2="0.74118">
          <stop offset="0.01441" stopColor="#FFC4FE" />
          <stop offset="0.1324" stopColor="#F3A7F1" />
          <stop offset="0.4419" stopColor="#D460D3" />
          <stop offset="0.6998" stopColor="#BE2CBC" />
          <stop offset="0.8932" stopColor="#B00CAE" />
          <stop offset="1" stopColor="#AB00A9" />
        </linearGradient>
        <linearGradient id="paint68_linear_1415_146431" x1="0.21561" y1="0.16398" x2="0.79915" y2="0.86618">
          <stop stopColor="#FED2FD" />
          <stop offset="0.1403" stopColor="#FAC8FD" />
          <stop offset="0.3761" stopColor="#EEAEFC" />
          <stop offset="0.6786" stopColor="#DC84FA" />
          <stop offset="1" stopColor="#C54FF8" />
        </linearGradient>
        <linearGradient id="paint69_linear_1415_146431" x1="0.00028" y1="0.50035" x2="0.99978" y2="0.50035">
          <stop offset="0" stopColor="#002673" />
          <stop offset="0.04235" stopColor="#104288" />
          <stop offset="0.1333" stopColor="#3079B2" />
          <stop offset="0.2178" stopColor="#48A5D3" />
          <stop offset="0.2933" stopColor="#5AC4EB" />
          <stop offset="0.357" stopColor="#65D7FA" />
          <stop offset="0.4013" stopColor="#69DEFF" />
          <stop offset="0.5893" stopColor="#8CE5FF" />
          <stop offset="0.8627" stopColor="#BAEFFF" />
          <stop offset="1" stopColor="#CCF3FF" />
        </linearGradient>
        <linearGradient id="paint70_linear_1415_146431" x1="0.27904" y1="0.20113" x2="0.87433" y2="0.99945">
          <stop stopColor="#CCF3FF" />
          <stop offset="0.1232" stopColor="#B0EDFF" />
          <stop offset="0.3864" stopColor="#69DEFF" />
          <stop offset="0.4693" stopColor="#64D8F9" />
          <stop offset="0.5824" stopColor="#57C7E7" />
          <stop offset="0.713" stopColor="#42AACB" />
          <stop offset="0.8565" stopColor="#2483A3" />
          <stop offset="1" stopColor="#005473" />
        </linearGradient>
        <linearGradient id="paint71_linear_1415_146431" x1="0.06512" y1="0.25915" x2="0.93695" y2="0.74064">
          <stop stopColor="#CCF3FF" />
          <stop offset="0.1232" stopColor="#B0EDFF" />
          <stop offset="0.3864" stopColor="#69DEFF" />
          <stop offset="0.4653" stopColor="#65D8FB" />
          <stop offset="0.5731" stopColor="#59C6EF" />
          <stop offset="0.6976" stopColor="#44AADD" />
          <stop offset="0.8345" stopColor="#2981C2" />
          <stop offset="0.9801" stopColor="#054FA1" />
          <stop offset="1" stopColor="#00479C" />
        </linearGradient>
        <radialGradient id="paint72_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(284.086 315.685) scale(4.25115 19.6205)">
          <stop stopColor="#AE5AE6" />
          <stop offset="0.1811" stopColor="#944FCC" />
          <stop offset="0.57" stopColor="#513288" />
          <stop offset="1" stopColor="#000F36" />
        </radialGradient>
        <radialGradient id="paint73_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.654 315.673) scale(3.98514 16.9799)">
          <stop offset="0" stopColor="#00A4DB" />
          <stop offset="0.1147" stopColor="#029ED7" />
          <stop offset="0.2633" stopColor="#078ECD" />
          <stop offset="0.4304" stopColor="#0F73BC" />
          <stop offset="0.61" stopColor="#1A4FA4" />
          <stop offset="0.6637" stopColor="#1E429C" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <linearGradient id="paint74_linear_1415_146431" x1="-0.09025" y1="0.30802" x2="1.09427" y2="0.69919">
          <stop offset="0.01441" stopColor="#FFC4FE" />
          <stop offset="0.04985" stopColor="#FFBEFE" />
          <stop offset="0.7069" stopColor="#FA56F7" />
          <stop offset="1" stopColor="#F82DF5" />
        </linearGradient>
        <linearGradient id="paint75_linear_1415_146431" x1="-0.10148" y1="0.34883" x2="1.0838" y2="0.65071">
          <stop offset="0.01441" stopColor="#D6FDFF" />
          <stop offset="0.1408" stopColor="#C2FBFE" />
          <stop offset="0.4009" stopColor="#8DF7FD" />
          <stop offset="0.7685" stopColor="#38F0FA" />
          <stop offset="1" stopColor="#00ECF8" />
        </linearGradient>
        <radialGradient id="paint76_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(274.141 286.538) scale(63.7565 10.6403)">
          <stop offset="0.01441" stopColor="#D1E9F3" />
          <stop offset="0.4998" stopColor="#69CAF8" />
          <stop offset="1" stopColor="#27277D" />
        </radialGradient>
        <radialGradient id="paint77_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(186.927 328.905) rotate(-0.656864) scale(92.4846 33.7895)">
          <stop offset="0.05063" stopColor="#0A4094" />
          <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint78_linear_1415_146431" x1="0.50028" y1="1.13937" x2="0.49888" y2="-0.11433">
          <stop offset="0" stopColor="#69AAFF" />
          <stop offset="0.06479" stopColor="#7BB5FF" />
          <stop offset="0.2341" stopColor="#A3CDFF" />
          <stop offset="0.3999" stopColor="#C4E1FF" />
          <stop offset="0.5594" stopColor="#DAEFFF" />
          <stop offset="0.7095" stopColor="#E8F7FF" />
          <stop offset="0.8408" stopColor="#EDFAFF" />
          <stop offset="0.8506" stopColor="#E8F9FF" />
          <stop offset="0.8906" stopColor="#D8F6FF" />
          <stop offset="0.9364" stopColor="#CFF4FF" />
          <stop offset="1" stopColor="#CCF3FF" />
        </linearGradient>
        <linearGradient id="paint79_linear_1415_146431" x1="0.50069" y1="1.09135" x2="0.49278" y2="-0.08856">
          <stop offset="0" stopColor="#2E1877" />
          <stop offset="0.06145" stopColor="#30207C" />
          <stop offset="0.1544" stopColor="#37348C" />
          <stop offset="0.2672" stopColor="#4356A4" />
          <stop offset="0.3956" stopColor="#5286C7" />
          <stop offset="0.5355" stopColor="#66C2F2" />
          <stop offset="0.5538" stopColor="#69CAF8" />
          <stop offset="0.9856" stopColor="white" />
        </linearGradient>
        <radialGradient id="paint80_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(149.248 303.017) scale(14.8375 4.01607)">
          <stop stopColor="#BA87FF" />
          <stop offset="0.1721" stopColor="#AB78EF" />
          <stop offset="0.5094" stopColor="#8451C5" />
          <stop offset="0.9753" stopColor="#451281" />
          <stop offset="1" stopColor="#420F7D" />
        </radialGradient>
        <linearGradient id="paint81_linear_1415_146431" x1="0.50049" y1="1.00097" x2="0.50049" y2="-0.00737">
          <stop offset="0" stopColor="#67A3FC" />
          <stop offset="0.1558" stopColor="#6CA7FC" />
          <stop offset="0.3518" stopColor="#7CB1FD" />
          <stop offset="0.5694" stopColor="#96C2FD" />
          <stop offset="0.8008" stopColor="#B9DAFE" />
          <stop offset="0.9856" stopColor="#DBF1FF" />
        </linearGradient>
        <radialGradient id="paint82_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(188.084 303.041) scale(16.5995 4.22859)">
          <stop offset="0" stopColor="#00A4DB" />
          <stop offset="0.1147" stopColor="#029ED7" />
          <stop offset="0.2633" stopColor="#078ECD" />
          <stop offset="0.4304" stopColor="#0F73BC" />
          <stop offset="0.61" stopColor="#1A4FA4" />
          <stop offset="0.6637" stopColor="#1E429C" />
          <stop offset="1" stopColor="#001038" />
        </radialGradient>
        <radialGradient id="paint83_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(226.92 303.047) scale(14.676 3.80623)">
          <stop stopColor="#BA87FF" />
          <stop offset="0.1721" stopColor="#AB78EF" />
          <stop offset="0.5094" stopColor="#8451C5" />
          <stop offset="0.9753" stopColor="#451281" />
          <stop offset="1" stopColor="#420F7D" />
        </radialGradient>
        <linearGradient id="paint84_linear_1415_146431" x1="0.49954" y1="1.00097" x2="0.49954" y2="-0.00737">
          <stop offset="0" stopColor="#67A3FC" />
          <stop offset="0.1558" stopColor="#6CA7FC" />
          <stop offset="0.3518" stopColor="#7CB1FD" />
          <stop offset="0.5694" stopColor="#96C2FD" />
          <stop offset="0.8008" stopColor="#B9DAFE" />
          <stop offset="0.9856" stopColor="#DBF1FF" />
        </linearGradient>
        <radialGradient id="paint85_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(188.889 303.586) rotate(31.2831) scale(4.93828 4.65753)">
          <stop offset="0.4126" stopColor="#001959" />
          <stop offset="0.8768" stopColor="#001959" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint86_linear_1415_146431" x1="0.09487" y1="0.20593" x2="0.90266" y2="0.79089">
          <stop offset="0.01441" stopColor="white" />
          <stop offset="0.1866" stopColor="#EBF2FE" />
          <stop offset="0.5409" stopColor="#B6D2FC" />
          <stop offset="1" stopColor="#69A2F8" />
        </linearGradient>
        <radialGradient id="paint87_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(180.695 266.842) scale(23.7967 4.54118)">
          <stop stopColor="#BEE0EE" />
          <stop offset="0.1263" stopColor="#B4D7E8" />
          <stop offset="0.3432" stopColor="#98BEDA" />
          <stop offset="0.6247" stopColor="#6B95C2" />
          <stop offset="0.9565" stopColor="#2E5EA1" />
          <stop offset="1" stopColor="#25569C" />
        </radialGradient>
        <radialGradient id="paint88_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.928 294.669) rotate(1.89587) scale(45.2919 9.80445)">
          <stop stopColor="#0A4094" />
          <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint89_linear_1415_146431" x1="0.00084" y1="0.50004" x2="0.99949" y2="0.50004">
          <stop stopColor="#66CBFF" />
          <stop offset="0.0938" stopColor="#77D1FF" />
          <stop offset="0.5283" stopColor="#C0E9FF" />
          <stop offset="0.8401" stopColor="#EDF9FF" />
          <stop offset="0.9963" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint90_linear_1415_146431" x1="0.53154" y1="0.21757" x2="0.45133" y2="0.94198">
          <stop stopColor="#66CBFF" />
          <stop offset="0.0938" stopColor="#77D1FF" />
          <stop offset="0.5283" stopColor="#C0E9FF" />
          <stop offset="0.8401" stopColor="#EDF9FF" />
          <stop offset="0.9963" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint91_linear_1415_146431" x1="0.08572" y1="1.47934" x2="0.86567" y2="-0.13518">
          <stop stopColor="#6BB4F3" />
          <stop offset="1" stopColor="#00356E" />
        </linearGradient>
        <linearGradient id="paint92_linear_1415_146431" x1="0.26422" y1="-0.36033" x2="0.70987" y2="1.75113">
          <stop offset="0.01441" stopColor="white" />
          <stop offset="0.2348" stopColor="#FCFDFF" />
          <stop offset="0.3949" stopColor="#F3F7FE" />
          <stop offset="0.5359" stopColor="#E3EEFE" />
          <stop offset="0.6658" stopColor="#CDE0FD" />
          <stop offset="0.7883" stopColor="#B0CEFB" />
          <stop offset="0.9034" stopColor="#8DB8FA" />
          <stop offset="1" stopColor="#69A2F8" />
        </linearGradient>
        <linearGradient id="paint93_linear_1415_146431" x1="0.52334" y1="1.13023" x2="0.49601" y2="-0.18311">
          <stop stopColor="#66CBFF" />
          <stop offset="0.2751" stopColor="#92D4F9" />
          <stop offset="0.6" stopColor="#BFDCF3" />
          <stop offset="0.8512" stopColor="#DBE2EF" />
          <stop offset="0.9963" stopColor="#E6E4EE" />
        </linearGradient>
        <linearGradient id="paint94_linear_1415_146431" x1="0.53421" y1="0.93526" x2="0.49585" y2="-0.10308">
          <stop stopColor="#66CBFF" />
          <stop offset="0.1422" stopColor="#8AD7FF" />
          <stop offset="0.3306" stopColor="#B4E6FF" />
          <stop offset="0.5146" stopColor="#D5F1FF" />
          <stop offset="0.6906" stopColor="#ECF9FF" />
          <stop offset="0.855" stopColor="#FAFDFF" />
          <stop offset="0.9963" stopColor="white" />
        </linearGradient>
        <radialGradient id="paint95_radial_1415_146431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(82.8979 293.69) rotate(-0.665007) scale(31.716 6.20231)">
          <stop stopColor="#CFDCFF" />
          <stop offset="0.1292" stopColor="#C9D6FB" />
          <stop offset="0.309" stopColor="#B8C4F1" />
          <stop offset="0.5185" stopColor="#9BA6E1" />
          <stop offset="0.7504" stopColor="#747DCA" />
          <stop offset="0.9982" stopColor="#4148AD" />
          <stop offset="1" stopColor="#4148AD" />
        </radialGradient>
        <linearGradient id="paint96_linear_1415_146431" x1="0.49146" y1="-0.01841" x2="0.54168" y2="1.54196">
          <stop offset="0" stopColor="#2E1877" />
          <stop offset="0.2066" stopColor="#4153A2" />
          <stop offset="0.5239" stopColor="#5EA8DF" />
          <stop offset="0.6685" stopColor="#69CAF8" />
          <stop offset="0.7443" stopColor="#7ED1F8" />
          <stop offset="0.8995" stopColor="#B2E2F8" />
          <stop offset="1" stopColor="#D8EEF8" />
        </linearGradient>
        <linearGradient id="paint97_linear_1415_146431" x1="0.50543" y1="1.63184" x2="0.49742" y2="-0.71456">
          <stop stopColor="#B6D7F3" />
          <stop offset="0.2304" stopColor="#6BB4F3" />
          <stop offset="1" stopColor="#00356E" />
        </linearGradient>
        <linearGradient id="paint98_linear_1415_146431" x1="0.50025" y1="1.00323" x2="0.50025" y2="0.00753">
          <stop offset="0" stopColor="#FF63B6" />
          <stop offset="0.1947" stopColor="#FF5FB4" />
          <stop offset="0.3982" stopColor="#FF52AE" />
          <stop offset="0.6059" stopColor="#FF3DA5" />
          <stop offset="0.815" stopColor="#FF2098" />
          <stop offset="1" stopColor="#FF0089" />
        </linearGradient>
        <linearGradient id="paint99_linear_1415_146431" x1="0.49986" y1="1.0038" x2="0.49986" y2="0.0095">
          <stop offset="0.00372" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id="paint100_linear_1415_146431" x1="0.50094" y1="1.00323" x2="0.50094" y2="0.00753">
          <stop stopColor="#FEFFEB" />
          <stop offset="0.3836" stopColor="#E7F148" />
          <stop offset="0.7478" stopColor="#F6CF1B" />
          <stop offset="1" stopColor="#FFBB00" />
        </linearGradient>
        <linearGradient id="paint101_linear_1415_146431" x1="0.49971" y1="1.00323" x2="0.49971" y2="0.00753">
          <stop stopColor="#00FFEA" />
          <stop offset="0.1191" stopColor="#00F2E6" />
          <stop offset="0.3374" stopColor="#00D1DA" />
          <stop offset="0.6307" stopColor="#009BC8" />
          <stop offset="0.9844" stopColor="#0051AE" />
          <stop offset="1" stopColor="#004EAD" />
        </linearGradient>
        <linearGradient id="paint102_linear_1415_146431" x1="0.49986" y1="1.0038" x2="0.49986" y2="0.0095">
          <stop offset="0.00372" stopColor="white" stopOpacity="0" />
          <stop offset="0.9963" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
        <linearGradient id="paint103_linear_1415_146431" x1="0.49978" y1="1.0038" x2="0.49978" y2="0.0095">
          <stop offset="0.00372" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id="paint104_linear_1415_146431" x1="-0.00005" y1="0.49677" x2="0.99988" y2="0.49677">
          <stop offset="0.00372" stopColor="#E6E4EE" />
          <stop offset="0.21" stopColor="#E3E3EE" />
          <stop offset="0.3889" stopColor="#D8DFF0" />
          <stop offset="0.5576" stopColor="#C6D8F3" />
          <stop offset="0.72" stopColor="#ADCFF6" />
          <stop offset="0.8766" stopColor="#8DC3FB" />
          <stop offset="1" stopColor="#6EB7FF" />
        </linearGradient>
        <linearGradient id="paint105_linear_1415_146431" x1="0.51198" y1="1.36939" x2="0.46727" y2="-0.26858">
          <stop offset="0" stopColor="#CEDFF8" />
          <stop offset="0.1885" stopColor="#E3EDFB" />
          <stop offset="0.4034" stopColor="#F3F7FD" />
          <stop offset="0.6483" stopColor="#FCFDFF" />
          <stop offset="0.9856" stopColor="white" />
        </linearGradient>
        <linearGradient id="svg_3" x1="1.00394" y1="-0.75975" x2="0.01856" y2="1.89893">
          <stop stopColor="#001764" />
          <stop offset="0.4525" stopColor="#404A9F" stopOpacity="0.7315" />
          <stop offset="1" stopColor="#A69CFF" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient id="svg_2" x1="0.51209" y1="1.36875" x2="0.46738" y2="-0.26919">
          <stop offset="0" stopColor="#CEDFF8" />
          <stop offset="0.1885" stopColor="#E3EDFB" />
          <stop offset="0.4034" stopColor="#F3F7FD" />
          <stop offset="0.6483" stopColor="#FCFDFF" />
          <stop offset="0.9856" stopColor="white" />
        </linearGradient>
        <linearGradient id="svg_8" x1="0.29156" y1="1.75478" x2="0.74357" y2="-0.36117">
          <stop offset="0" stopColor="#41DEFF" stopOpacity="0.3" />
          <stop offset="0.09363" stopColor="#37BDE8" stopOpacity="0.3655" />
          <stop offset="0.2719" stopColor="#2784C0" stopOpacity="0.4903" />
          <stop offset="0.4449" stopColor="#19559F" stopOpacity="0.6114" />
          <stop offset="0.6082" stopColor="#0E3086" stopOpacity="0.7258" />
          <stop offset="0.7597" stopColor="#061673" stopOpacity="0.8318" />
          <stop offset="0.8948" stopColor="#020668" stopOpacity="0.9263" />
          <stop offset="1" stopColor="#000064" />
        </linearGradient>
        <linearGradient id="svg_7" x1="0.35772" y1="1.72673" x2="0.69671" y2="-0.41642">
          <stop stopColor="#41DEFF" stopOpacity="0.2" />
          <stop offset="1" stopColor="#000064" />
        </linearGradient>
        <linearGradient id="svg_6" x1="0.26458" y1="-0.36354" x2="0.71439" y2="1.74158">
          <stop offset="0.01441" stopColor="white" />
          <stop offset="0.2348" stopColor="#FCFDFF" />
          <stop offset="0.3949" stopColor="#F3F7FE" />
          <stop offset="0.5359" stopColor="#E3EEFE" />
          <stop offset="0.6658" stopColor="#CDE0FD" />
          <stop offset="0.7883" stopColor="#B0CEFB" />
          <stop offset="0.9034" stopColor="#8DB8FA" />
          <stop offset="1" stopColor="#69A2F8" />
        </linearGradient>
        <linearGradient id="svg_11" x1="0.59278" y1="1.34739" x2="0.46184" y2="-0.55183">
          <stop stopColor="#000064" />
          <stop offset="0.1033" stopColor="#040E6E" stopOpacity="0.9277" />
          <stop offset="0.2992" stopColor="#0F3388" stopOpacity="0.7906" />
          <stop offset="0.567" stopColor="#216FB2" stopOpacity="0.6031" />
          <stop offset="0.8931" stopColor="#39C2EB" stopOpacity="0.3748" />
          <stop offset="1" stopColor="#41DEFF" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient id="svg_10" x1="0.00002" y1="0.50024" x2="1.00009" y2="0.50024">
          <stop offset="0" stopColor="#A7BEE8" />
          <stop offset="0.2669" stopColor="#BACCED" />
          <stop offset="0.8064" stopColor="#ECF1FA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient y2="0" x2="0.49998" y1="1.00027" x1="0.49998" id="svg_188">
          <stop stopColor="#12E7FA" offset="0" />
          <stop stopColor="#0CC1FA" offset="0.09059" />
          <stop stopColor="#07A2F9" offset="0.1766" />
          <stop stopColor="#0597F9" offset="0.225" />
          <stop stopColor="#058CEF" offset="0.3219" />
          <stop stopColor="#046ED2" offset="0.4937" />
          <stop stopColor="#033EA5" offset="0.7183" />
          <stop stopColor="#020D78" offset="0.9181" />
        </linearGradient>
        <linearGradient y2="0.49995" x2="1.00003" y1="0.49995" x1="-0.00003" id="svg_187">
          <stop stopColor="#D4FFFF" offset="0" />
          <stop stopColor="#B6FAFC" offset="0.04513" />
          <stop stopColor="#86F1F8" offset="0.1272" />
          <stop stopColor="#67ECF5" offset="0.1897" />
          <stop stopColor="#5CEAF4" offset="0.225" />
          <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.3154" />
          <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.4757" />
          <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.687" />
          <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9386" />
          <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
        </linearGradient>
        <linearGradient y2="0.43375" x2="7.46259" y1="0.56578" x1="-6.44461" id="svg_186">
          <stop stopColor="#D4FFFF" offset="0" />
          <stop stopColor="#B6FAFC" offset="0.04513" />
          <stop stopColor="#86F1F8" offset="0.1272" />
          <stop stopColor="#67ECF5" offset="0.1897" />
          <stop stopColor="#5CEAF4" offset="0.225" />
          <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.3154" />
          <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.4757" />
          <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.687" />
          <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9386" />
          <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
        </linearGradient>
        <linearGradient y2="0.49995" x2="0.99968" y1="0.49995" x1="-0.00038" id="svg_185">
          <stop stopOpacity="0" stopColor="#1D3CDD" offset="0" />
          <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.06138" />
          <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.313" />
          <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.5243" />
          <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.6846" />
          <stop stopColor="#5CEAF4" offset="0.775" />
          <stop stopColor="#67ECF5" offset="0.8103" />
          <stop stopColor="#86F1F8" offset="0.8728" />
          <stop stopColor="#B6FAFC" offset="0.9549" />
          <stop stopColor="#D4FFFF" offset="1" />
        </linearGradient>
        <linearGradient y2="0.49972" x2="0.99968" y1="0.49972" x1="-0.00006" id="svg_184">
          <stop stopOpacity="0" stopColor="#1D3CDD" offset="0" />
          <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.06137" />
          <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.313" />
          <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.5243" />
          <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.6846" />
          <stop stopColor="#5CEAF4" offset="0.775" />
          <stop stopColor="#67ECF5" offset="0.8103" />
          <stop stopColor="#86F1F8" offset="0.8728" />
          <stop stopColor="#B6FAFC" offset="0.9549" />
          <stop stopColor="#D4FFFF" offset="1" />
        </linearGradient>
        <linearGradient y2="0.49663" x2="0.99822" y1="0.49663" x1="-0.00102" id="svg_182">
          <stop stopOpacity="0" stopColor="#1D3CDD" offset="0" />
          <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.07919" />
          <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.4039" />
          <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.6765" />
          <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.8833" />
          <stop stopColor="#5CEAF4" offset="1" />
        </linearGradient>
        <linearGradient y2="0.50234" x2="0.99887" y1="0.50234" x1="-0.0024" id="svg_181">
          <stop stopColor="#5CEAF4" />
          <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.1167" />
          <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.3235" />
          <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.5961" />
          <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9208" />
          <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
        </linearGradient>
        <linearGradient y2="0.50001" x2="1.00001" y1="0.50001" x1="0.00002" id="svg_180">
          <stop stopColor="#D4FFFF" offset="0" />
          <stop stopColor="#B6FAFC" offset="0.04513" />
          <stop stopColor="#86F1F8" offset="0.1272" />
          <stop stopColor="#67ECF5" offset="0.1897" />
          <stop stopColor="#5CEAF4" offset="0.225" />
          <stop stopOpacity="0.8833" stopColor="#58DFF3" offset="0.3154" />
          <stop stopOpacity="0.6765" stopColor="#4DC1EF" offset="0.4757" />
          <stop stopOpacity="0.4039" stopColor="#3B90E8" offset="0.687" />
          <stop stopOpacity="0.07919" stopColor="#234DDF" offset="0.9386" />
          <stop stopOpacity="0" stopColor="#1D3CDD" offset="1" />
        </linearGradient>
        <linearGradient y2="0.50235" x2="1" y1="0.50235" x1="0" id="svg_179">
          <stop stopColor="#0597F9" />
          <stop stopColor="#08A9F9" offset="0.02648" />
          <stop stopColor="#0CC4FA" offset="0.07698" />
          <stop stopColor="#0FD7FA" offset="0.1281" />
          <stop stopColor="#11E3FA" offset="0.1798" />
          <stop stopColor="#12E7FA" offset="0.2328" />
          <stop stopColor="#15E7FA" offset="0.2354" />
          <stop stopColor="#4EEEFC" offset="0.296" />
          <stop stopColor="#7DF4FD" offset="0.3546" />
          <stop stopColor="#A2F9FE" offset="0.41" />
          <stop stopColor="#BCFCFE" offset="0.4614" />
          <stop stopColor="#CCFEFF" offset="0.5072" />
          <stop stopColor="#D2FFFF" offset="0.5428" />
          <stop stopColor="#CEFEFF" offset="0.5825" />
          <stop stopColor="#C1FDFF" offset="0.6249" />
          <stop stopColor="#ABFAFE" offset="0.6685" />
          <stop stopColor="#8EF6FD" offset="0.7131" />
          <stop stopColor="#67F2FC" offset="0.7583" />
          <stop stopColor="#39ECFB" offset="0.8034" />
          <stop stopColor="#12E7FA" offset="0.8361" />
          <stop stopColor="#11DFFA" offset="0.8681" />
          <stop stopColor="#0DC9FA" offset="0.918" />
          <stop stopColor="#07A5F9" offset="0.9792" />
          <stop stopColor="#0597F9" offset="1" />
        </linearGradient>
        <linearGradient y2="0.47947" x2="1.00099" y1="0.5216" x1="-0.00016" id="svg_196">
          <stop stopColor="#D4FFFF" offset="0" />
          <stop stopColor="#B6FAFC" offset="0.04513" />
          <stop stopColor="#86F1F8" offset="0.1272" />
          <stop stopColor="#67ECF5" offset="0.1897" />
          <stop stopColor="#5CEAF4" offset="0.225" />
          <stop stopColor="#46CEEF" offset="0.4413" />
          <stop stopColor="#0D85E3" offset="0.9071" />
          <stop stopColor="#0176E0" offset="1" />
        </linearGradient>
        <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_195">
          <stop stopColor="#30C7EF" />
          <stop stopColor="#01B0EF" offset="0.9963" />
        </linearGradient>
        <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_193">
          <stop stopColor="#1042AF" />
          <stop stopColor="#003593" offset="1" />
        </linearGradient>
        <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_192">
          <stop stopColor="#019CC9" offset="0" />
          <stop stopColor="#103199" offset="0.9963" />
        </linearGradient>
        <linearGradient y2="1.14649" x2="0.45832" y1="-0.13067" x1="0.53234" id="svg_191">
          <stop stopOpacity="0" stopColor="#66CBFF" offset="0.5475" />
          <stop stopOpacity="0.124" stopColor="#6FCEFF" offset="0.6031" />
          <stop stopOpacity="0.3287" stopColor="#88D7FF" offset="0.695" />
          <stop stopOpacity="0.5888" stopColor="#B1E5FF" offset="0.8117" />
          <stop stopOpacity="0.8911" stopColor="#E9F8FF" offset="0.9474" />
          <stop stopColor="white" offset="0.9963" />
        </linearGradient>
        <linearGradient y2="-0.04954" x2="0.94397" y1="1.04816" x1="0.07202" id="svg_204">
          <stop stopColor="white" />
          <stop stopColor="#FFF6FC" offset="0.01685" />
          <stop stopColor="#FDBAE9" offset="0.1319" />
          <stop stopColor="#FC88D9" offset="0.2427" />
          <stop stopColor="#FB62CD" offset="0.3465" />
          <stop stopColor="#FB46C4" offset="0.4416" />
          <stop stopColor="#FA35BF" offset="0.5249" />
          <stop stopColor="#FA2FBD" offset="0.5875" />
          <stop stopColor="#F82FBE" offset="0.5941" />
          <stop stopColor="#6342FF" offset="1" />
        </linearGradient>
        <linearGradient y2="0.50036" x2="0.99966" y1="0.50036" x1="-0.0002" id="svg_203">
          <stop stopColor="#FF99FD" offset="0.00372" />
          <stop stopColor="#FA93FD" offset="0.1649" />
          <stop stopColor="#ED82FD" offset="0.3798" />
          <stop stopColor="#D866FE" offset="0.6251" />
          <stop stopColor="#BB40FF" offset="0.8909" />
          <stop stopColor="#AD2EFF" offset="1" />
        </linearGradient>
        <linearGradient y2="0.50036" x2="0.99966" y1="0.50036" x1="-0.00066" id="svg_202">
          <stop stopColor="#3605A3" />
          <stop stopColor="#55036D" offset="1" />
        </linearGradient>
        <linearGradient y2="0.49992" x2="0.99984" y1="0.49992" x1="-0.00005" id="svg_201">
          <stop stopColor="#9507E0" offset="0" />
          <stop stopColor="#54049D" offset="0.5317" />
          <stop stopColor="#3606D4" offset="1" />
        </linearGradient>
        <linearGradient y2="0.49967" x2="0.99994" y1="0.49967" x1="-0.00003" id="svg_200">
          <stop stopColor="#8C06BD" offset="0" />
          <stop stopColor="#6204A4" offset="0.3181" />
          <stop stopColor="#120073" offset="0.8659" />
        </linearGradient>
        <linearGradient y2="0.04449" x2="0.98784" y1="0.87452" x1="0.00618" id="svg_199">
          <stop stopColor="white" offset="0.0838" />
          <stop stopColor="#FEF3FF" offset="0.1649" />
          <stop stopColor="#FCD2FF" offset="0.313" />
          <stop stopColor="#F89CFF" offset="0.5115" />
          <stop stopColor="#F353FF" offset="0.7532" />
          <stop stopColor="#ED00FF" offset="1" />
        </linearGradient>
        <linearGradient y2="0.50033" x2="1.00011" y1="0.50033" x1="-0.00006" id="svg_198">
          <stop stopColor="white" offset="0.0838" />
          <stop stopColor="#FEF3FF" offset="0.1649" />
          <stop stopColor="#FCD2FF" offset="0.313" />
          <stop stopColor="#F89CFF" offset="0.5115" />
          <stop stopColor="#F353FF" offset="0.7532" />
          <stop stopColor="#ED00FF" offset="1" />
        </linearGradient>
        <linearGradient y2="1.38758" x2="0.64104" y1="-0.39465" x1="0.37844" id="svg_248">
          <stop stopColor="#FC7DD6" />
          <stop stopColor="#FB61CD" offset="0.1077" />
          <stop stopColor="#FB46C4" offset="0.2407" />
          <stop stopColor="#FA35BF" offset="0.3579" />
          <stop stopColor="#FA2FBD" offset="0.446" />
          <stop stopColor="#EB31C4" offset="0.527" />
          <stop stopColor="#C336D5" offset="0.683" />
          <stop stopColor="#843EF0" offset="0.8964" />
          <stop stopColor="#6342FF" offset="1" />
        </linearGradient>
        <linearGradient y2="0.5" x2="1.00015" y1="0.5" x1="0.00015" id="svg_247">
          <stop stopColor="#FC7DD6" />
          <stop stopColor="#FB61CD" offset="0.1077" />
          <stop stopColor="#FB46C4" offset="0.2407" />
          <stop stopColor="#FA35BF" offset="0.3579" />
          <stop stopColor="#FA2FBD" offset="0.446" />
          <stop stopColor="#EB31C4" offset="0.527" />
          <stop stopColor="#C336D5" offset="0.683" />
          <stop stopColor="#843EF0" offset="0.8964" />
          <stop stopColor="#6342FF" offset="1" />
        </linearGradient>
        <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_246">
          <stop stopColor="#30C7EF" />
          <stop stopColor="#01B0EF" offset="0.9963" />
        </linearGradient>
        <linearGradient y2="0.5" x2="1" y1="0.5" x1="0" id="svg_245">
          <stop stopColor="#019CC9" offset="0" />
          <stop stopColor="#103199" offset="0.9963" />
        </linearGradient>
        <linearGradient y2="0.13759" x2="0.98659" y1="1.02349" x1="0.00827" id="svg_244">
          <stop stopColor="#8C06BD" offset="0" />
          <stop stopColor="#7C05B3" offset="0.1832" />
          <stop stopColor="#510399" offset="0.5439" />
          <stop stopColor="#120073" offset="1" />
        </linearGradient>
        <linearGradient y2="0.77544" x2="0.98461" y1="0.1061" x1="0.0063" id="svg_243">
          <stop stopColor="#8C06BD" offset="0" />
          <stop stopColor="#7C05B3" offset="0.1832" />
          <stop stopColor="#510399" offset="0.5439" />
          <stop stopColor="#120073" offset="1" />
        </linearGradient>
      </defs>
      <g>
        <title>Layer 1</title>
        <g id="svg_194">
          <g clipPath="url(#clip0_1415_146431)" id="svg_14">
            <mask id="mask0_1415_146431" maskUnits="userSpaceOnUse" x="0" y="0" width="375" height="381">
              <path d="m374.733,0.31934l-374.26645,0l0,380.13366l374.26645,0l0,-380.13366z" fill="white" id="svg_15" />
            </mask>
            <g mask="url(#mask0_1415_146431)" id="svg_16">
              <path d="m17.8954,221.677l69.6021,72.671l9.2236,0.397l0.1698,32.367l-11.091,-0.283l-9.3935,0l-104.686,-79.474l46.175,-25.678z" fill="url(#paint0_radial_1415_146431)" id="svg_17" />
              <path d="m-24.9976,259.486l0,30.384l90.709,65.528l53.3046,0l-3.168,-18.536l-28.5203,0.17l-112.3253,-77.546z" fill="url(#paint1_radial_1415_146431)" id="svg_18" />
              <path d="m17.6125,250.303l64.2263,48.41l3.282,-5.329l-62.9248,-46.142l-4.5835,3.061z" fill="url(#paint2_linear_1415_146431)" id="svg_19" />
              <path d="m21.6868,247.525l17.1458,-8.389l51.8903,41.607l-9.0539,13.491l-59.9822,-46.709z" fill="url(#paint3_radial_1415_146431)" id="svg_20" />
              <path d="m91.6849,33.1405c0,0 -29.5385,12.5276 -47.7595,32.0274c-18.221,19.4999 -11.5437,56.7991 -11.5437,56.7991l-44.2511,1.7l-15.9575,-14.511c0,0 -3.5084,-7.426 15.7877,-26.7557c19.29622,-19.3298 35.1972,-31.4038 53.2484,-43.4778c20.5411,-13.7179 30.1609,-19.84 67.1688,-28.4562l0.905,14.058l-17.5981,8.6162z" fill="url(#paint4_linear_1415_146431)" id="svg_21" />
              <path d="m91.6849,33.1406c0,0 -29.5385,12.5275 -47.7595,32.0273c-18.221,19.4999 -20.7674,27.8894 -20.7674,27.8894l-21.50309,17.4587l-11.3174,-13.3207c0,0 -1.24491,-17.1757 11.65692,-28.0594c15.39167,-12.981 21.16357,-18.1394 39.21477,-30.2134c20.5411,-13.7179 30.1609,-19.84 67.1688,-28.4562l0.905,14.058l-17.5981,8.6163z" fill="url(#paint5_radial_1415_146431)" id="svg_22" />
              <path d="m-30.0338,91.0168c0,0 3.5084,-3.5145 12.1662,-4.4215c10.18566,-1.077 16.91952,-1.1904 27.72764,0.6236c10.41206,1.7572 13.80726,4.2514 13.80726,4.2514l0,9.5227c0,0 -10.9213,-1.7568 -20.25818,-2.0969c-9.39345,-0.3401 -16.86292,0.2267 -20.88062,1.8139c-4.0177,1.587 -3.2255,2.834 -3.2255,2.834c0,0 -3.0557,-0.963 -5.6021,-4.6479c-2.6596,-3.5712 -3.7347,-7.8793 -3.7347,-7.8793z" fill="url(#paint6_linear_1415_146431)" id="svg_23" />
              <path d="m-21.8853,246.505l0,-146.475c0,0 1.5279,-4.6485 20.54114,-4.1383c16.24046,0.3968 27.78426,2.4941 27.78426,2.4941l-3.2821,148.2892l-6.3377,4.365c0,0 -5.4324,0.964 -14.939,0.964c-6.90362,0 -11.7701,-0.964 -16.5234,-2.381c-3.8479,-1.19 -7.2432,-3.118 -7.2432,-3.118z" fill="url(#paint7_radial_1415_146431)" id="svg_24" />
              <path d="m5.89907,212.72l-6.56409,0c-0.84881,0 -1.52785,-0.68 -1.52785,-1.531l0,-19.726c0,-0.851 0.67904,-1.531 1.52785,-1.531l6.56409,0c0.84881,0 1.52785,0.68 1.52785,1.531l0,19.726c0,0.851 -0.67904,1.531 -1.52785,1.531z" fill="url(#paint8_linear_1415_146431)" id="svg_25" />
              <path d="m6.35165,227.345l-7.46949,0c-0.62246,0 -1.07515,-0.51 -1.07515,-1.077l0,-9.183c0,-0.623 0.50928,-1.077 1.07515,-1.077l7.46949,0c0.62246,0 1.07515,0.51 1.07515,1.077l0,9.183c0,0.567 -0.45269,1.077 -1.07515,1.077z" fill="url(#paint9_linear_1415_146431)" id="svg_26" />
              <path d="m6.35171,145.322l-7.46949,0c-0.62246,0 -1.07515,-0.51 -1.07515,-1.077l0,-9.183c0,-0.624 0.50928,-1.077 1.07515,-1.077l7.46949,0c0.62246,0 1.07515,0.51 1.07515,1.077l0,9.183c0,0.567 -0.45269,1.077 -1.07515,1.077z" fill="url(#paint10_linear_1415_146431)" id="svg_27" />
              <path d="m-28.2796,247.355l102.1962,74.996l54.4364,0l-1.414,18.252l-45.7227,0l-109.4959,-77.092l0,-16.156z" fill="url(#paint11_radial_1415_146431)" id="svg_28" />
              <path d="m17.6125,97.082l-0.3396,153.618l4.6968,-3.344l0.7356,-149.6504l-5.0928,-0.6236z" fill="url(#paint12_linear_1415_146431)" id="svg_29" />
              <path d="m122.072,33.2542c0.057,0.1133 -2.659,1.0203 -2.829,1.077c-1.585,0.4535 -3.169,1.077 -4.697,1.6439c-3.225,1.1904 -6.451,2.4375 -9.62,3.7412c-6.3941,2.6076 -12.6752,5.4418 -18.7866,8.6162c-1.8108,0.9637 -3.6216,1.9273 -5.4324,2.9477c-11.0345,6.2354 -21.3899,13.8313 -30.1609,23.0143c-5.0928,5.2718 -10.7515,13.718 -11.3174,24.885c-0.4527,9.4095 -0.3961,140.0135 -0.3961,140.0135l-4.1874,2.211l-3.9611,-3.061l0,-144.0951c0,-9.3531 4.0177,-17.4025 7.4695,-21.994c5.5455,-7.5392 12.1662,-12.9244 20.0318,-18.5362c7.4695,-5.3285 16.58,-10.6002 24.7285,-14.8517c10.8647,-5.6685 32.7071,-13.3211 32.7071,-13.3211l6.451,7.7093z" fill="url(#paint13_linear_1415_146431)" id="svg_30" />
              <path d="m119.413,30.1931c-17.656,5.7252 -34.6883,13.6045 -50.4195,23.4678c-7.0734,4.3648 -13.9204,9.1831 -19.7489,15.0784c-7.4695,7.5392 -13.1848,16.6656 -13.977,27.3225c-0.5659,7.5392 -0.679,15.1352 -0.7922,22.7312c-0.1698,10.6 0.1698,121.023 0.2263,122.554l-12.732,6.009l0,-153.3349c0,-9.1831 3.5649,-17.2891 8.771,-24.6582c5.0362,-7.1424 12.3359,-14.4549 19.4659,-19.3865c15.3917,-10.6002 31.0663,-19.0464 48.3819,-25.1684c4.5265,-1.5872 8.9975,-2.9477 13.8075,-3.5145c2.376,3.0043 4.696,5.9519 7.017,8.8996z" fill="url(#paint14_linear_1415_146431)" id="svg_31" />
              <path d="m119.243,29.9094l2.603,5.1017c-16.241,3.5145 -31.1231,8.8996 -50.7022,21.8239c-6.9602,4.5349 -13.4111,10.0334 -19.4093,15.8153c-7.5261,7.2558 -11.5438,15.8153 -12.5058,24.0347c-0.9054,7.539 -0.1697,15.305 -0.2829,22.844c-0.1698,10.601 -0.2829,119.89 -0.2829,119.89l-16.863,8.22c0,0 0,-153.7883 0,-153.845c0,-9.183 3.7914,-17.119 8.9974,-24.4881c5.0362,-7.1424 12.1096,-14.6249 19.2396,-19.5566c15.3916,-10.6002 31.0662,-19.0463 48.3819,-25.1684c4.5272,-1.5872 8.9972,-2.9476 13.8072,-3.5145c2.377,2.9477 4.697,5.8953 7.017,8.843z" fill="url(#paint15_radial_1415_146431)" id="svg_32" />
              <path d="m119.413,30.1931l2.603,5.1017c-16.241,3.5145 -31.1233,8.8996 -50.7024,21.8239c-6.9603,4.5349 -13.4112,10.0334 -19.4094,15.8153c-7.5261,7.2558 -11.5437,15.8153 -12.5057,24.0347c-0.9054,7.5393 -0.1698,15.3053 -0.283,22.8443c-0.1697,10.6 -17.1458,-25.7353 -17.1458,-25.7353c0,-9.183 3.5649,-17.2891 8.771,-24.6582c5.0362,-7.1424 12.3359,-14.4548 19.4659,-19.3865c15.3917,-10.6002 31.0663,-19.0463 48.3819,-25.1684c4.5265,-1.5872 8.9975,-2.9476 13.8075,-3.5145c2.376,2.9476 4.696,5.8953 7.017,8.843z" fill="url(#paint16_radial_1415_146431)" id="svg_33" />
              <path d="m91.4019,27.5854c0,0 -19.4659,7.5392 -37.5738,20.0667c-11.3174,7.8226 -19.183,16.7223 -19.183,16.7223c0,0 7.9788,-10.827 25.6906,-22.5609c16.9195,-11.2237 28.2935,-16.042 28.2935,-16.042l2.7727,1.8139z" fill="url(#paint17_linear_1415_146431)" id="svg_34" />
              <path d="m61.2977,304.268l-16.9196,-12.868c-1.4146,-1.077 -1.1883,-3.288 0.3961,-4.025l6.3944,-3.061c0.7922,-0.397 1.7542,-0.283 2.4332,0.227l16.0142,11.847c1.3015,0.964 1.3015,2.891 0,3.855l-5.489,4.025c-0.8488,0.68 -1.9805,0.68 -2.8293,0z" fill="url(#paint18_radial_1415_146431)" id="svg_35" />
              <path opacity="0.3" d="m44.7742,287.942l6.3944,-3.061c0.7922,-0.397 1.7542,-0.283 2.4332,0.227l16.0141,11.847c0.5659,0.397 0.8488,1.021 0.9054,1.644c0.1132,-0.794 -0.2263,-1.644 -0.9054,-2.211l-16.0141,-11.847c-0.679,-0.51 -1.641,-0.624 -2.4332,-0.227l-6.3944,3.061c-0.962,0.454 -1.4712,1.474 -1.3581,2.438c0.1132,-0.794 0.5659,-1.474 1.3581,-1.871z" fill="#2B0691" id="svg_36" />
              <path opacity="0.6" d="m42.2844,276.832l-15.4482,-11.621c-0.7923,-0.567 -1.7542,-0.68 -2.6596,-0.283l-5.6021,2.664c-1.5845,0.907 -1.7542,3.174 -0.283,4.308l15.4483,11.621c0.7922,0.567 1.7542,0.68 2.6596,0.283l5.6021,-2.664c1.641,-0.907 1.8108,-3.175 0.2829,-4.308z" fill="url(#paint19_radial_1415_146431)" id="svg_37" />
              <path opacity="0.5" d="m18.6311,268.159l5.6021,-2.664c0.8488,-0.397 1.8674,-0.284 2.6596,0.283l15.4483,11.621c0.6224,0.453 0.9619,1.133 1.0185,1.757c0.1132,-0.85 -0.2263,-1.757 -1.0185,-2.324l-15.4483,-11.621c-0.7922,-0.567 -1.7542,-0.68 -2.6596,-0.283l-5.6021,2.664c-0.962,0.51 -1.4147,1.531 -1.3015,2.551c0.1132,-0.794 0.5093,-1.531 1.3015,-1.984z" fill="#2B0691" id="svg_38" />
              <path d="m25.761,275.641l-5.6021,-4.194c-0.7923,-0.624 -0.5093,-2.211 -0.5093,-2.211l5.0362,-2.154c0.4527,-0.227 1.0186,-0.17 1.4147,0.113l6.1114,4.195c0,0 0.3395,1.644 -0.5659,2.097l-4.4703,2.211c-0.4527,0.283 -0.962,0.227 -1.4147,-0.057z" fill="#2B0691" id="svg_39" />
              <path d="m25.8176,275.245l-5.6022,-4.195c-0.7922,-0.623 -0.5092,-2.211 -0.5092,-2.211l5.0362,-2.154c0.4527,-0.226 1.0186,-0.17 1.4147,0.114l6.1114,4.194c0,0 0.3395,1.644 -0.5659,2.098l-4.4704,2.211c-0.5092,0.283 -1.0185,0.283 -1.4146,-0.057z" fill="#B70870" id="svg_40" />
              <path d="m25.8176,274.281l-5.5456,-4.138c-0.7922,-0.624 -0.679,-1.871 0.2264,-2.324l4.244,-2.098c0.4527,-0.226 1.0186,-0.17 1.4147,0.114l5.5455,4.138c0.7922,0.623 0.6791,1.87 -0.2263,2.324l-4.2441,2.097c-0.5092,0.284 -1.0185,0.227 -1.4146,-0.113z" fill="url(#paint20_linear_1415_146431)" id="svg_41" />
              <path d="m35.0413,282.613l-5.5455,-4.138c-0.7922,-0.623 -0.5093,-2.154 -0.5093,-2.154l4.9797,-2.097c0.4527,-0.227 0.9619,-0.17 1.4146,0.113l6.0548,4.195c0,0 0.3396,1.644 -0.5658,2.097l-4.4138,2.211c-0.4527,0.113 -1.0186,0.113 -1.4147,-0.227z" fill="#2B0691" id="svg_42" />
              <path d="m35.0413,282.274l-5.5455,-4.139c-0.7922,-0.623 -0.5093,-2.154 -0.5093,-2.154l4.9797,-2.097c0.4527,-0.227 0.9619,-0.17 1.4146,0.113l6.0548,4.195c0,0 0.3396,1.644 -0.5658,2.098l-4.4138,2.21c-0.4527,0.114 -1.0186,0.057 -1.4147,-0.226z" fill="#D88705" id="svg_43" />
              <path d="m35.0414,281.31l-5.5456,-4.082c-0.7922,-0.623 -0.679,-1.87 0.2264,-2.324l4.244,-2.04c0.4527,-0.227 0.962,-0.171 1.4147,0.113l5.5455,4.081c0.7922,0.624 0.6791,1.871 -0.2263,2.324l-4.244,2.041c-0.4527,0.227 -1.0186,0.17 -1.4147,-0.113z" fill="url(#paint21_linear_1415_146431)" id="svg_44" />
              <path opacity="0.6" d="m52.1871,295.481l-5.9416,-4.364c-0.7922,-0.624 -0.5093,-2.211 -0.5093,-2.211l4.9797,-2.154c0.4527,-0.227 1.0185,-0.17 1.4146,0.113l6.1114,4.195c0,0 0.3395,1.587 -0.5658,2.097c-1.0186,0.624 -4.1309,2.381 -4.1309,2.381c-0.4527,0.283 -0.962,0.227 -1.3581,-0.057z" fill="#2B0691" id="svg_45" />
              <path d="m52.1871,295.085l-5.885,-4.365c-0.7923,-0.624 -0.5093,-2.211 -0.5093,-2.211l4.9796,-2.154c0.4527,-0.226 1.0186,-0.17 1.4147,0.114l6.1114,4.194c0,0 0.3395,1.588 -0.5659,2.098c-1.0185,0.623 -4.1308,2.38 -4.1308,2.38c-0.4527,0.284 -1.0186,0.227 -1.4147,-0.056z" fill="#0694BA" id="svg_46" />
              <path d="m52.1871,294.121l-5.885,-4.308c-0.7923,-0.624 -0.6791,-1.871 0.2263,-2.324l4.244,-2.098c0.4527,-0.226 1.0186,-0.17 1.4147,0.114l5.5455,4.081c0.7923,0.624 0.6225,1.757 -0.2263,2.324c-0.962,0.624 -3.9611,2.268 -3.9611,2.268c-0.3961,0.283 -0.962,0.226 -1.3581,-0.057z" fill="url(#paint22_linear_1415_146431)" id="svg_47" />
              <path opacity="0.6" d="m62.4859,303.191l-6.5641,-4.875c-0.8488,-0.624 -0.4527,-2.267 -0.4527,-2.267l4.9797,-2.325c0.4527,-0.226 1.0186,-0.17 1.4147,0.17l6.5641,4.479c0,0 0.2263,1.473 -0.7357,2.21c-0.9053,0.681 -3.7347,2.835 -3.7347,2.835c-0.5093,0.17 -1.0752,0.113 -1.4713,-0.227z" fill="#2B0691" id="svg_48" />
              <path d="m62.4859,302.851l-6.5641,-4.932c-0.8488,-0.623 -0.4527,-2.267 -0.4527,-2.267l4.9797,-2.324c0.4527,-0.227 1.0186,-0.17 1.4147,0.17l6.3943,4.308c0,0 0.6225,1.417 -0.5659,2.381c-0.9619,0.793 -3.7347,2.834 -3.7347,2.834c-0.5093,0.17 -1.0186,0.114 -1.4713,-0.17z" fill="#2178D8" id="svg_49" />
              <path d="m62.5426,301.831l-6.5641,-4.875c-0.8488,-0.624 -0.6791,-1.928 0.2263,-2.381l4.1875,-2.268c0.4526,-0.226 1.0185,-0.17 1.4146,0.171l5.7719,4.421c0.962,0.737 0.679,1.701 -0.1698,2.324c-0.9053,0.68 -3.5084,2.664 -3.5084,2.664c-0.3961,0.34 -0.9619,0.284 -1.358,-0.056z" fill="url(#paint23_linear_1415_146431)" id="svg_50" />
              <path opacity="0.6" d="m-25.6766,271.787l100.0458,71.991l4.9797,11.904" stroke="#6B2FDD" strokeWidth="0.68637" strokeMiterlimit="10" id="svg_51" />
              <path d="m73.9166,322.351l7.2997,18.252l47.5897,0l-3.678,-18.252l-51.2114,0z" fill="url(#paint24_radial_1415_146431)" id="svg_52" />
              <path d="m140.746,298.656l-58.9073,0l-7.9221,23.694l66.8294,0l0,-23.694z" fill="url(#paint25_radial_1415_146431)" id="svg_53" />
              <path d="m133.333,298.713l-51.4942,0l-0.1132,-4.818l51.3814,0l0.226,4.818z" fill="url(#paint26_linear_1415_146431)" id="svg_54" />
              <path d="m157.213,284.598l0,9.637l-41.875,0l-33.669,0l0,-1.871c0,-4.308 4.6401,-11.847 9.1671,-11.847c11.5999,0 54.5499,0.17 62.5849,0.226c2.094,0 3.792,1.758 3.792,3.855z" fill="url(#paint27_radial_1415_146431)" id="svg_55" />
              <path opacity="0.7" d="m81.6124,292.364c0,-4.308 4.6402,-11.847 9.1671,-11.847c4.4138,0 11.0915,0 20.9375,0.056c0,0 -10.299,0.284 -16.4671,0.284c-5.206,0 -6.6773,-0.227 -9.5066,3.061c-4.0743,4.648 -4.1309,8.446 -4.1309,8.446z" fill="white" id="svg_56" />
              <path d="m127.844,309.086c11.845,0 21.446,-1.979 21.446,-4.421c0,-2.442 -9.601,-4.422 -21.446,-4.422c-11.845,0 -21.446,1.98 -21.446,4.422c0,2.442 9.601,4.421 21.446,4.421z" fill="url(#paint28_radial_1415_146431)" id="svg_57" />
              <path d="m104.587,345.025c-2.433,-0.284 -3.395,-10.034 -2.151,-21.824c1.245,-11.791 4.244,-21.144 6.678,-20.917c2.433,0.227 3.395,10.033 2.15,21.824c-1.301,11.79 -4.244,21.143 -6.677,20.917z" fill="url(#paint29_radial_1415_146431)" id="svg_58" />
              <path d="m92.4205,356.248c-2.4332,-0.34 -2.9991,-10.146 -1.3015,-21.88c1.6977,-11.734 5.0929,-20.974 7.5261,-20.634c2.4329,0.34 2.9989,10.147 1.3015,21.881c-1.7542,11.734 -5.1494,20.974 -7.5261,20.633z" fill="url(#paint30_radial_1415_146431)" id="svg_59" />
              <path d="m127.618,318.723c16.72,0 30.274,-2.386 30.274,-5.329c0,-2.942 -13.554,-5.328 -30.274,-5.328c-16.72,0 -30.2744,2.386 -30.2744,5.328c0,2.943 13.5544,5.329 30.2744,5.329z" fill="url(#paint31_radial_1415_146431)" id="svg_60" />
              <path d="m113.641,347.406l-6.451,0c-3.339,0 -6.055,-2.721 -6.055,-6.065l0,-0.227c0.113,-0.964 3.056,-23.071 4.584,-30.724c1.018,-5.158 5.206,-8.786 10.185,-8.786l11.883,0l0,3.458l-11.883,0c-3.395,0 -6.111,2.437 -6.847,6.009c-1.415,7.312 -4.3,28.569 -4.527,30.383c0.057,1.417 1.189,2.494 2.603,2.494l6.508,0l0,3.458z" fill="url(#paint32_linear_1415_146431)" id="svg_61" />
              <path d="m113.641,347.406l-6.451,0c-3.339,0 -6.055,-2.721 -6.055,-6.065l0,-0.227c0.113,-0.964 3.056,-23.071 4.584,-30.724c1.018,-5.158 5.206,-8.786 10.185,-8.786l11.883,0l0,3.458l-11.883,0c-3.395,0 -6.111,2.437 -6.847,6.009c-1.415,7.312 -4.3,28.569 -4.527,30.383c0.057,1.417 1.189,2.494 2.603,2.494l6.508,0l0,3.458z" fill="url(#paint33_linear_1415_146431)" id="svg_62" />
              <path d="m401.159,265.778l0,24.092l-90.709,65.528l-32.594,0l5.263,-14.795l118.04,-74.825z" fill="url(#paint34_radial_1415_146431)" id="svg_63" />
              <path d="m358.266,221.677l-69.602,72.671l1.698,32.481l9.393,0l104.686,-79.474l-46.175,-25.678z" fill="url(#paint35_radial_1415_146431)" id="svg_64" />
              <path d="m358.606,250.303l-66.433,50.45l-0.962,-7.085l62.811,-46.256l4.584,2.891z" fill="url(#paint36_linear_1415_146431)" id="svg_65" />
              <path d="m254.09,33.2537c-0.057,0.1134 2.659,1.0204 2.829,1.077c1.584,0.4535 3.169,1.0771 4.697,1.6439c3.225,1.1904 6.451,2.4375 9.62,3.7413c6.394,2.6075 12.675,5.4418 18.786,8.6162c1.811,0.9636 3.622,1.9273 5.433,2.9476c11.034,6.2355 21.39,13.8313 30.161,23.0144c5.093,5.2718 10.751,13.7179 11.317,24.885c0.453,9.4099 0.396,140.0139 0.396,140.0139l4.527,2.324l3.678,-3.231l0,-144.0953c0,-9.3531 -4.017,-17.4024 -7.469,-21.994c-5.546,-7.5392 -12.166,-12.9243 -20.032,-18.5362c-7.469,-5.3284 -16.58,-10.6002 -24.729,-14.8516c-10.864,-5.6686 -32.707,-13.3211 -32.707,-13.3211l-6.507,7.7659z" fill="url(#paint37_linear_1415_146431)" id="svg_66" />
              <path d="m284.816,33.1405c0,0 29.539,12.5276 47.76,32.0274c18.221,19.4999 11.544,56.7991 11.544,56.7991l44.251,1.7l15.901,-14.511c0,0 3.508,-7.426 -15.788,-26.7557c-19.296,-19.3298 -35.197,-31.4038 -53.249,-43.4778c-20.541,-13.7179 -30.16,-19.84 -67.168,-28.4562l-0.906,14.058l17.655,8.6162z" fill="url(#paint38_linear_1415_146431)" id="svg_67" />
              <path d="m284.816,33.1405c0,0 29.539,12.5276 47.76,32.0274c18.221,19.4999 11.544,56.7991 11.544,56.7991l44.251,1.7l15.901,-14.511c0,0 3.508,-7.426 -15.788,-26.7557c-19.296,-19.3298 -35.197,-31.4038 -53.249,-43.4778c-20.541,-13.7179 -30.16,-19.84 -67.168,-28.4562l-0.906,14.058l17.655,8.6162z" fill="url(#paint39_radial_1415_146431)" id="svg_68" />
              <path d="m406.196,91.0168c0,0 -3.509,-3.5145 -12.167,-4.4215c-10.185,-1.077 -16.919,-1.1904 -27.727,0.6236c-10.412,1.7572 -13.807,4.2514 -13.807,4.2514l0,9.5227c0,0 10.921,-1.7568 20.258,-2.0969c9.393,-0.3401 16.863,0.2267 20.88,1.8139c4.018,1.587 3.226,2.834 3.226,2.834c0,0 3.055,-0.963 5.602,-4.6479c2.659,-3.5712 3.735,-7.8793 3.735,-7.8793z" fill="url(#paint40_linear_1415_146431)" stroke="#001038" strokeWidth="0.24678" strokeMiterlimit="10" id="svg_69" />
              <path d="m370.263,224.058l6.564,0c0.849,0 1.528,-0.681 1.528,-1.531l0,-19.726c0,-0.851 -0.679,-1.531 -1.528,-1.531l-6.564,0c-0.849,0 -1.528,0.68 -1.528,1.531l0,19.726c0,0.85 0.679,1.531 1.528,1.531z" fill="#2DDCB6" stroke="#001038" strokeWidth="0.24678" strokeMiterlimit="10" id="svg_70" />
              <path d="m369.81,196.225l7.47,0c0.622,0 1.075,-0.51 1.075,-1.077l0,-9.183c0,-0.623 -0.509,-1.077 -1.075,-1.077l-7.47,0c-0.622,0 -1.075,0.51 -1.075,1.077l0,9.183c0,0.567 0.509,1.077 1.075,1.077z" fill="#B045EF" stroke="#001038" strokeWidth="0.24678" strokeMiterlimit="10" id="svg_71" />
              <path d="m397.651,246.165l0,-146.3624c0,0 -1.528,-4.8183 -20.541,-4.3648c-16.241,0.3968 -27.784,2.7209 -27.784,2.7209l3.282,148.1763l6.337,4.365c0,0 5.433,0.963 14.939,0.963c6.904,0 11.77,-0.963 16.524,-2.38c3.791,-1.191 7.243,-3.118 7.243,-3.118z" fill="url(#paint41_radial_1415_146431)" id="svg_72" />
              <path d="m369.81,182.45l7.47,0c0.622,0 1.075,-0.51 1.075,-1.077l0,-9.183c0,-0.623 -0.509,-1.077 -1.075,-1.077l-7.47,0c-0.622,0 -1.075,0.51 -1.075,1.077l0,9.183c0,0.567 0.509,1.077 1.075,1.077z" fill="url(#paint42_linear_1415_146431)" id="svg_73" />
              <path d="m369.81,168.676l7.47,0c0.622,0 1.075,-0.51 1.075,-1.077l0,-9.183c0,-0.624 -0.509,-1.077 -1.075,-1.077l-7.47,0c-0.622,0 -1.075,0.51 -1.075,1.077l0,9.183c0,0.624 0.509,1.077 1.075,1.077z" fill="url(#paint43_linear_1415_146431)" id="svg_74" />
              <path d="m369.81,143.621l7.47,0c0.622,0 1.075,-0.51 1.075,-1.077l0,-9.183c0,-0.624 -0.509,-1.077 -1.075,-1.077l-7.47,0c-0.622,0 -1.075,0.51 -1.075,1.077l0,9.183c0,0.567 0.509,1.077 1.075,1.077z" fill="url(#paint44_linear_1415_146431)" id="svg_75" />
              <path d="m354.362,247.525l-17.033,-8.389l-53.927,43.137l9.846,12.075l61.114,-46.823z" fill="url(#paint45_radial_1415_146431)" id="svg_76" />
              <path d="m404.441,247.355l-106.496,74.996l-50.136,0l0.792,18.252l46.345,0l109.495,-77.092l0,-16.156z" fill="url(#paint46_radial_1415_146431)" id="svg_77" />
              <path d="m358.606,96.9683l0.283,153.7317l-4.697,-3.061l-0.792,-150.1039l5.206,-0.5668z" fill="url(#paint47_linear_1415_146431)" id="svg_78" />
              <path d="m256.749,30.1931c17.655,5.7252 34.688,13.6045 50.419,23.4678c7.074,4.3648 13.921,9.1831 19.749,15.0784c7.47,7.5392 13.185,16.6656 13.977,27.3225c0.566,7.5392 0.679,15.1352 0.792,22.7312c0.17,10.6 0.17,21.257 0.114,31.857c-0.057,11.507 0.113,89.053 0.056,90.584l12.336,6.122l0,-153.3349c0,-9.1831 -3.565,-17.2891 -8.771,-24.6582c-5.036,-7.1424 -12.336,-14.4549 -19.466,-19.3865c-15.392,-10.6002 -31.066,-19.0464 -48.382,-25.1684c-4.527,-1.5872 -8.997,-2.9477 -13.807,-3.5145c-2.377,3.0043 -4.697,5.9519 -7.017,8.8996z" fill="url(#paint48_linear_1415_146431)" id="svg_79" />
              <path d="m256.976,30.1931l-2.603,5.1017c16.24,3.5145 31.575,9.5231 50.871,22.0507c6.564,4.2514 14.373,10.6569 20.089,16.7222c6.054,6.4055 8.884,11.1671 11.261,21.9373c1.584,7.369 0.339,16.212 0.679,23.808c0.396,10.6 0.282,119.38 0.282,119.38l16.863,8.219l0,-153.3343c0,-9.1831 -3.565,-17.2891 -8.771,-24.6582c-5.036,-7.1424 -12.336,-14.4548 -19.466,-19.3865c-15.391,-10.6002 -31.066,-19.0463 -48.381,-25.1684c-4.527,-1.5872 -8.998,-2.9476 -13.808,-3.5145c-2.376,2.9476 -4.696,5.8953 -7.016,8.843z" fill="url(#paint49_radial_1415_146431)" id="svg_80" />
              <path d="m345.704,96.912c-2.773,1.1337 -5.998,2.2107 -8.771,3.288c0.113,6.632 0.283,13.094 0.396,19.556c0.17,10.601 0.057,119.38 0.057,119.38l17.089,8.22l0,-153.335c0,-0.2267 0,-0.4534 0,-0.6802c-2.942,1.2471 -5.885,2.4375 -8.771,3.5712z" fill="url(#paint50_radial_1415_146431)" id="svg_81" />
              <path d="m284.59,27.5287c0,0 10.016,3.9113 21.56,10.2034c9.223,4.9883 21.503,13.4912 21.503,13.4912c0,0 -5.036,-5.4418 -17.089,-13.2645c-13.298,-8.5595 -23.145,-12.1307 -23.145,-12.1307l-2.829,1.7006z" fill="url(#paint51_linear_1415_146431)" id="svg_82" />
              <path opacity="0.4" d="m325.955,293.271c0.453,3.118 -3.112,6.179 -7.922,6.859c-4.81,0.68 -9.054,-1.304 -9.507,-4.365c-0.452,-3.117 3.113,-6.178 7.923,-6.859c4.809,-0.68 9.11,1.248 9.506,4.365z" fill="url(#paint52_radial_1415_146431)" id="svg_83" />
              <path opacity="0.4" d="m343.384,280.743c0.453,3.118 -3.112,6.179 -7.922,6.859c-4.81,0.681 -9.054,-1.303 -9.507,-4.364c-0.453,-3.118 3.112,-6.179 7.922,-6.859c4.81,-0.681 9.054,1.247 9.507,4.364z" fill="url(#paint53_radial_1415_146431)" id="svg_84" />
              <path opacity="0.4" d="m360.586,267.649c0.453,3.118 -3.112,6.179 -7.922,6.859c-4.81,0.68 -9.054,-1.304 -9.506,-4.365c-0.453,-3.117 3.112,-6.178 7.922,-6.859c4.81,-0.68 9.054,1.247 9.506,4.365z" fill="url(#paint54_radial_1415_146431)" id="svg_85" />
              <path opacity="0.5" d="m317.88,298.782c3.776,-0.531 6.559,-2.948 6.215,-5.397c-0.344,-2.449 -3.683,-4.004 -7.459,-3.472c-3.776,0.531 -6.558,2.948 -6.214,5.397c0.343,2.449 3.683,4.004 7.458,3.472z" fill="#00389B" id="svg_86" />
              <path d="m323.692,292.931c0.339,2.324 -2.377,4.648 -5.999,5.158c-3.621,0.511 -6.847,-0.963 -7.186,-3.344c-0.34,-2.381 2.377,-4.648 5.998,-5.158c3.622,-0.511 6.847,0.963 7.187,3.344z" fill="url(#paint55_radial_1415_146431)" id="svg_87" />
              <path d="m335.199,286.424c3.775,-0.531 6.558,-2.948 6.214,-5.397c-0.344,-2.449 -3.683,-4.004 -7.459,-3.472c-3.776,0.532 -6.558,2.948 -6.214,5.397c0.343,2.45 3.683,4.004 7.459,3.472z" fill="#7405A3" id="svg_88" />
              <path d="m341.007,280.573c0.34,2.324 -2.376,4.648 -5.998,5.159c-3.621,0.51 -6.847,-0.964 -7.186,-3.345c-0.34,-2.324 2.376,-4.648 5.998,-5.158c3.621,-0.51 6.847,0.963 7.186,3.344z" fill="url(#paint56_radial_1415_146431)" id="svg_89" />
              <path opacity="0.5" d="m352.403,273.441c3.776,-0.532 6.558,-2.948 6.214,-5.397c-0.343,-2.45 -3.683,-4.004 -7.459,-3.472c-3.775,0.531 -6.558,2.948 -6.214,5.397c0.344,2.449 3.683,4.004 7.459,3.472z" fill="#012E56" id="svg_90" />
              <path d="m358.21,267.536c0.339,2.324 -2.377,4.648 -5.998,5.158c-3.622,0.511 -6.847,-0.963 -7.187,-3.344c-0.34,-2.324 2.377,-4.648 5.998,-5.158c3.622,-0.511 6.847,1.02 7.187,3.344z" fill="url(#paint57_radial_1415_146431)" id="svg_91" />
              <path opacity="0.6" d="m296.077,355.172l5.036,-11.394l100.046,-71.991" stroke="#223CAA" strokeWidth="0.68637" strokeMiterlimit="10" id="svg_92" />
              <path d="m149.8,379.773l-49.9666,0c-3.3386,0 -6.564,-1.417 -8.8275,-3.854c-2.0938,-2.211 -3.0557,-5.045 -2.886,-7.993c0.9054,-11.904 4.9231,-35.089 7.13,-47.559l0.3395,-1.928c0.9054,-5.215 5.0926,-8.502 10.9216,-8.502l20.88,0l0,3.457l-20.88,0c-1.981,0 -6.6776,0.567 -7.5264,5.669l-0.3395,1.927c-2.1503,12.414 -6.168,35.485 -7.0734,47.219c-0.1697,1.984 0.5659,3.855 1.9806,5.385c1.641,1.758 3.9045,2.721 6.2811,2.721l49.9666,0c4.3,0 5.715,-4.194 6.224,-6.689c0.396,-2.04 1.472,-8.106 1.472,-8.106l3.395,0.624c0,0 -1.132,6.122 -1.471,8.106c-1.132,5.952 -4.697,9.523 -9.62,9.523z" fill="url(#paint58_linear_1415_146431)" id="svg_93" />
              <path d="m128.919,305.685l-1.811,0c-0.792,0 -1.414,-0.624 -1.414,-1.417l0,-1.984c0,-0.794 0.622,-1.417 1.414,-1.417l2.377,0l-0.566,4.818z" fill="url(#paint59_radial_1415_146431)" id="svg_94" />
              <path d="m115.169,348.029l-2.66,0c-0.792,0 -1.415,-0.623 -1.415,-1.417l0,-1.984c0,-0.794 0.623,-1.417 1.415,-1.417l1.811,0l0.849,4.818z" fill="url(#paint60_radial_1415_146431)" id="svg_95" />
              <path d="m128.41,314.244l-2.037,0c-0.623,0 -1.189,-0.51 -1.189,-1.19l0,-2.664c0,-0.624 0.51,-1.191 1.189,-1.191l2.433,0l-0.396,5.045z" fill="url(#paint61_radial_1415_146431)" id="svg_96" />
              <path d="m162.475,360.16l0,1.587c0,0.907 -0.735,1.644 -1.641,1.644l-3.169,0c-0.905,0 -1.641,-0.737 -1.641,-1.644l0,-1.587l6.451,0z" fill="url(#paint62_radial_1415_146431)" id="svg_97" />
              <path d="m271.858,357.892l-152.106,0c-1.867,0 -3.056,-1.587 -3.452,-3.458c-0.679,-3.571 -2.037,-10.77 -2.659,-14.454c-0.283,-1.758 1.471,-3.288 3.282,-3.288l170.044,0c0,0 -5.206,13.661 -6.847,17.742c-1.698,4.139 -6.395,3.458 -8.262,3.458z" fill="url(#paint63_radial_1415_146431)" id="svg_98" />
              <path d="m261.559,50.7131l15.335,-8.6163c0.51,-1.5871 1.189,-3.7979 1.189,-3.7979l6.45,-10.7702l14.147,-8.5029l-13.241,-14.68156l-23.88,46.36886z" fill="url(#paint64_linear_1415_146431)" id="svg_99" />
              <path d="m90.1005,4.34424l-12.6189,14.68156c5.5455,3.4011 13.9204,8.5595 13.9204,8.5595c0,0 4.5835,7.3125 6.6207,10.7136c0.4526,1.6439 0.679,2.4375 1.0751,3.8546l15.7882,8.5596l-24.7855,-46.36886z" fill="url(#paint65_linear_1415_146431)" id="svg_100" />
              <path d="m97.4569,311.297c-0.5093,3.175 -4.1309,5.726 -8.092,5.726c-3.9611,0 -6.7904,-2.721 -5.7719,-5.839c1.0186,-3.174 5.3192,-5.952 8.8276,-5.952c3.5084,0 5.5455,2.891 5.0363,6.065z" fill="url(#paint66_linear_1415_146431)" id="svg_101" />
              <path d="m96.6647,310.22c-0.2264,2.948 -3.6782,5.726 -7.2998,5.726c-3.6781,0 -5.6021,-2.268 -5.6021,-5.329c0,-3.117 5.206,-5.555 8.3749,-5.555c3.2255,0.057 4.7533,1.814 4.527,5.158z" fill="url(#paint67_linear_1415_146431)" id="svg_102" />
              <path d="m96.4382,309.2c-0.4527,2.891 -3.7914,5.272 -7.4695,5.272c-3.6782,0 -5.8851,-2.381 -4.9797,-5.272c0.9054,-2.891 4.2441,-5.272 7.4695,-5.272c3.2255,0 5.4324,2.381 4.9797,5.272z" fill="url(#paint68_linear_1415_146431)" id="svg_103" />
              <path d="m246.224,336.862l40.573,0c3.565,0 6.451,-2.891 6.451,-6.462l0,-36.108l-49.627,0l2.603,42.57z" fill="url(#paint69_linear_1415_146431)" id="svg_104" />
              <path d="m258.107,307.216c-2.263,0 -4.13,-1.871 -4.13,-4.138c0,-2.267 1.867,-4.138 4.13,-4.138c2.264,0 4.131,1.871 4.131,4.138c0,2.267 -1.867,4.138 -4.131,4.138z" fill="#101B68" id="svg_105" />
              <path d="m258.107,307.669c-2.489,0 -4.583,-2.04 -4.583,-4.591c0,-2.551 2.037,-4.592 4.583,-4.592c2.547,0 4.584,2.041 4.584,4.592c0,2.551 -2.037,4.591 -4.584,4.591zm0,-8.276c-2.037,0 -3.678,1.644 -3.678,3.685c0,2.041 1.641,3.684 3.678,3.684c2.038,0 3.679,-1.643 3.679,-3.684c0,-2.041 -1.641,-3.685 -3.679,-3.685z" fill="url(#paint70_linear_1415_146431)" id="svg_106" />
              <path d="m258.107,320.877c-2.263,0 -4.13,-1.87 -4.13,-4.138c0,-2.267 1.867,-4.138 4.13,-4.138c2.264,0 4.131,1.871 4.131,4.138c0,2.268 -1.867,4.138 -4.131,4.138z" fill="#101B68" id="svg_107" />
              <path d="m258.107,321.331c-2.489,0 -4.583,-2.041 -4.583,-4.592c0,-2.551 2.037,-4.592 4.583,-4.592c2.547,0 4.584,2.041 4.584,4.592c0,2.551 -2.037,4.592 -4.584,4.592zm0,-8.22c-2.037,0 -3.678,1.644 -3.678,3.685c0,2.04 1.641,3.684 3.678,3.684c2.038,0 3.679,-1.644 3.679,-3.684c0,-2.041 -1.641,-3.685 -3.679,-3.685z" fill="url(#paint71_linear_1415_146431)" id="svg_108" />
              <path d="m281.421,301.378l0,28.569c0,1.474 1.189,2.664 2.66,2.664c1.471,0 2.659,-1.19 2.659,-2.664l0,-28.569c0,-1.474 -1.188,-2.665 -2.659,-2.665c-1.471,0 -2.66,1.191 -2.66,2.665z" fill="url(#paint72_radial_1415_146431)" id="svg_109" />
              <path d="m268.972,301.378l0,28.569c0,1.474 1.189,2.664 2.66,2.664c1.471,0 2.659,-1.19 2.659,-2.664l0,-28.569c0,-1.474 -1.188,-2.665 -2.659,-2.665c-1.471,0 -2.66,1.191 -2.66,2.665z" fill="url(#paint73_radial_1415_146431)" id="svg_110" />
              <path d="m282.214,305.572l0,2.778c0,1.02 0.848,1.871 1.867,1.871c1.018,0 1.867,-0.851 1.867,-1.871l0,-2.778c0,-1.02 -0.849,-1.87 -1.867,-1.87c-1.019,0.056 -1.867,0.85 -1.867,1.87z" fill="url(#paint74_linear_1415_146431)" id="svg_111" />
              <path d="m269.821,322.691l0,2.778c0,1.02 0.849,1.87 1.867,1.87c1.019,0 1.868,-0.85 1.868,-1.87l0,-2.778c0,-1.02 -0.849,-1.871 -1.868,-1.871c-1.075,0 -1.867,0.851 -1.867,1.871z" fill="url(#paint75_linear_1415_146431)" id="svg_112" />
              <path d="m217.025,294.348l76.223,0l-0.057,-3.004c0,-4.762 -7.016,-10.714 -14.486,-10.714c-11.487,0 -47.759,0.17 -55.795,0.227c-2.093,0 -4.187,1.757 -4.527,3.855c-0.679,4.761 -1.358,9.636 -1.358,9.636z" fill="url(#paint76_radial_1415_146431)" id="svg_113" />
              <path d="m187.826,362.427c52.316,0 94.727,-14.999 94.727,-33.501c0,-18.502 -42.411,-33.501 -94.727,-33.501c-52.316,0 -94.7264,14.999 -94.7264,33.501c0,18.502 42.4104,33.501 94.7264,33.501z" fill="url(#paint77_radial_1415_146431)" id="svg_114" />
              <path opacity="0.5" d="m130.787,298.712l-48.9483,0l-22.1255,-16.608l21.9558,17.005l49.061,0.114l0.057,-0.511z" fill="#2B0691" id="svg_115" />
              <path d="m245.941,292.364l-116.286,0l0,-0.114c0,-4.024 3.282,-7.312 7.3,-7.312l101.573,0c4.131,0.057 7.413,3.344 7.413,7.426z" fill="url(#paint78_linear_1415_146431)" id="svg_116" />
              <path opacity="0.7" d="m147.706,284.995l-10.752,0c-4.017,0 -7.299,3.287 -7.299,7.312c0,0 0.226,-3.684 4.074,-5.895c0.679,-0.397 2.094,-0.907 2.943,-0.907c2.772,-0.114 11.034,-0.51 11.034,-0.51z" fill="white" id="svg_117" />
              <path d="m245.941,292.42l-116.286,0l-1.585,22.901l119.456,0l-1.585,-22.901z" fill="url(#paint79_linear_1415_146431)" id="svg_118" />
              <path d="m162.136,305.344l-25.804,0c-0.509,0 -0.962,-0.453 -0.962,-0.964l0,-2.21c0,-0.794 0.622,-1.418 1.415,-1.418l25.351,0c0.509,0 0.962,0.454 0.962,0.964l0,2.721c0,0.453 -0.396,0.907 -0.962,0.907z" fill="url(#paint80_radial_1415_146431)" stroke="#C2E9F2" strokeWidth="0.24678" strokeMiterlimit="10" id="svg_119" />
              <path d="m162.136,305.627l-25.804,0c-0.679,0 -1.245,-0.567 -1.245,-1.247l0,-2.211c0,-0.963 0.792,-1.7 1.698,-1.7l25.351,0c0.679,0 1.245,0.567 1.245,1.247l0,2.721c0,0.623 -0.566,1.19 -1.245,1.19zm-25.295,-4.648c-0.622,0 -1.131,0.51 -1.131,1.134l0,2.21c0,0.397 0.282,0.681 0.679,0.681l25.803,0c0.396,0 0.679,-0.284 0.679,-0.681l0,-2.721c0,-0.396 -0.283,-0.68 -0.679,-0.68l-25.351,0l0,0.057z" fill="url(#paint81_linear_1415_146431)" id="svg_120" />
              <path d="m203.84,305.344l-31.519,0c-0.509,0 -0.962,-0.453 -0.962,-0.964l0,-2.721c0,-0.51 0.453,-0.963 0.962,-0.963l31.519,0c0.51,0 0.962,0.453 0.962,0.963l0,2.721c0,0.511 -0.452,0.964 -0.962,0.964z" fill="url(#paint82_radial_1415_146431)" id="svg_121" />
              <path d="m214.026,305.344l25.804,0c0.509,0 0.962,-0.453 0.962,-0.964l0,-2.21c0,-0.794 -0.623,-1.418 -1.415,-1.418l-25.351,0c-0.509,0 -0.962,0.454 -0.962,0.964l0,2.721c0,0.453 0.453,0.907 0.962,0.907z" fill="url(#paint83_radial_1415_146431)" stroke="#C2E9F2" strokeWidth="0.24678" strokeMiterlimit="10" id="svg_122" />
              <path d="m239.83,305.627l-25.804,0c-0.679,0 -1.245,-0.567 -1.245,-1.247l0,-2.211c0,-0.963 0.792,-1.7 1.698,-1.7l25.351,0c0.679,0 1.245,0.567 1.245,1.247l0,2.721c0,0.623 -0.566,1.19 -1.245,1.19zm-25.351,-4.648c-0.623,0 -1.132,0.51 -1.132,1.134l0,2.21c0,0.397 0.283,0.681 0.679,0.681l25.804,0c0.396,0 0.679,-0.284 0.679,-0.681l0,-2.721c0,-0.396 -0.283,-0.68 -0.679,-0.68l-25.351,0l0,0.057z" fill="url(#paint84_linear_1415_146431)" id="svg_123" />
              <path d="m187.77,310.39c-3.339,0 -6.055,-2.721 -6.055,-6.065c0,-3.345 2.716,-6.066 6.055,-6.066c3.338,0 6.054,2.721 6.054,6.066c0,3.401 -2.716,6.065 -6.054,6.065z" fill="url(#paint85_radial_1415_146431)" id="svg_124" />
              <path d="m188.279,306.535c-1.924,0 -3.452,-1.53 -3.452,-3.458c0,-1.927 1.528,-3.457 3.452,-3.457c1.924,0 3.452,1.53 3.452,3.457c0,1.871 -1.585,3.458 -3.452,3.458z" fill="url(#paint86_linear_1415_146431)" id="svg_125" />
              <path d="m200.841,271.957l0,-1.814c0,-2.551 -2.093,-4.648 -4.64,-4.648l-16.919,0c-2.547,0 -4.641,2.097 -4.641,4.648l0,1.814l26.2,0z" fill="url(#paint87_radial_1415_146431)" id="svg_126" />
              <path opacity="0.7" d="m174.698,271.957l0,-1.814c0,-2.551 2.094,-4.648 4.64,-4.648l16.92,0l-16.637,0.34c-1.358,0.113 -3.055,0.623 -4.187,2.494c-0.566,1.077 -0.736,3.628 -0.736,3.628z" fill="white" id="svg_127" />
              <path d="m191.391,305.345c28.315,0 51.268,-4.772 51.268,-10.657c0,-5.886 -22.953,-10.657 -51.268,-10.657c-28.314,0 -51.268,4.771 -51.268,10.657c0,5.885 22.954,10.657 51.268,10.657z" fill="url(#paint88_radial_1415_146431)" id="svg_128" />
              <path d="m81.669,294.234l-30.6702,-23.808l30.6136,23.071l0.0566,0.737z" fill="url(#paint89_linear_1415_146431)" id="svg_129" />
              <path d="m76.9723,320.933c-0.5093,0 -0.8488,-0.51 -0.7357,-0.963l5.4324,-20.804l-6.5641,19.67c-0.283,0.907 -1.4147,1.304 -2.2069,0.737l-43.9681,-30.214l44.9867,32.991l19.9186,0l-16.8629,-1.417z" fill="url(#paint90_linear_1415_146431)" id="svg_130" />
              <path d="m155.968,273.487c-1.472,0 -2.83,0.851 -3.395,2.211l-8.885,18.763c-0.452,1.077 -0.509,3.741 1.472,3.741l85.389,0c1.528,0 1.924,-2.664 1.472,-3.684l-8.545,-18.763c-0.566,-1.361 -1.924,-2.268 -3.395,-2.268l-64.113,0z" fill="url(#paint91_linear_1415_146431)" id="svg_131" />
              <path d="m156.647,271.957c-1.868,0 -3.622,1.134 -4.357,2.834l-8.602,19.67c-0.452,1.077 0.283,2.211 1.472,2.211l85.389,0c1.132,0 1.924,-1.134 1.472,-2.211l-8.262,-19.613c-0.736,-1.757 -2.49,-2.948 -4.414,-2.948l-62.698,0l0,0.057z" fill="url(#paint92_linear_1415_146431)" id="svg_132" />
              <path d="m261.559,50.7131l-146.617,0l-24.785,-46.36886l195.338,0l-23.936,46.36886z" fill="url(#paint93_linear_1415_146431)" id="svg_133" />
              <path d="m114.942,50.7131l-24.785,-46.36886l110.005,0l-102.9882,1.64388c-1.4712,0.05669 -2.3766,1.53051 -1.8108,2.89097l19.579,41.83401z" fill="url(#paint94_linear_1415_146431)" id="svg_134" />
              <path d="m81.669,294.234l-0.0566,-0.736l34.8006,0.68l-34.744,0.056z" fill="url(#paint95_radial_1415_146431)" id="svg_135" />
              <path d="m247.865,332.95l-119.512,0l0,13.775l3.226,11.904c0.283,0.963 1.188,1.644 2.207,1.644l109.099,0c1.076,0 1.981,-0.737 2.207,-1.758l2.773,-11.734l0,-13.831z" fill="url(#paint96_linear_1415_146431)" id="svg_136" />
              <path d="m247.978,346.725l-119.511,0l-8.149,-6.576l134.96,0l-7.3,6.576z" fill="url(#paint97_linear_1415_146431)" id="svg_137" />
              <path d="m162.023,355.455l-25.578,0c-0.566,0 -1.075,-0.454 -1.075,-1.077l0,-2.494c0,-0.567 0.453,-1.077 1.075,-1.077l25.578,0c0.565,0 1.075,0.453 1.075,1.077l0,2.494c0,0.623 -0.453,1.077 -1.075,1.077z" fill="url(#paint98_linear_1415_146431)" id="svg_138" />
              <path d="m135.37,354.491l0,-2.607c0,-0.567 0.453,-1.077 1.075,-1.077l25.578,0c0.565,0 1.075,0.453 1.075,1.077l0,2.607c0,0 -0.113,-1.304 -0.17,-1.927c-0.113,-0.737 -0.453,-0.907 -1.188,-0.907l-25.068,0c-0.51,0 -0.962,0.17 -1.189,0.964l-0.113,1.87z" fill="url(#paint99_linear_1415_146431)" id="svg_139" />
              <path d="m203.727,355.455l-31.349,0c-0.566,0 -1.075,-0.454 -1.075,-1.077l0,-2.494c0,-0.567 0.453,-1.077 1.075,-1.077l31.349,0c0.566,0 1.075,0.453 1.075,1.077l0,2.494c0,0.623 -0.452,1.077 -1.075,1.077z" fill="url(#paint100_linear_1415_146431)" id="svg_140" />
              <path d="m214.139,355.455l25.578,0c0.565,0 1.075,-0.454 1.075,-1.077l0,-2.494c0,-0.567 -0.453,-1.077 -1.075,-1.077l-25.578,0c-0.566,0 -1.075,0.453 -1.075,1.077l0,2.494c0,0.623 0.453,1.077 1.075,1.077z" fill="url(#paint101_linear_1415_146431)" id="svg_141" />
              <path d="m213.064,354.491l0,-2.607c0,-0.567 0.453,-1.077 1.075,-1.077l25.578,0c0.565,0 1.075,0.453 1.075,1.077l0,2.607c0,0 -0.113,-1.304 -0.17,-1.927c-0.113,-0.737 -0.453,-0.907 -1.188,-0.907l-25.068,0c-0.51,0 -0.962,0.17 -1.189,0.964l-0.113,1.87z" fill="url(#paint102_linear_1415_146431)" id="svg_142" />
              <path d="m171.359,354.491l0,-2.607c0,-0.567 0.453,-1.077 1.076,-1.077l31.292,0c0.566,0 1.075,0.453 1.075,1.077l0,2.607c0,0 -0.113,-1.304 -0.169,-1.927c-0.114,-0.737 -0.453,-0.907 -1.189,-0.907l-30.726,0c-0.51,0 -0.962,0.17 -1.189,0.964l-0.17,1.87z" fill="url(#paint103_linear_1415_146431)" id="svg_143" />
              <path d="m254.373,340.433l-133.15,0c-1.018,0 -1.81,-0.85 -1.697,-1.87l0.735,-7.426l135.074,0l0.735,7.426c0.057,0.963 -0.735,1.87 -1.697,1.87z" fill="url(#paint104_linear_1415_146431)" id="svg_144" />
              <path opacity="0.7" d="m132.088,292.42l-2.433,0l-1.019,15.079l1.358,-14.228c0,-0.284 0.227,-0.454 0.51,-0.51l1.584,-0.341z" fill="white" id="svg_145" />
              <path d="m244.413,309.653l-112.891,0c-0.452,0 -0.849,0.227 -1.018,0.623l-10.073,20.18c-0.566,1.134 0.283,2.438 1.528,2.438l131.678,0c1.245,0 2.094,-1.304 1.528,-2.438l-9.677,-20.123c-0.226,-0.397 -0.622,-0.68 -1.075,-0.68z" fill="url(#paint105_linear_1415_146431)" id="svg_146" />
            </g>
          </g>
          <g id="svg_164">
            <svg width="136" height="24" viewBox="0 0 136 24" fill="none" id="svg_4" x="119.18385" y="311.41553" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#svg_9)" id="svg_147">
                <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="#0C056B" id="svg_152" />
                <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="url(#svg_3)" id="svg_151" />
                <path d="m132.542,22.3534l-129.39484,0c-0.84609,0 -1.41014,-0.9041 -1.0153,-1.6387l9.30694,-18.64664c0.1692,-0.39553 0.5641,-0.62155 1.0153,-0.62155l111.1189,0c0.452,0 0.846,0.22602 1.016,0.62155l8.968,18.64664c0.339,0.7911 -0.169,1.6387 -1.015,1.6387zm-8.235,-22.03699l-112.5298,0c-0.4512,0 -0.846,0.22602 -1.0153,0.62155l-10.09659,20.11584c-0.56406,1.1301 0.28203,2.4297 1.52295,2.4297l131.25574,0c1.241,0 2.087,-1.2996 1.523,-2.4297l-9.645,-20.05933c-0.169,-0.39554 -0.621,-0.67806 -1.015,-0.67806z" fill="url(#svg_2)" id="svg_148" />
              </g>
              <defs>
                <clipPath id="svg_9">
                  <rect width="134.697" height="23.2236" fill="white" y="0.3169" x="0.49609" id="svg_12" />
                </clipPath>
              </defs>
            </svg>
          </g>
          <g id="svg_173">
            <svg width="89" height="26" viewBox="0 0 89 26" fill="none" id="svg_165" x="142.2804" y="273" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#svg_166)" id="svg_168">
                <path d="m2.22814,21.7263l8.25566,-18.88653c0.3393,-0.79882 1.0744,-1.2553 1.9226,-1.2553l64.9711,0c0.8481,0 1.6398,0.51353 1.9225,1.31236l7.9164,18.88657c0.5655,1.4265 -0.4523,2.9671 -1.9225,2.9671l-81.08666,0c-1.52674,-0.0571 -2.60111,-1.5977 -1.9791,-3.0242z" fill="#0C056B" id="svg_172" />
                <path d="m13.6504,1.01367c-1.4137,0 -2.7142,0.85589 -3.2797,2.16825l-8.65148,18.43008c-0.45236,1.0271 -0.45236,3.6518 1.41365,3.6518l83.06573,0c1.4702,0 1.866,-2.5677 1.4137,-3.6518l-8.3123,-18.37302c-0.5654,-1.36942 -1.866,-2.22531 -3.3362,-2.22531l-62.3134,0z" fill="url(#svg_8)" id="svg_171" />
                <path d="m2.22814,21.7263l8.25566,-18.88653c0.3393,-0.79882 1.0744,-1.2553 1.9226,-1.2553l64.9711,0c0.8481,0 1.6398,0.51353 1.9225,1.31236l7.9164,18.88657c0.5655,1.4265 -0.4523,2.9671 -1.9225,2.9671l-81.08666,0c-1.52674,-0.0571 -2.60111,-1.5977 -1.9791,-3.0242z" fill="url(#svg_7)" id="svg_170" />
                <path d="m85.0677,23.8374l-80.40807,0c-1.13092,0 -1.92256,-1.1983 -1.47019,-2.2253l7.57716,-17.28893c0.5089,-1.14118 1.6398,-1.94001 2.8838,-1.94001l62.4831,0c1.3005,0 2.4314,0.79883 2.9404,1.94001l7.2944,17.51713c0.3958,0.97 -0.2828,1.9971 -1.3006,1.9971zm-71.4738,-23.16601c-1.8661,0 -3.61897,1.14118 -4.35407,2.85295l-8.6515,19.79946c-0.45237,1.0842 0.28273,2.2253 1.47019,2.2253l85.32758,0c1.1309,0 1.9225,-1.1411 1.4702,-2.2253l-8.2557,-19.68534c-0.7351,-1.82589 -2.488,-2.96707 -4.4106,-2.96707l-62.5961,0z" fill="url(#svg_6)" id="svg_169" />
              </g>
              <defs>
                <clipPath id="svg_166">
                  <rect width="88.4941" height="24.9348" fill="white" y="0.67041" x="0.47522" id="svg_167" />
                </clipPath>
              </defs>
            </svg>
          </g>
          {/* top panel */}
          <g id="svg_183" stroke="null" transform="matrix(1.33735 0 0 1.22845 -59.4995 -1.83685)">
            <svg width="137" height="35" viewBox="0 0 137 35" fill="none" id="svg_174" x="118.05675" y="7.96347" stroke="null" xmlns="http://www.w3.org/2000/svg">
              <path d="m120.086,33.2441l-106.474,0l-12.50409,-32.35396l134.73009,0l-15.752,32.35396z" fill="#0C056B" id="svg_177" stroke="null" />
              <path d="m120.086,33.2441l-106.474,0l-12.50409,-32.35396l134.73009,0l-15.752,32.35396z" fill="url(#svg_11)" id="svg_176" stroke="null" />
              <path d="m120.086,34.0569l-106.474,0c-0.3248,0 -0.6496,-0.2167 -0.7578,-0.5419l-12.50411,-32.29977c-0.10826,-0.27097 -0.05413,-0.54195 0.10826,-0.75872c0.16239,-0.21678 0.37891,-0.37936 0.64956,-0.37936l134.73009,0c0.271,0 0.541,0.16258 0.704,0.37936c0.162,0.21677 0.162,0.54194 0.054,0.81291l-15.752,32.35398c-0.162,0.271 -0.433,0.4335 -0.758,0.4335zm-104.0923,-1.6258l101.9813,0c0.974,0 1.895,-0.5419 2.328,-1.4632l13.099,-26.93457c0.541,-1.08389 -0.271,-2.33035 -1.461,-2.33035l-127.2605,0c-1.13673,0 -1.94869,1.13807 -1.51564,2.22196l10.39304,26.82616c0.3789,1.0297 1.3532,1.68 2.4358,1.68z" fill="url(#svg_10)" id="svg_175" stroke="null" />
              <foreignObject x="0" y="9" width="100%" height="100%">
                <SpinResult />
              </foreignObject>
            </svg>
          </g>
        </g>
      </g>
      <g>
        <title>Layer 2</title>
        <g transform="matrix(1 0 0 1.14106 0 -53.1682)" stroke="null" id="svg_283">
          <svg stroke="null" y="376" x="-1.44956" id="svg_208" fill="none" viewBox="0 0 375 101" height="101" width="375" xmlns="http://www.w3.org/2000/svg">
            <g stroke="null" id="svg_209" clipPath="url(#svg_178)">
              <path stroke="null" id="svg_210" fill="url(#svg_188)" d="m376.956,0.92334l-376.95588,0l0,96.39826l376.95588,0l0,-96.39826z" />
              <path stroke="null" id="svg_211" fill="url(#svg_187)" d="m87.2183,98.1782l-1.4841,-0.5704l29.9668,-79.6854l1.484,0.5704l-29.9667,79.6854z" />
              <path stroke="null" id="svg_212" fill="url(#svg_186)" d="m189.505,98.4065l-1.598,0.0571l-0.514,-85.1614l1.599,0l0.513,85.1043z" />
              <path stroke="null" id="svg_213" fill="url(#svg_185)" d="m290.765,98.1782l-29.967,-79.6854l1.484,-0.5704l29.967,79.6854l-1.484,0.5704z" />
              <path stroke="null" id="svg_214" fill="url(#svg_184)" d="m376.956,82.6059l-60.676,-80.76923c-0.286,-0.34224 -0.799,-0.45632 -1.142,-0.17112c-0.342,0.2852 -0.456,0.79857 -0.171,1.14081l61.989,82.53744l0,-2.7379z" />
              <path stroke="null" id="svg_215" fill="url(#svg_182)" d="m372.047,0.92383l-1.769,0c-0.115,0.34224 0,0.68448 0.285,0.91264l6.393,4.56324l0,-1.99642l-4.909,-3.47946z" />
              <path stroke="null" id="svg_216" fill="url(#svg_181)" d="m6.45022,0.92383l-1.48407,0l-5.02303,3.76467l0,1.99641l6.33586,-4.73435c0.34248,-0.22817 0.39956,-0.68449 0.17124,-1.02673z" />
              <path stroke="null" id="svg_217" fill="url(#svg_180)" d="m61.7036,1.66555c-0.3425,-0.2852 -0.8562,-0.17112 -1.1416,0.17112l-60.56182,80.59813l0,2.6809l61.81752,-82.30934c0.2854,-0.39928 0.2283,-0.85561 -0.1141,-1.14081z" />
              <path stroke="null" id="svg_218" fill="url(#svg_179)" d="m376.956,97.3208l-376.95594,0l0,2.8522l376.95594,0l0,-2.8522z" />
            </g>
            <defs stroke="null">
              <clipPath stroke="null" id="svg_178">
                <rect stroke="null" id="svg_219" x="0" y="0.92139" fill="white" height="99.2503" width="376.956" />
              </clipPath>
            </defs>
          </svg>
        </g>
        <svg y="373" x="69.45621" id="svg_220" fill="none" viewBox="0 0 236 104" height="104" width="236" xmlns="http://www.w3.org/2000/svg">
          <g id="svg_221" clipPath="url(#svg_190)">
            <g id="svg_222" opacity="0.45">
              <path id="svg_223" fill="#060693" d="m181.76,9.82568l-128.3197,0l-52.23669,51.09192l41.07119,42.1644l150.5942,0l41.128,-42.1644l-52.237,-51.09192z" />
            </g>
            <path id="svg_224" fill="url(#svg_196)" d="m44.4069,98.3662c-0.1122,0 -0.2805,-0.0562 -0.3927,-0.1685l-43.20336,-43.7369c-0.11221,-0.1123 -0.16832,-0.2246 -0.16832,-0.393l0,-19.37c0,-0.1685 0.11221,-0.3369 0.22443,-0.4492l52.29285,-32.7325c0.0561,-0.05615 0.1683,-0.11229 0.2805,-0.11229l128.3757,0c0.112,0 0.168,0 0.28,0.05614l52.237,32.73255c0.168,0.1123 0.225,0.2807 0.225,0.4491l0,19.37c0,0.1123 -0.057,0.2808 -0.169,0.3931l-43.203,43.7369c-0.112,0.1123 -0.224,0.1684 -0.393,0.1684l-146.3861,0l0,0.0562z" />
            <path id="svg_225" fill="#060693" d="m181.76,1.96519l52.237,32.73251l0,19.37l-43.204,43.7369l-146.3861,0l-43.20327,-43.7369l0,-19.37l52.23677,-32.73251l128.3196,0zm0,-1.06675l-128.3196,0c-0.2245,0 -0.3928,0.05614 -0.5611,0.16843l-52.23675,32.73253c-0.33665,0.1684 -0.50498,0.5053 -0.50498,0.8983l0,19.37c0,0.2807 0.11222,0.5615 0.28054,0.7299l43.20329,43.7369c0.2245,0.2246 0.4489,0.3369 0.7294,0.3369l146.3862,0c0.281,0 0.561,-0.1123 0.73,-0.3369l43.203,-43.7369c0.168,-0.2246 0.28,-0.4492 0.28,-0.7299l0,-19.37c0,-0.3369 -0.168,-0.6737 -0.505,-0.8983l-52.124,-32.73253c-0.168,-0.11229 -0.337,-0.16843 -0.561,-0.16843z" />
            <path id="svg_226" fill="url(#svg_195)" d="m44.4069,97.8608l-43.20329,-43.793l0,-19.37l43.20329,43.7369l0,19.4261z" />
            <path id="svg_227" fill="url(#svg_193)" d="m190.793,97.8608l43.204,-43.793l0,-19.37l-43.204,43.7369l0,19.4261z" />
            <path id="svg_228" fill="url(#svg_192)" d="m162.515,78.4346l-89.8857,0l-28.2224,0l0,19.4261l146.3861,0l0,-19.4261l-28.278,0z" />
            <path id="svg_229" fill="url(#svg_191)" d="m1.20361,34.6978l43.20329,43.737l146.3861,0l43.204,-43.737l-52.237,-32.73247l-128.3197,0l-52.23669,32.73247z" />
          </g>
          <defs>
            <clipPath id="svg_190">
              <rect id="svg_230" x="0.13757" y="0.89844" fill="white" height="102.184" width="234.925" />
            </clipPath>
          </defs>
        </svg>

        {!isSpinning
          ? (
            <g id="spin-button" onClick={handleSpinClick} className="active:scale-100">
              <svg y="359" x="62.40515" id="svg_231" fill="none" viewBox="0 0 238 103" height="103" width="238" xmlns="http://www.w3.org/2000/svg">
                <path id="svg_232" fill="url(#svg_204)" d="m187.659,22.4106l-126.0717,0l-35.1736,24.0322l0,7.1082l33.2541,31.93l131.2092,0l31.899,-31.93l0,-7.1082l-35.117,-24.0322z" />
                <path id="svg_233" fill="url(#svg_203)" d="m59.6678,85.4816l-33.2541,-31.9301l0,-7.1081l33.2541,31.9865l0,7.0517z" />
                <path id="svg_234" fill="url(#svg_202)" d="m190.877,85.4816l31.899,-31.9301l0,-7.1081l-31.899,31.9865l0,7.0517z" />
                <path id="svg_235" fill="url(#svg_201)" d="m190.877,78.4297l-131.2092,0l0,7.0517l131.2092,0l0,-7.0517z" />
                <path id="svg_236" fill="url(#svg_200)" d="m26.4137,53.5513l33.2541,31.9301l131.2092,0l31.899,-31.9301l-31.899,30.5197l-131.2092,0l-33.2541,-30.5197z" opacity="0.7" />
                <path id="svg_237" fill="url(#svg_199)" d="m26.4137,46.4433l34.9478,-24.0322l126.7495,0l26.31,18.2216l-26.592,-17.3754l-125.9029,-0.1128l-35.5124,23.2988z" />
                <path id="svg_238" fill="url(#svg_198)" d="m26.4137,46.4434l33.2541,31.9301l131.2092,0l31.899,-31.9301l-32.35,30.012l-130.5324,0l-33.4799,-30.012z" opacity="0.8" />
              </svg>
              <svg y="391" x="124.17371" id="svg_239" fill="none" viewBox="0 0 126 31" height="31" width="126" xmlns="http://www.w3.org/2000/svg">
                <g id="svg_240" clipPath="url(#svg_206)">
                  <path id="svg_241" fill="#FCF8FF" d="m2.59126,23.7115c6.04655,0.2883 12.15064,0.5766 18.19724,0.6342c0.7486,0 1.4396,-0.173 1.9579,-0.5766c0.5183,-0.4036 0.8638,-0.8649 1.0366,-1.4991c0.3455,-1.4992 0.5182,-2.2488 0.9213,-3.7479c-4.4341,0 -6.6224,0 -10.9989,0c-3.34,0 -5.58587,-0.6343 -6.62242,-1.9605c-1.03655,-1.3261 -0.97896,-3.4596 0.11517,-6.3426c0.17276,-0.51889 0.28793,-0.74953 0.46069,-1.26847c1.09414,-2.94066 2.59136,-5.01641 4.37656,-6.34259c1.7852,-1.32618 4.2038,-2.0181 7.1407,-2.0181c5.9889,0 8.9834,0 14.9148,0c-0.4607,2.3064 -0.691,3.51726 -1.2093,5.82365c-5.5283,0 -8.2348,0 -13.7631,0c-1.7852,0 -2.9369,0.80724 -3.4552,2.47938c-0.1152,0.34596 -0.1727,0.51894 -0.2879,0.80724c-0.5183,1.67209 0.1152,2.47939 1.9579,2.47939c3.34,0 4.9524,0 8.2924,0c2.9945,0 5.0676,0.6342 6.2769,1.9028c1.1517,1.2685 1.5548,3.2866 1.0366,5.9966c-0.1728,0.8072 -0.2304,1.2685 -0.4031,2.0757c-0.5183,2.7101 -1.6124,4.7282 -3.4552,5.9967c-1.8428,1.2685 -4.4341,1.9028 -7.7741,1.9028c-1.7276,0 -3.5704,0 -5.5283,-0.0577c-1.9004,-0.0577 -2.8217,-0.1153 -4.6645,-0.173c-3.45516,-0.1153 -7.0255,-0.3459 -10.65343,-0.5766c0.86379,-2.1334 1.32448,-3.2866 2.13069,-5.5353z" />
                  <path id="svg_242" fill="#FCF8FF" d="m40.4253,0.58984c5.9314,0 11.9204,0 17.8518,0c2.9944,0 5.1251,0.63426 6.5072,1.96044c1.3821,1.32618 2.1307,3.45959 2.2459,6.34259c0.0575,1.67213 0.0575,2.47933 0.1151,4.15153c0.1152,2.9406 -0.5758,5.0164 -2.0731,6.3426c-1.4972,1.3262 -3.9734,1.9604 -7.4286,1.9604c-4.6645,0 -7.0255,0 -11.7476,0c-0.3455,3.3443 -0.5182,5.0164 -0.8638,8.303c-3.6855,0 -5.5282,0 -9.2713,0c1.6124,-9.6292 3.1672,-19.316 4.6644,-29.06056zm18.5428,8.36069c0.0576,-1.67214 -0.8062,-2.47938 -2.5914,-2.47938c-3.5703,0 -5.2979,0 -8.8683,0c-0.4031,3.69025 -0.5758,5.47765 -0.9213,9.16795c3.7431,0 5.6434,0 9.3865,0c1.9004,0 2.8217,-0.8073 2.8793,-2.4794c0.0576,-1.7298 0.0576,-2.537 0.1152,-4.20917z" />
                  <path id="svg_243" fill="#FCF8FF" d="m89.2584,29.7657c-6.1617,0 -12.3234,0 -18.4851,0c-0.1152,-2.3064 -0.1728,-3.5172 -0.288,-5.8236c1.7852,0 2.649,0 4.4342,0c-0.4607,-5.8237 -0.8638,-11.6473 -1.3245,-17.47099c-1.6124,0 -2.4186,0 -3.9735,0c-0.1151,-2.3064 -0.1727,-3.51725 -0.2879,-5.82365c6.1042,0 9.1562,0 15.3179,0c0.3456,2.3064 0.5759,3.51725 0.9214,5.82365c-1.6124,0 -2.4186,0 -3.9734,0c0.7486,5.82369 1.4972,11.64729 2.3034,17.47099c1.7852,0 2.649,0 4.4342,0c0.3455,2.3064 0.5758,3.5172 0.9213,5.8236z" />
                  <path id="svg_244" fill="#FCF8FF" d="m125.192,29.7658c-3.916,0 -5.874,0 -9.732,0c-5.643,-5.7084 -11.229,-11.4167 -16.5271,-17.471c1.3821,5.8237 2.8221,11.6473 4.2041,17.471c-3.6858,0 -5.5286,0 -9.2717,0c-1.7852,-9.7446 -3.5703,-19.4314 -5.4131,-29.17596c3.2248,0 4.8372,0 8.0621,0c5.0097,6.05429 10.3077,11.82026 15.7207,17.47096c-1.842,-5.8237 -3.685,-11.64731 -5.528,-17.47096c3.052,0 4.607,0 7.659,0c3.628,9.74456 7.256,19.43136 10.826,29.17596z" />
                  <path id="svg_245" fill="#E8B0FF" d="m19.2336,0.64697c-2.9945,0 -5.3555,0.63426 -7.1983,1.96044c-1.8427,1.32618 -3.33997,3.45959 -4.37652,6.34259c-0.17276,0.51894 -0.28793,0.74958 -0.46069,1.2685c-0.63344,1.6722 -0.86379,3.056 -0.8062,4.1515c0.05758,-1.0378 0.34552,-2.191 0.8062,-3.5172c0.17276,-0.519 0.28793,-0.7496 0.46069,-1.26854c1.09414,-2.94066 2.59142,-5.01641 4.37652,-6.34259c1.8428,-1.32618 4.2038,-1.96044 7.1983,-1.96044c5.9314,0 8.9259,0 14.7996,0c0.0576,-0.23064 0.0576,-0.40362 0.1152,-0.63426c-5.9889,0 -8.9834,0 -14.9148,0z" />
                  <path id="svg_246" fill="#E8B0FF" d="m17.4485,12.9287c3.34,0 4.9524,0 8.2924,0c2.9945,0 5.0676,0.6342 6.2769,1.9027c0.8062,0.8649 1.2093,2.0758 1.2669,3.5749c0.0576,-1.8451 -0.3455,-3.2289 -1.2669,-4.2091c-1.1517,-1.2685 -3.2248,-1.9028 -6.2769,-1.9028c-3.34,0 -4.9524,0 -8.2924,0c-1.3245,0 -2.0155,-0.4036 -2.1307,-1.2685c-0.1728,1.2685 0.5183,1.9028 2.1307,1.9028z" />
                  <path id="svg_247" fill="#E8B0FF" d="m20.7885,24.9794c0.7486,0 1.4396,-0.173 1.9579,-0.5766c0.5183,-0.4036 0.8638,-0.8649 1.0365,-1.4992c0.3456,-1.4991 0.6911,-2.883 1.0366,-4.3821c-0.0576,0 -0.1152,0 -0.1728,0c-0.2879,1.0955 -0.5758,2.4794 -0.9213,3.7479c-0.1728,0.6342 -0.5183,1.1532 -1.0366,1.4991c-0.5183,0.4037 -1.2093,0.5766 -1.9579,0.5766c-6.0466,0 -12.15071,-0.3459 -18.19726,-0.6342c-0.74862,2.2487 -1.20931,3.4019 -2.01551,5.6506c0.05758,0 0.17276,0 0.23034,0c0.69104,-1.8451 1.09414,-2.9983 1.84276,-5.0164c6.04655,0.2883 12.15067,0.6343 18.19727,0.6343z" />
                  <path id="svg_248" fill="#E8B0FF" d="m67.0877,8.89238c-0.1151,-2.94065 -0.8638,-5.01641 -2.2458,-6.34259c-1.3821,-1.32618 -3.5128,-1.96044 -6.5073,-1.96044c-5.9889,0 -11.9203,0 -17.8517,0c-1.5548,9.74455 -3.052,19.43135 -4.6069,29.17595c0.0576,0 0.0576,0 0.1152,0c1.4972,-9.5139 2.9945,-19.0278 4.4917,-28.54169c5.9314,0 11.9204,0 17.8517,0c2.9945,0 5.1252,0.63426 6.5073,1.96044c1.382,1.32618 2.1307,3.4596 2.2458,6.34259c0.0576,1.67216 0.0576,2.47936 0.1152,4.09386c0,-0.173 0,-0.4036 0,-0.5766c-0.0576,-1.6145 -0.1152,-2.4794 -0.1152,-4.15152z" />
                  <path id="svg_249" fill="#E8B0FF" d="m58.853,13.1016c-0.0576,1.6721 -1.0366,2.537 -2.8794,2.537l-9.4441,0c0,0.2306 -0.0576,0.4036 -0.0576,0.6343l9.4442,0c1.9003,0 2.8793,-0.8649 2.8793,-2.5371c0.0575,-0.3459 0.0576,-0.6342 0.0576,-0.6342z" />
                  <path id="svg_250" fill="#E8B0FF" d="m88.3371,23.9414c0,0 -4.4342,0 -4.4918,0c0.0576,0.2306 0.0576,0.4036 0.0576,0.6343l4.4917,0c0,-0.2307 0,-0.4037 -0.0575,-0.6343z" />
                  <path id="svg_251" fill="#E8B0FF" d="m84.6515,0.58935c-6.1041,0 -9.1562,0 -15.3179,0c0,0.23065 0,0.40363 0.0576,0.63426c6.1041,0 9.2714,0 15.3755,0c-0.0576,-0.17298 -0.0576,-0.34595 -0.1152,-0.63426z" />
                  <path id="svg_252" fill="#E8B0FF" d="m74.9195,23.9414c-1.7276,0 -2.7066,0 -4.4341,0c0,0.2306 0,0.4613 0.0575,0.6343c1.7852,0 2.7066,0 4.4918,0c-0.0576,-0.2307 -0.0576,-0.4613 -0.1152,-0.6343z" />
                  <path id="svg_253" fill="#E8B0FF" d="m124.962,29.7653c0.057,0 0.173,0 0.23,0c-3.57,-9.7446 -7.198,-19.4314 -10.768,-29.17594c-3.053,-0.00001 -4.607,-0.00001 -7.659,-0.00001c0.057,0.23065 0.115,0.40363 0.23,0.63426c2.937,0 4.434,0 7.429,0c3.512,9.51389 7.025,19.02779 10.538,28.54169z" />
                  <path id="svg_254" fill="#E8B0FF" d="m112.581,19.0405c-0.115,-0.3459 -0.231,-0.6342 -0.288,-0.9802c-5.298,-5.593 -10.826,-11.53197 -15.7212,-17.47094c-3.2248,-0.00001 -4.8372,-0.00001 -8.062,-0.00001c0.0576,0.23065 0.0576,0.40363 0.1151,0.63426c3.1673,0 4.7797,0 7.9469,0c5.0102,6.05429 10.5962,12.16629 16.0092,17.81689z" />
                </g>
                <defs>
                  <clipPath id="svg_206">
                    <rect id="svg_255" x="0.51825" y="0.58838" fill="white" height="29.5795" width="124.674" />
                  </clipPath>
                </defs>
              </svg>
            </g>
          )
          : (
            <g transform="matrix(0.264153 0 0 0.286715 83.4099 374.361)" stroke="null" id="svg_249">
              <svg y="17.3387" x="-21.6256" stroke="null" id="svg_250" fill="none" viewBox="0 0 820 235" height="235" width="820" xmlns="http://www.w3.org/2000/svg">
                <g stroke="null" id="svg_251" clipPath="url(#svg_242)">
                  <path stroke="null" id="svg_252" fill="url(#svg_248)" d="m673.589,10.6094l-527.413,0l-139.3386,96.1936l132.0296,127.314l547.924,0l126.608,-127.314l-139.81,-96.1936z" />
                  <path stroke="null" id="svg_253" fill="url(#svg_247)" d="m673.353,10.6094l-527.413,0l-139.33844,96.1936l132.02944,127.314l547.925,0l126.607,-127.314l-139.81,-96.1936z" />
                  <path stroke="null" id="svg_254" fill="url(#svg_246)" d="m146.176,10.6095l-139.33874,96.1935l-6.83726,-6.13l146.176,-100.673l0,10.6095z" />
                  <path stroke="null" id="svg_255" fill="url(#svg_245)" d="m673.588,0l-527.412,0l0,10.6095l527.412,0l139.811,96.1935l6.601,-6.13l-146.412,-100.673z" />
                  <path stroke="null" id="svg_256" fill="url(#svg_244)" d="m6.83716,106.803l139.33884,-96.1936l527.412,0l139.81,96.1936l-10.373,0l-122.835,-84.641c-5.187,-3.3008 -11.081,-5.1869 -17.211,-5.1869l-509.494,0c-6.602,0 -13.203,1.8861 -18.862,5.4227l-127.78584,84.4052z" opacity="0.3" />
                  <path stroke="null" id="svg_257" fill="url(#svg_243)" d="m6.60156,106.803l132.02944,127.314l547.925,0l126.607,-127.314l-10.374,0l-114.819,114.111c-3.772,3.537 -8.723,5.423 -13.674,5.423l-528.12,0c-3.536,0 -7.073,-1.415 -9.666,-3.772l-129.90844,-115.762z" opacity="0.3" />
                  <g stroke="null" id="svg_258" clipPath="url(#svg_241)">
                    <path stroke="null" id="svg_259" fill="#FCF8FF" d="m163.48,140.583c24.735,1.179 49.705,2.359 74.439,2.594c3.062,0 5.889,-0.707 8.009,-2.358c2.12,-1.651 3.534,-3.538 4.241,-6.133c1.413,-6.132 2.12,-9.199 3.769,-15.331c-18.139,0 -27.09,0 -44.993,0c-13.663,0 -22.85,-2.595 -27.091,-8.02c-4.24,-5.425 -4.004,-14.1518 0.472,-25.9451c0.706,-2.1228 1.177,-3.0663 1.884,-5.1891c4.476,-12.0293 10.601,-20.5205 17.903,-25.9454c7.303,-5.425 17.196,-8.2554 29.21,-8.2554c24.499,0 36.749,0 61.012,0c-1.885,9.4347 -2.827,14.3879 -4.947,23.8226c-22.614,0 -33.686,0 -56.3,0c-7.303,0 -12.014,3.3022 -14.134,10.1423c-0.471,1.4152 -0.707,2.1228 -1.178,3.3022c-2.12,6.8401 0.471,10.1423 8.009,10.1423c13.663,0 20.259,0 33.922,0c12.249,0 20.729,2.5945 25.676,7.7836c4.712,5.189 6.361,13.444 4.241,24.53c-0.707,3.302 -0.943,5.189 -1.649,8.491c-2.121,11.086 -6.596,19.342 -14.134,24.531c-7.538,5.189 -18.139,7.783 -31.802,7.783c-7.067,0 -14.605,0 -22.614,-0.236c-7.774,-0.235 -11.543,-0.471 -19.081,-0.707c-14.134,-0.472 -28.739,-1.415 -43.58,-2.359c3.534,-8.727 5.418,-13.444 8.716,-22.643z" />
                    <path stroke="null" id="svg_260" fill="#FCF8FF" d="m318.247,46c24.263,0 48.762,0 73.026,0c12.249,0 20.965,2.5945 26.618,8.0195c5.654,5.4249 8.716,14.152 9.188,25.9454c0.235,6.8402 0.235,10.1423 0.471,16.9825c0.471,12.0296 -2.356,20.5206 -8.481,25.9456c-6.124,5.425 -16.254,8.019 -30.388,8.019c-19.081,0 -28.739,0 -48.055,0c-1.414,13.681 -2.12,20.521 -3.534,33.965c-15.076,0 -22.614,0 -37.926,0c6.596,-39.39 12.956,-79.0154 19.081,-118.877zm75.852,34.2008c0.236,-6.8402 -3.298,-10.1423 -10.6,-10.1423c-14.605,0 -21.672,0 -36.277,0c-1.649,15.0955 -2.356,22.4074 -3.769,37.5025c15.311,0 23.085,0 38.397,0c7.774,0 11.543,-3.302 11.778,-10.1419c0.236,-7.076 0.236,-10.3782 0.471,-17.2183z" />
                    <path stroke="null" id="svg_261" fill="#FCF8FF" d="m518.007,165.349c-25.206,0 -50.411,0 -75.617,0c-0.471,-9.435 -0.706,-14.388 -1.177,-23.823c7.302,0 10.836,0 18.138,0c-1.884,-23.822 -3.533,-47.6449 -5.418,-71.4675c-6.596,0 -9.894,0 -16.254,0c-0.471,-9.4347 -0.707,-14.388 -1.178,-23.8227c24.97,0 37.455,0 62.661,0c1.413,9.4347 2.355,14.388 3.769,23.8227c-6.596,0 -9.894,0 -16.254,0c3.062,23.8226 6.124,47.6455 9.422,71.4675c7.303,0 10.836,0 18.139,0c1.413,9.435 2.356,14.388 3.769,23.823z" />
                    <path stroke="null" id="svg_262" fill="#FCF8FF" d="m665,165.349c-16.018,0 -24.028,0 -39.811,0c-23.085,-23.351 -45.935,-46.702 -67.607,-71.4679c5.654,23.8229 11.543,47.6449 17.196,71.4679c-15.076,0 -22.614,0 -37.926,0c-7.302,-39.862 -14.605,-79.4874 -22.143,-119.349c13.192,0 19.788,0 32.979,0c20.494,24.7661 42.166,48.3528 64.31,71.468c-7.538,-23.8228 -15.077,-47.6454 -22.615,-71.468c12.485,0 18.846,0 31.331,0c14.84,39.8616 29.681,79.487 44.286,119.349z" />
                    <path stroke="null" id="svg_263" fill="#E8B0FF" d="m231.559,46.2358c-12.249,0 -21.908,2.5946 -29.446,8.0195c-7.538,5.425 -13.663,14.1521 -17.903,25.9455c-0.706,2.1228 -1.178,3.0662 -1.884,5.189c-2.592,6.8402 -3.534,12.501 -3.298,16.9822c0.235,-4.2453 1.413,-8.9627 3.298,-14.3876c0.706,-2.1228 1.178,-3.0663 1.884,-5.1891c4.476,-12.0292 10.601,-20.5205 17.903,-25.9454c7.538,-5.425 17.197,-8.0195 29.446,-8.0195c24.263,0 36.513,0 60.54,0c0.236,-0.9435 0.236,-1.6511 0.472,-2.5946c-24.499,0 -36.749,0 -61.012,0z" />
                    <path stroke="null" id="svg_264" fill="#E8B0FF" d="m224.256,96.4755c13.663,0 20.259,0 33.922,0c12.249,0 20.73,2.5946 25.676,7.7835c3.298,3.538 4.947,8.491 5.183,14.624c0.235,-7.548 -1.414,-13.209 -5.183,-17.218c-4.711,-5.1895 -13.191,-7.784 -25.676,-7.784c-13.663,0 -20.259,0 -33.922,0c-5.418,0 -8.245,-1.6511 -8.716,-5.1891c-0.706,5.1891 2.12,7.7836 8.716,7.7836z" />
                    <path stroke="null" id="svg_265" fill="#E8B0FF" d="m237.919,145.772c3.063,0 5.889,-0.707 8.009,-2.359c2.12,-1.651 3.534,-3.538 4.241,-6.132c1.413,-6.133 2.826,-11.793 4.24,-17.926c-0.236,0 -0.471,0 -0.707,0c-1.178,4.481 -2.356,10.142 -3.769,15.331c-0.707,2.595 -2.12,4.718 -4.24,6.133c-2.12,1.651 -4.947,2.359 -8.009,2.359c-24.735,0 -49.705,-1.416 -74.439,-2.595c-3.063,9.199 -4.947,13.916 -8.245,23.115c0.236,0 0.707,0 0.942,0c2.827,-7.548 4.476,-12.265 7.538,-20.52c24.735,1.179 49.705,2.594 74.439,2.594z" />
                    <path stroke="null" id="svg_266" fill="#E8B0FF" d="m427.314,79.9649c-0.471,-12.0292 -3.533,-20.5205 -9.187,-25.9454c-5.654,-5.425 -14.369,-8.0195 -26.619,-8.0195c-24.499,0 -48.762,0 -73.025,0c-6.361,39.8616 -12.485,79.487 -18.846,119.349c0.236,0 0.236,0 0.472,0c6.124,-38.918 12.249,-77.8363 18.374,-116.7545c24.263,0 48.762,0 73.025,0c12.25,0 20.965,2.5946 26.619,8.0195c5.654,5.425 8.716,14.1521 9.187,25.9455c0.236,6.8401 0.236,10.1423 0.471,16.7465c0,-0.7076 0,-1.651 0,-2.3586c-0.235,-6.6043 -0.471,-10.1423 -0.471,-16.9825z" />
                    <path stroke="null" id="svg_267" fill="#E8B0FF" d="m393.628,97.1831c-0.235,6.8399 -4.24,10.3779 -11.778,10.3779l-38.633,0c0,0.944 -0.236,1.651 -0.236,2.595l38.633,0c7.774,0 11.779,-3.538 11.779,-10.3784c0.235,-1.4152 0.235,-2.5945 0.235,-2.5945z" />
                    <path stroke="null" id="svg_268" fill="#E8B0FF" d="m514.238,141.526c0,0 -18.139,0 -18.374,0c0.235,0.944 0.235,1.651 0.235,2.595l18.374,0c0,-0.944 0,-1.651 -0.235,-2.595z" />
                    <path stroke="null" id="svg_269" fill="#E8B0FF" d="m499.162,46c-24.97,0 -37.455,0 -62.661,0c0,0.9435 0,1.6511 0.236,2.5945c24.97,0 37.926,0 62.896,0c-0.236,-0.7076 -0.236,-1.4152 -0.471,-2.5945z" />
                    <path stroke="null" id="svg_270" fill="#E8B0FF" d="m459.351,141.526c-7.067,0 -11.072,0 -18.139,0c0,0.944 0,1.887 0.236,2.595c7.303,0 11.072,0 18.374,0c-0.235,-0.944 -0.235,-1.887 -0.471,-2.595z" />
                    <path stroke="null" id="svg_271" fill="#E8B0FF" d="m664.058,165.349c0.235,0 0.706,0 0.942,0c-14.605,-39.862 -29.446,-79.4874 -44.051,-119.349c-12.485,0 -18.845,0 -31.33,0c0.235,0.9435 0.471,1.6511 0.942,2.5945c12.014,0 18.139,0 30.388,0c14.37,38.9182 28.739,77.8365 43.109,116.7545z" />
                    <path stroke="null" id="svg_272" fill="#E8B0FF" d="m613.411,121.478c-0.471,-1.416 -0.942,-2.595 -1.178,-4.01c-21.672,-22.8793 -44.286,-47.1737 -64.309,-71.468c-13.192,0 -19.788,0 -32.979,0c0.235,0.9435 0.235,1.6511 0.471,2.5945c12.956,0 19.552,0 32.508,0c20.494,24.7661 43.344,49.7681 65.487,72.8835z" />
                  </g>
                </g>
                <defs stroke="null">
                  <clipPath stroke="null" id="svg_242">
                    <rect stroke="null" id="svg_273" fill="white" height="234.117" width="820" />
                  </clipPath>
                  <clipPath stroke="null" id="svg_241">
                    <rect stroke="null" id="svg_274" x="155" y="46" fill="white" height="121" width="510" />
                  </clipPath>
                </defs>
              </svg>
            </g>
          )}
      </g>
    </svg>
  );
}
export default Machine;
