import { SettingsResponse } from '../store/reducers/settings/types';
import { ProfileResponseT } from '../types';

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getSecondsBetweenDates(startDate: Date, endDate: Date) {
  const diff = endDate.getTime() - startDate.getTime();
  return Math.round(diff / 1000);
}

export const getMinutesFromSeconds = (seconds: number) => Math.floor(seconds / 60);
export const formatNumbers = (number: number) => Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 1,
}).format(number);

export const getNextLeagueCoins = (leagues: SettingsResponse['leagues'], userLeague: ProfileResponseT['league']['name']) => {
  const currentLeagueIndex = leagues?.findIndex((league) => league.name === userLeague) || 0;
  const nextLeagueIndex: number = currentLeagueIndex === (leagues.length + 1) ? leagues.length : currentLeagueIndex + 1;
  return leagues[nextLeagueIndex]?.from || 0;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}.${month}.${year}, ${hours}:${minutes}`;
};

export const calculateScale = (animation: any, targetWidth: number, targetHeight: number) => {
  const originalWidth = animation.width;
  const originalHeight = animation.height;
  const scaleX = targetWidth / originalWidth;
  const scaleY = targetHeight / originalHeight;
  return Math.min(scaleX, scaleY);
};
