import { useMemo } from 'react';
import {
  Route, Routes, Navigate, Link,
} from 'react-router-dom';
import HomePage from '../pages/Home';

import Pages from './pages';
import MainLayout from '../Layouts/MainLayout';
import FriendsPage from '../pages/Friends';
import QuestPage from '../pages/Quest';
import Leaderboard from '../pages/Leaderboard';
import Store from '../pages/Store';

function PageNotFound() {
  return (
    <div>
      <h1>{'Page doesn\'t exist'}</h1>
      <div>
        <Link to={Pages.home}>
          Home
        </Link>
      </div>
    </div>
  );
}

type ProtectedRouteType = {
  isAllowed: boolean,
  redirectPath?: string,
  element: JSX.Element
}

function ProtectedRoute({ isAllowed, redirectPath = '/', element }: ProtectedRouteType) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return element;
}

function RoutesComponent() {
  const routes: RouteType[] = [
    {
      path: Pages.home,
      element: <HomePage />,
      systemPermissions: [],
      redirect: Pages.home,
    },
    {
      path: Pages.friends,
      element: <FriendsPage />,
      systemPermissions: [],
      redirect: Pages.home,
    },
    {
      path: Pages.quests,
      element: <QuestPage />,
      systemPermissions: [],
      redirect: Pages.home,
    },
    {
      path: Pages.leaderbord,
      element: <Leaderboard />,
      systemPermissions: [],
      redirect: Pages.home,
    },
    {
      path: Pages.store,
      element: <Store />,
      systemPermissions: [],
      redirect: Pages.home,
    },
  ];

  const routesMapper = (route: RouteType) => (
    <Route
      element={(
        <ProtectedRoute
          isAllowed={route?.systemPermissions?.every(Boolean)}
          redirectPath={route.redirect || Pages.home}
          element={route.element}
        />
)}
      path={route.path}
      key={route.path}
    />
  );

  const mappedRoutes = useMemo(() => (
    <Route element={<MainLayout />}>
      {routes.map((el) => routesMapper({
        ...el,
        systemPermissions: [],
        redirect: Pages.home,
      }))}
    </Route>
  ), [routes]);

  return (
    <Routes>
      {mappedRoutes}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RoutesComponent;

type RouteType = {
  path: string,
  element: JSX.Element,
  systemPermissions: Array<boolean>
  redirect?: string
}
